import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {Book} from "./components/book/Book";
import {KazboekContainer} from "./components/container/container";
import {Books} from "./components/books/Books.tsx";
import {AuthContextProvider, useAuth} from "./Auth";
import {KazboekApolloProvider} from "./Apollo";
import {Admin} from "./components/admin/Admin.tsx";
import {Changelog} from "./components/changelog/Changelog.tsx";
import {YearProvider} from "./providers/year-provider";
import {LandingPage} from "./LandingPage";
import {CurrentUserProvider} from "./providers/CurrentUserProvider";
import {GuardedRoute} from "./components/guards/GuardedRoute";

const MainSwitch = (props) => {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) return (
       <LandingPage/>
    );

    return (
        <Switch>
            <Route path='/books/:bookId'>
                <Book />
            </Route>
            <Route path='/' exact={true}>
                <Redirect to='/home'/>
            </Route>
            <Route path='/home' exact={true}>
                <Books />
            </Route>
            <Route path='/books' exact={true}>
                <Books />
            </Route>
            <GuardedRoute path='/admin' permission={['viewAllBooks']} component={Admin} />
            <Route path='/changelog' exact={true}>
                <Changelog/>
            </Route>
        </Switch>
    )
};


export default function App(props) {
    return (
        <Router basename=''>
            <AuthContextProvider>
                <YearProvider>
                    <KazboekApolloProvider>
                        <CurrentUserProvider>
                            <KazboekContainer>
                                <MainSwitch/>
                            </KazboekContainer>
                        </CurrentUserProvider>
                    </KazboekApolloProvider>
                </YearProvider>
            </AuthContextProvider>
        </Router>
    )
}
