import React from 'react';
import { Route, Redirect, useParams, Switch} from "react-router-dom";
import {EntriesTable} from "./entries/EntriesTable";
import { Categories as OldCategories } from './categories/Categories'
import {BookContextProvider} from "./BookContext";
import {Receipts} from "./receipts/Receipts";
import {Dashboard} from "./dashboard/Dashboard";
import {Col, Row} from "react-bootstrap";


export const Book = ({menu, ...props}) => {
    const { bookId } = useParams<{bookId?: any}>();

    return (
        <Row className='py-2'>
            <Col xs={12}>
                <BookContextProvider>
                    <Switch>
                        <Route path='/books/:bookId/categories'>
                            <OldCategories />
                        </Route>
                        <Route path='/books/:bookId/receipts'>
                            <Receipts bookId={bookId}/>
                        </Route>
                        <Route path='/books/:bookId/entries'>
                            <EntriesTable/>
                        </Route>
                        <Route path='/books/:bookId/dashboard'>
                            <Dashboard/>
                        </Route>
                        <Route path='/books/:bookId' exact={true}>
                            <Redirect to={`/books/${bookId}/entries`} />
                        </Route>
                        <Route path='/books/:bookId' exact={true}>
                            <Redirect to={`/books/${bookId}/entries`} />
                        </Route>
                    </Switch>
                </BookContextProvider>
            </Col>
        </Row>
    )
}
