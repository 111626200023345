import { InputGroup} from "react-bootstrap";
import DateTime from "react-datetime";
import React from "react";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "../../../types";

interface DateControlProps {
    size: 'sm' | 'lg'
}

// const DateControl: React.FC<DateControlProps> = ({size, ...props}) => {
//     return (
//         <Form.Control size={size} {...props} />
//     )
// }


export const DateInput = ({entry, name, size, ...props}) => {

    const {setFieldValue, setFieldTouched} = useFormikContext<EntriesTableValues>();

    return (
        <InputGroup size='sm'>
            <DateTime
                onChange={value => setFieldValue(name, new Date(value.valueOf()))}
                onBlur={() => setFieldTouched(name, true)}
                inputProps={{placeholder: 'Datum', size: size, disabled: entry.isCamtEntry}}
                value={new Date(entry.date)}
                dateFormat='DD-MM-YYYY'
                timeFormat=''
                viewMode='days'
                {...props}
            />
        </InputGroup>
    )
}
