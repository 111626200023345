import React, {useContext, useState} from 'react'
import {Button, ButtonGroup, Col, Row} from "react-bootstrap";
import {DeleteEntryModalForm} from "../../DeleteEntryModalForm";
import {Pencil, Trash, FileEarmarkCheck, FileEarmarkArrowUp, ArrowDownUp} from "react-bootstrap-icons";
import {dateString} from "../../../../../helpers/dateString";
import {BookContext} from "../../../BookContext";
import {CamtButton} from "./camt/CamtButton";
import {SelectButton} from "./SelectButton";
import {ReceiptButton} from "../../header/add/receipt/ReceiptButton";
import {Currency} from "../../../../../helpers/Currency";
import {TransactionSplitModalButton} from "./TransactionSplitModal";
import {AdminToolTipOverlay} from "../../../../admin/components/AdminTooltipOverlay";
import { EditableEntry} from "../../EntriesTable";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "../../types";

interface EntryDisplayProps {
    entry: EditableEntry;
    index: number;
}

export const EntryDisplay: React.FC<EntryDisplayProps> = ({entry, index, ...props}) => {

    const {setFieldValue} = useFormikContext<EntriesTableValues>();

    const { categories, sources } = useContext(BookContext);

    const category = categories.find(c => c.id === entry.categoryId)
    const subcategory = (!!category) ? category.subcategories?.find(sc => sc.id === entry.subcategoryId) : null
    const source = sources.find(s => s.id === entry.sourceId);

    const [droppedFile, setDroppedFile] = useState<File | undefined>(undefined);
    const [dragging, setDragging] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const dropHandler = (event) => {
        event.preventDefault();
        setDroppedFile(event.dataTransfer.files[0]);
        setDragging(false);
        setShow(true);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <Row className={'entry-display droppable ' + (dragging ? 'row-dragging' : '')}
             onDrop={e => dropHandler(e)}
             onDragOver={e => onDragOver(e)}
             onDragEnter={() => setDragging(true)}
             onDragLeave={() => setDragging(false)}>
            <AdminToolTipOverlay text={entry.description} id={`item-${entry.id}`}>
                <Col sm={10} lg={3} className='text-truncate order-lg-2 entry-description'>{entry.description}</Col>
            </AdminToolTipOverlay>
           <Col sm={2}  lg={1} className='order-lg-6 entry-amount'><Currency amount={entry.amount} /></Col>
            <Col sm={6}  lg={1} className='text-truncate order-lg-1 entry-date'>{dateString(new Date(entry.date))}</Col>
            <Col sm={6}  lg={1} className='text-truncate order-lg-3 entry-source'>{source?.name}</Col>
            <Col sm={12} lg={4} className='order-lg-4'>
                <Row>
                    <Col sm={5} className='text-truncate'>{!!category ? category.name : null}</Col>
                    <Col sm={5} className='text-truncate'>{!!subcategory ? subcategory.name : null}</Col>
                    <Col sm={2}>{!!entry.subcategoryYear ? entry.subcategoryYear : null}</Col>
                </Row>
            </Col>
            <Col sm={12} lg={2} className='text-right order-lg-7'>
                <ButtonGroup size='sm'>
                    {entry.isCamtEntry ?
                        // @ts-ignore
                        <CamtButton entry={entry} variant='secondary' /> :
                        <DeleteEntryModalForm disabled={entry.isCamtEntry ?? false} entry={entry}>
                            <Trash size={18}/>
                        </DeleteEntryModalForm>
                    }

                    <AdminToolTipOverlay text={"Bewerk entry"} id={"edit-entry"}>
                        <Button className='entry-edit' onClick={() => setFieldValue(`entries[${index}].editing`, true)}>
                            <Pencil size={18}/>
                        </Button>
                    </AdminToolTipOverlay>
                    <AdminToolTipOverlay text={"Bon uploaden"} id={"upload-receit"}>
                        <ReceiptButton setShow={setShow} show={show} file={droppedFile} entry={entry}>
                            {
                                entry.receipt !== null
                                    ? <FileEarmarkCheck size={18}/>
                                    : <FileEarmarkArrowUp size={18} />
                            }

                        </ReceiptButton>
                    </AdminToolTipOverlay>
                    <AdminToolTipOverlay text={"Meerdere selecteren"} id={"select-multiple"}>
                        <SelectButton entry={entry} index={index} />
                    </AdminToolTipOverlay>
                    <TransactionSplitModalButton transactionId={entry.transaction?.id}>
                        <ArrowDownUp size={20} />
                    </TransactionSplitModalButton>
                </ButtonGroup>
            </Col>
        </Row>
    )
}
