import {useState} from "react";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack"
import React from "react";
import {SizeMe} from 'react-sizeme'
import {useAuth} from "../../../../../../Auth";

export interface PdfViewerProps {
    fileLocation: string;
}

export const PdfViewer = ({fileLocation}:PdfViewerProps) => {
    const [numPages, setNumPages] = useState(null);

    const {token} = useAuth();

    const fileUrl = {
        url: fileLocation,
        httpHeaders: {
            Authorization: 'Bearer ' + token
        },
        withCredentials: false
    };

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages)
    };

    return (
        <SizeMe>
            {
                ({size}) => (
                    <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {
                            Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        width={size.width ? size.width: 1}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )
                        }
                    </Document>
                )
            }
        </SizeMe>
  );
};
