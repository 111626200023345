import React, {useEffect, useContext} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {CreateSubcategoryModalForm} from "../../../../categories/CreateSubcategoryModalForm";
import {Plus} from "react-bootstrap-icons";
import {BookContext} from "../../../../BookContext";
import { EditableEntry} from "../../../EntriesTable";
import {FormikProps, useFormikContext} from "formik";
import {SubCategory} from "../../../../../../models/subcategory.model";
import {EntriesTableValues} from "../../../types";

interface SubcategorySelectBaseProps {
    onChange: (any) => any;
    onBlur: (any) => any;
    value?: string;
    categoryId: string;
}

export const SubcategorySelectBase: React.FC<SubcategorySelectBaseProps> = ({ onChange, onBlur, value, categoryId }) => {
    const { categories } = useContext(BookContext)

    const subcategories = categories.reduce<SubCategory[]>((subcategories, category) => {
        return (category.id === categoryId) ? [...subcategories, ...(category.subcategories ?? [])] : [...subcategories];
    }, [])

    return (
        <InputGroup>
            <Form.Control size='sm' as='select' onBlur={onBlur} onChange={onChange} value={value} className='subcategory-select'>
                <option key={0} value=''>Geen subcategorie</option>
                { subcategories.sort((a, b) => a.name > b.name ? 1 : -1).map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option>)}
            </Form.Control>
            {/* @ts-ignore */}
            <InputGroup.Append size={'sm'}>
                {/* @ts-ignore */}
                <CreateSubcategoryModalForm size={'sm'} tabIndex={-1} initialCategoryId={categoryId} categories={categories}>
                    <Plus size={16} className='align-text-bottom' />
                </CreateSubcategoryModalForm>
            </InputGroup.Append>
        </InputGroup>
    )
}


interface SubcategorySelectProps {
    entry: EditableEntry;
    index: number;
}

export const SubcategorySelect: React.FC<SubcategorySelectProps> = ({entry, index}) => {

    const {setFieldValue, setFieldTouched} = useFormikContext<EntriesTableValues>();

    useEffect(() => {
        if (entry.categoryId === '') {
            setFieldValue(`entries[${index}].subcategoryId`, '')
        }
    }, [ entry.categoryId])




    return (
        <SubcategorySelectBase
                onChange={e => setFieldValue(`entries[${index}].subcategoryId`, e.target.value)}
                onBlur={() => setFieldTouched(`entries[${index}].subcategoryId`, true)}
                value={entry.subcategoryId ?? ""}
                categoryId={entry.categoryId ?? ""}
        />
    )
}
