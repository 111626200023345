import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {Book} from "../../../models/book.model";
import {EditBookButton} from "./EditBookButton";

interface AdminBookBaseInfoProps {
    book: Book;
    refetch: () => void;
}

export const AdminBookBaseInfo: FunctionComponent<AdminBookBaseInfoProps> = ({book, ...props}) => {

    return (
        <Row>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5 className={"d-inline-block"}>Gegevens</h5>
                    <span className={"float-right"}>
                        <EditBookButton refetch={props.refetch} book={book}/>
                    </span>
                    <p className={"small"}>Stamgegevens van dit kazboek</p>
                </Col>
                <hr />
                <Col xs={12}>
                    <span><strong>ID:</strong>&nbsp;{book.id}</span>
                </Col>
                <Col xs={12}>
                    <span><strong>Naam:</strong>&nbsp;{book.name}</span>
                </Col>
            </div>
        </Row>
    )
};
