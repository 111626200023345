import {useField} from "formik";
import DateTime from "react-datetime";
import React from "react";


export const DateTimeField = ({placeholder, ...props}) => {
    const [ field, , helpers ] = useField(props);

    return (
        <DateTime
            {...field}
            onChange={value => helpers.setValue(new Date(value))}
            onBlur={() => helpers.setTouched(true)}
            inputProps={{placeholder}}
            {...props}
        />
    )
}


export const DateField = ({placeholder = 'datum', timeFormat='', dateFormat='DD-MM-YYYY', viewMode='days', ...props}) => {
    const [ field, , helpers ] = useField(props);

    return (
        <DateTime
            {...field}
            onChange={value => helpers.setValue(new Date(value))}
            onBlur={() => helpers.setTouched(true)}
            inputProps={{placeholder}}
            placeholder={placeholder}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            viewMode={viewMode}
            {...props}
        />
    )
}