import React, {FunctionComponent, useState} from "react";
import {User} from "../../../models/user.model";
import {CreateUserModal} from "../users/CreateUserModal";
import {Pencil} from "react-bootstrap-icons";

interface EditUserButtonProps {
    user: User;
    refetch: () => void;
}

export const EditUserButton: FunctionComponent<EditUserButtonProps> = (props) => {

    const [show, setShow] = useState<boolean>(false);

    return (
        <span>
            <CreateUserModal user={props.user} refetch={props.refetch} show={show} setShow={setShow} />
            <Pencil onClick={() => setShow(true)}/>
        </span>
    )
};
