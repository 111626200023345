import {Category} from "../../../models/category.model";
import React, {ChangeEvent, FunctionComponent} from "react";
import {ListGroup, Form} from "react-bootstrap";
import {CreateSubcategoryModalForm} from "./CreateSubcategoryModalForm";
import {Plus} from "react-bootstrap-icons";
import {Subcategories} from "./Subcategories";
import {DeleteCategoryModalForm} from "./DeleteCategoryModalForm";
import {EditCategoryModalForm} from "./EditCategoryModalForm";
import {CHANGE_CATEGORY_HIDDEN, ChangeCategoryHiddenResult} from "../Queries";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import Switch from "react-bootstrap/Switch";

export interface CategoryViewProps {
    category: Category;
    allCategories: Category[];
    bookId: number;
}

export const CategoryView: FunctionComponent<CategoryViewProps> = ({category, allCategories, bookId}) => {

    const [update, {data, loading}] = useMutation<ChangeCategoryHiddenResult>(CHANGE_CATEGORY_HIDDEN);


    const changeHidden = (event: ChangeEvent<HTMLInputElement>) => {
        console.log("change", event.target.checked)
        const newHidden: boolean = event.target.checked;

        console.log('Change hidden', newHidden);

        update({
            variables: {
                id: category.id,
                hidden: newHidden
            }
        }).then((data) => {
            if (data.data) {
                category.hidden = data.data?.updateCategory.category.hidden
            }
        })
    }


    return (
        <ListGroup.Item>
            <div className='justify-content-between d-flex'>
                <span>{category.name}

                </span>
                <span>
                    <Form.Check
                        onChange={(event) => changeHidden(event)}
                        checked={category.hidden}
                        type={"switch"}
                        id={`category-${category.id}-switch`}
                        label="Verborgen" />
                </span>
                <div>
                    <CreateSubcategoryModalForm className={'btn-sm'} initialCategoryId={category.id} categories={allCategories}>
                        <Plus />&nbsp;Subcategorie
                    </CreateSubcategoryModalForm>
                    &nbsp;
                    <DeleteCategoryModalForm id={category.id} name={category.name} bookId={ bookId } />
                    <EditCategoryModalForm id={category.id} name={category.name} bookId={ bookId } />
                </div>
            </div>
            <Subcategories subcategories={category.subcategories} />
        </ListGroup.Item>
    )
};
