import React, {FunctionComponent, ReactNode, useState} from "react";
import {Trash} from "react-bootstrap-icons";
import {Source} from "../../../../models/source.model";
import {Book} from "../../../../models/book.model";
import {ConfirmModal} from "../../../../helpers/ConfirmModal";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_SOURCE, DeleteSourceResult} from "../queries";
import {SuperDangerous} from "../../../../helpers/SuperDangerous";

interface RemoveSourceFromBookButtonProps {
    book: Book;
    source: Source;
    refetch: () => void;
}

export const RemoveSourceFromBookButton: FunctionComponent<RemoveSourceFromBookButtonProps> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string | ReactNode>('');
    const [error, setError] = useState<string | ReactNode>('');

    const description = <SuperDangerous>
        Weet je zeker dat je de bron&nbsp;<strong>{props.source.name}</strong>
        &nbsp;wilt verwijderen van&nbsp;<strong>{props.book.name}</strong>?&nbsp;
        <strong className={"text-danger"}><em>ALLE</em></strong> transacties zullen verloren gaan, je kunt dit <strong className={"text-danger"}><em>NIET</em></strong> meer ongedaan maken.
    </SuperDangerous>;

    const [deleteSource] = useMutation<DeleteSourceResult>(DELETE_SOURCE);

    const confirmDelete = () => {

        deleteSource({
            variables: {
                sourceId: props.source.id
            }
        }).then((result) => {
            setMessage(
                <span>Bron&nbsp;<strong>{props.source.name}</strong>&nbsp;succesvol verwijderd</span>
            );
            props.refetch();
            setTimeout(() => setShow(false), 3000);
        }).catch((error) => {
            setError(<span>
                Kon bron&nbsp;<strong>{props.source.name}</strong>&nbsp;niet verwijderen. <strong>Foutmelding:</strong>
                {JSON.stringify(error)}
            </span>)
        });
    };

    const cancelDelete = () => {
    };

    return (
        <span>
            <ConfirmModal title={"Bron verwijderen"}
                          text={description}
                          onCancel={cancelDelete}
                          onConfirm={confirmDelete}
                          show={show}
                          confirmText={props.source.name}
                          successMessage={message}
                          errorMessage={error}
                          setShow={setShow}/>
            <Trash onClick={() => setShow(true)}/>
        </span>

    )
};
