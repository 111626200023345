import React, {useContext, useEffect, useState} from "react";
import {User} from "../models/user.model";
import {useAuth} from "../Auth";
import {useLazyQuery} from "@apollo/react-hooks";
import {GET_CURRENT_USER, GetCurrentUserResult} from "./queries";


interface CurrentUserProviderProps {
    user: User | undefined;
}

export const CurrentUserContext = React.createContext<CurrentUserProviderProps>({
    user: {
        id: 0,
        email: '',
        role: {
            id: 0,
            name: '',
            permissions: []
        }
    }
});

export const CurrentUserProvider =  (props) => {

    const {token} = useAuth();

    const [getUser, {data}] = useLazyQuery<GetCurrentUserResult>(GET_CURRENT_USER);
    const [user, setUser] = useState<User | undefined>( undefined);

    useEffect(() =>{
        if(token){
            getUser();
        }
    }, [token, getUser]);

    useEffect(() => {
        if(data){
            setUser(data.me);
        }
    }, [data, setUser]);

    return (
        <CurrentUserContext.Provider value={{user}}>
            {props.children}
        </CurrentUserContext.Provider>
    )

};

export const useCurrentUser = () => {
    const { user } = useContext(CurrentUserContext);

    return { user };
};



