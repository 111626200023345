import React, {FunctionComponent} from "react";
import {Role} from "../../../models/role.model";
import {Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../components/AdminTooltipOverlay";

interface AdminRolePermissionsInfoProps {
    role: Role;
}

export const AdminRolePermissionsInfo: FunctionComponent<AdminRolePermissionsInfoProps> = ({role, ...props}) => {

    let sortRegex = /([a-z]*)([A-Z]\w+)/g;
    role.permissions?.sort((permissionA, permissionB) => {
        const tPermissionA = permissionA.internalName.replace(sortRegex, '$2');
        const tPermissionB = permissionB.internalName.replace(sortRegex, '$2');
        return tPermissionA > tPermissionB ? 1 : -1;
    });

    return (
        <Row>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5 className={"d-inline-block"}>Permissies</h5>
                    <p className={"small"}>Alle permissies van deze rol</p>
                </Col>
                <hr />
                <div className={"admin-list-header ml-1 mr-1"}>
                    <Row>
                        <Col xs={1}>
                            <AdminToolTipOverlay text={"Unique nummer van elke permissie"} id={"id_overlay"}>
                                <strong>ID</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={5}>
                            <AdminToolTipOverlay text={"Weergavenaam van deze permissie"} id={"email_overlay"}>
                                <strong>Naam</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={5}>
                            <AdminToolTipOverlay text={"Interne naam van deze permissie, niet muteerbaar"} id={"email_overlay"}>
                                <strong>Interne naam</strong>
                            </AdminToolTipOverlay>
                        </Col>
                    </Row>
                </div>
                <div className={"admin-list ml-1 mr-1"}>
                    {
                        role.permissions?.map((permission) => (
                            <Row key={permission.id} className={"admin-list-item"}>
                                <Col xs={1}>
                                    {permission.id}
                                </Col>
                                <Col xs={5}>
                                    {permission.displayName}
                                </Col>
                                <Col xs={5}>
                                    {permission.internalName}
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </div>
        </Row>
    )
};
