import React, {useState} from "react";
import {Alert, Button, Container} from "react-bootstrap";
import {AdminTitle} from "../AdminTitle";
import {CardList, Plus} from "react-bootstrap-icons";
import {CreateBookModal} from "./CreateBookModal";
import {AdminBooksList} from "./AdminBooksList";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_BOOKS, GetAllBooksResult} from "./queries";
import {LoadingComponent} from "../../../helpers/LoadingComponent";


export const AdminBooks = (props) => {

    const [showBookModal, setShowBookModal] = useState<boolean>(false);
    const {loading, error, data, refetch} = useQuery<GetAllBooksResult>(GET_ALL_BOOKS);

    if(loading){
        return <LoadingComponent />
    }

    return (
        <Container>
            <CreateBookModal refetch={refetch} show={showBookModal} setShow={setShowBookModal}/>
            <AdminTitle title={'Kasboeken'} icon={CardList} component={
                <Button onClick={() => setShowBookModal(true)} type="button" variant='primary' size="sm"><Plus />&nbsp;Nieuw</Button>
            } description={"Alle kazboeken waar je toegang tot hebt"}/>
            {data ? (
                <AdminBooksList books={data.books} {...props} />
            ) : null}
            {error ? <Alert variant='danger'>Kon gegevens niet laden: {JSON.stringify(error)}</Alert> : null}
        </Container>
    )

};
