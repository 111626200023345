import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../../components/AdminTooltipOverlay";
import {BookUser} from "../../../../models/book-user.model";
import {ArrowUpRight} from "react-bootstrap-icons";
import {NavLink} from "react-router-dom";

interface AdminUserBooksInfoProps {
    books: BookUser[]
}

export const AdminUserBooksInfo: FunctionComponent<AdminUserBooksInfoProps> = ({books, ...props}) => {

    return (
        <Row>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5>Kasboeken</h5>
                    <p className={"small"}>Kasboeken waar deze gebruiker toegang to heeft.</p>
                </Col>
                <hr />
                <div className={"admin-list-header ml-1 mr-1"}>
                    <Row>
                        <Col xs={1}>
                            <AdminToolTipOverlay text={"Unique nummer van elk kazboek"} id={"id_overlay"}>
                                <strong>ID</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={5}>
                            <AdminToolTipOverlay text={"Naam van kazboek"} id={"name_overlay"}>
                                <strong>Naam</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={5}>
                            <AdminToolTipOverlay text={"De rol die de gebruiker heeft in het kazboek"} id={"rol_overlay"}>
                                <strong>Rol</strong>
                            </AdminToolTipOverlay>
                        </Col>
                    </Row>
                </div>
                <div className={"admin-list ml-1 mr-1"}>
                    {
                        books.map((bookUser) => (
                            <Row key={bookUser.book?.id} className={"admin-list-item"}>
                                <Col xs={1}>
                                    {bookUser.book?.id}
                                </Col>
                                <Col xs={5}>
                                    {bookUser.book?.name}
                                </Col>
                                <Col xs={5}>
                                    {bookUser.role?.name}
                                </Col>
                                <Col xs={1} className={'text-right'} >
                                    <NavLink to={`/admin/books/${bookUser.book?.id}`} className={'kazboek-link'}>
                                        <ArrowUpRight />
                                    </NavLink>
                                </Col>
                            </Row>
                        ))
                    }
                </div>

            </div>
        </Row>
    )
};
