import React, {FunctionComponent, ReactNode, useState} from "react";
import {Trash} from "react-bootstrap-icons";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_BOOK_USER, DeleteBookUserResult} from "../queries";
import {ConfirmModal} from "../../../../helpers/ConfirmModal";
import {User} from "../../../../models/user.model";
import {Book} from "../../../../models/book.model";

interface RemoveUserFromBookButtonProps {
    user: User;
    book: Book;
    refetch: () => void;
}

export const RemoveUserFromBookButton: FunctionComponent<RemoveUserFromBookButtonProps> = (props) => {

    const [show, setShow] = useState<boolean>(false);
    const [deleteUser] = useMutation<DeleteBookUserResult>(DELETE_BOOK_USER);

    const [error, setError] = useState<string | ReactNode>('');
    const [success, setSuccess] = useState<string | ReactNode>('');

    const description = <span>
        Weet je zeker dat je de gebruiker&nbsp;<strong>{props.user.email}</strong>&nbsp;uit het kazboek&nbsp;
        <strong>{props.book.name}</strong>&nbsp;wilt verwijderen?
    </span>;

    const onConfirm = () => {
      deleteUser({
          variables: {
              userId: props.user.id,
              bookId: props.book.id
          }
      }).then((data) => {
          setSuccess(<span>
              Gebruiker&nbsp;<strong>{props.user.email}</strong>&nbsp;succesvol verwijderd uit&nbsp;<strong>{props.book.name}</strong>
          </span>);
          setTimeout(() => {
              props.refetch();
              setShow(false);
          }, 3000)
      }).catch((error) => {
          setError(<span>
              Kon&nbsp;<strong>{props.user.email}</strong>&nbsp;niet verwijderen uit&nbsp;<strong>{props.book.name}</strong>&nbsp;Foutmelding:{error}
          </span>);
      })
    };

    return (
        <span>
            <ConfirmModal title={"Gebruiker uit kazboek verwijderen"}
                          text={description}
                          onConfirm={onConfirm}
                          show={show}
                          successMessage={success}
                          errorMessage={error}
                          setShow={setShow}/>
            <Trash onClick={() => {setShow(true)}}></Trash>
        </span>

    )
};
