import React, {FunctionComponent} from "react";
import {ExclamationCircle} from "react-bootstrap-icons";

interface SuperDangerousProps {

}

export const SuperDangerous: FunctionComponent<SuperDangerousProps> = (props) => {

    return (
        <div className={"super-dangerous"}>
            <div><ExclamationCircle className={"super-dangerous-icon"} /></div>
            <div className={"super-dangerous-text"}>{props.children}</div>
        </div>
    )
};
