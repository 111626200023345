import React from "react";
import {Category} from "../../../models/category.model";
import {Card, Col, Row} from "react-bootstrap";
import {CategoryCard} from "./CategoryCard";


interface CategoryCardsProps {
    categories: Category[];
}

export const CategoryCards = ({categories}:CategoryCardsProps) => {
    return (
        <Row>
            {categories.map(c => (
                <Col className='mt-3' lg={4} key={c.id}>
                    <Card className={'h-100'}>
                        <CategoryCard category={c}/>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}
