import React from 'react';
import {Category} from "../../../models/category.model";
import {Card, Col, Row, ListGroup} from "react-bootstrap";
import {Currency} from "../../../helpers/Currency";


interface CategoryCardProps {
    category: Category;
}


export const CategoryCard = ({category}: CategoryCardProps) => {
    return (
        <Card className='h-100'>
            <Card.Header>
                <Card.Title>{category.name}</Card.Title>
            </Card.Header>
            <Card.Body>
                <ListGroup variant='flush'>
                    { category.subcategories?.map(subcategory => (
                        <ListGroup.Item key={subcategory.id}>
                            <Row>
                                <Col lg={8}>{subcategory.name}</Col>
                                <Col lg={4}><Currency amount={subcategory.balance}/></Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col lg={8}><strong>Totaal:</strong></Col>
                    <Col lg={4} className='font-weight-bold'><Currency amount={category.balance}/></Col>
                </Row>
            </Card.Footer>
        </Card>
    )
};
