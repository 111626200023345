import React, {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap';
import {Formik} from "formik";
import {useApolloClient, useLazyQuery, useMutation} from "@apollo/react-hooks";
import {
    ENTRIESQUERY,
    MUTATION_UPDATE_TRANSACTION, MutationUpdateTransactionResult,
    TRANSACTION, TransactionResult
} from "../../../Queries";
import {useParams} from "react-router";
import { useYear } from "../../../../../providers/year-provider";
import {AdminToolTipOverlay} from "../../../../admin/components/AdminTooltipOverlay";
import {KazboekModalProps} from "../../../../../models/PropDefinitions";
import {TransactionSplitForm} from "./TransactionSplitForm";

interface TransactionSplitModalProps extends KazboekModalProps {
    transactionId: string;
}

export const TransactionSplitModal: React.FC<TransactionSplitModalProps> = ({show, setShow, transactionId}) => {
    const { year } = useYear();
    const { bookId } = useParams<{bookId?: any}>();
    const client = useApolloClient();

    const [ loadTransaction, { called, data: transactionData, loading: transactionLoading }] = useLazyQuery<TransactionResult>(TRANSACTION, { variables: { bookId, transactionId }});
    const [ updateTransaction ] = useMutation<MutationUpdateTransactionResult>(MUTATION_UPDATE_TRANSACTION);

    useEffect(() => {
        if (show && !called) {
            loadTransaction();
        }
    }, [show, called, loadTransaction])

    if (!called || transactionLoading) return (<> </>);

    const transaction = transactionData?.book.transaction

    const initialValues = {
        entries: transaction?.entries.map(e => (
            {
                id: e.id,
                amount: e.amount,
                description: e.description,
                subcategoryYear: e.subcategoryYear
            })
        ) ?? []
    }

    const onSubmit = (values, {setSubmitting}) => {
        console.log('Updating transaction', transaction);
        updateTransaction({
            variables: {
                transactionId: transaction?.id ?? '',
                entries: values.entries.map(e => ({
                    id: e.id,
                    amount: e.amount,
                    date: e.date,
                    description: e.description,
                    subcategoryYear: e.subcategoryYear
                })),
            }
        }).then(({ data }) => {
            // Get current know data.
            const cachedData = client.readQuery({query: ENTRIESQUERY, variables: { bookId, year }})
            //Get all the ID's that belonged to the transaction.
            const existingIds = cachedData.book.entries.filter(e => e.transaction.id === data?.updateTransaction.transaction.id).map(e => e.id);
            const newEntries = data?.updateTransaction.transaction.entries.filter(e => !existingIds.includes(e.id))
            const transactionEntryIds = data?.updateTransaction.transaction.entries.map(e => e.id)
            const removedIds = existingIds.filter(eId => !transactionEntryIds?.includes(eId))

            // @ts-ignore
            const allBookEntries = [...cachedData.book.entries.filter(e => !removedIds.includes(e.id)), ...newEntries].map(e => {
                if (transactionEntryIds?.includes(e.id)) {
                    const tEntry = data?.updateTransaction.transaction.entries.find(te => te.id === e.id)
                    return {...e, amount: tEntry?.amount, description: tEntry?.description}
                }
                return e
            })
            client.writeQuery({query: ENTRIESQUERY, variables: { bookId, year }, data: {
                book: {...cachedData.book, entries: allBookEntries}
            }})
        }).then(() => setSubmitting(false)).then(() => setShow(false))
    }

    return (
        <Modal size={"lg"} show={show} onHide={() => setShow(false)} className='transaction-split-modal'>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}


            >
                {({values, handleSubmit, setValues}) => (
                    <>
                        <Modal.Header closeButton>Transactie opsplitsen</Modal.Header>
                        <Modal.Body>
                            { transaction
                                ? <TransactionSplitForm values={values} transaction={transaction} setValues={setValues} />
                                : <></>}
                        </Modal.Body>
                        <Modal.Footer>
                            { /* @ts-ignore */}
                            <Button type='button' disabled={Math.abs(transaction.amount - values.entries.reduce((sum, e) => !!Number.parseFloat(e.amount) ? sum + Number.parseFloat(e.amount) : sum, 0.0)) > 0.005}
                                    onClick={() => {handleSubmit()}}>Submit</Button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </Modal>
    )
}


export const TransactionSplitModalButton = ({transactionId, ...props}) => {
    const [ show, setShow ] = useState(false);

    props.className += ' entry-split-transaction';

    return (
        <React.Fragment>
            <AdminToolTipOverlay text={`Transactie ${transactionId} opsplitsen`} id={"split-transaction"}>
                <Button onClick={() => setShow(true)} {...props} />
            </AdminToolTipOverlay>
            <TransactionSplitModal show={show} setShow={setShow} transactionId={transactionId} />
        </React.Fragment>

    )
};
