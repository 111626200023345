import {Source} from "../../../models/source.model";
import React from 'react'
import {ListGroup} from "react-bootstrap";
import {Utils} from "../../../helpers/Utils";

interface SourcesInfoProps {
    sources: Source[];
}

export const SourcesInfo = ({sources}: SourcesInfoProps) => {

    let sourcesInfo = sources.map((source: Source) => {
        return <span key={source.id}><strong>{source.name}</strong> : &euro;&nbsp;{Utils.makeDutchCurrency(Number(source.balance) + source.startAmount)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    });

    return (
        <ListGroup>
            <ListGroup.Item>
                {sourcesInfo}
            </ListGroup.Item>
        </ListGroup>
    )

};
