import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {ShieldSlash} from "react-bootstrap-icons";
import {SuperDangerous} from "../../helpers/SuperDangerous";

interface NoBooksMessageProps {

}

export const NoBooksMessage: FunctionComponent<NoBooksMessageProps> = (props) => {

    return (
        <Row className={'mt-4'}>
            <Col xs={12}>
                <h4><ShieldSlash />&nbsp;Geen toegang tot kasboeken</h4>
                <hr />
                <SuperDangerous>
                    <p>Er zijn geen kasboeken gekoppeld aan dit email addres, als je een gevoel dat dit een fout is, neem dan contact op met de beheerder</p>
                </SuperDangerous>
            </Col>
        </Row>
    )
};
