import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {useAuth} from "../../../../../Auth";
import {useQuery} from "@apollo/react-hooks";
import {ENTRIESQUERY, EntriesQueryResult} from "../../../Queries";
import {useFormikContext} from "formik";
import {useYear} from "../../../../../providers/year-provider";
import {EntriesTableValues} from "../../types";
import {Entry} from "../../../../../models/entry.model";

interface CamtUploadProps {
}

export const CamtUpload = (props) => {
    const { resetForm, values: fValues, errors: fErrors, touched: fTouched } = useFormikContext<EntriesTableValues>()
    const { year } = useYear();
    const { token } = useAuth();
    let { bookId } = useParams<{bookId?: any}>();
    const [ file, setFile ] = useState<File | undefined>(undefined);
    const [ alert, setAlert ] = useState('');
    const [ warning, setWarning ] = useState('');
    const [ error, setError ] = useState('');
    const fileInputForm = useRef(null);

    const { refetch } = useQuery<EntriesQueryResult>(ENTRIESQUERY, {variables: {bookId: bookId, year: year}})

    function updateTable(entries: Entry[]) {
        const newEntries = entries.filter(e => fValues.entries.findIndex(ve => ve.id === e.id) === -1);
        //props.refetch();
        resetForm({
            values: {
                ...fValues,
                entries: [
                    ...fValues.entries,
                    ...newEntries.map(entry => ({
                        editing: false,
                        selected: false,
                        ...entry
                    }))
                ],
            },
            touched: {
                ...fTouched,
            },
            errors: {
                ...fErrors,
            }
        })
    }

    useEffect(() => {
        if (alert) {
            const handler = setTimeout(() => setAlert(''), 2000)
            return function () {
                clearTimeout(handler)
            }
        }
    }, [alert]);

    const submitFile = () => {

        if(file === undefined) {
            return;
        }
        // @ts-ignore
        fileInputForm.current.value = null;
        const formData = new FormData();
        const restUrl = '/api/upload_camt052';

        formData.append('file', file);
        formData.append('bookId', bookId);

        fetch(restUrl, {
            method: 'POST',
            body: formData,
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            }
        }).then(response => response.json()
        ).then(data => {
            if(data.success){
                if(data.warning){
                    setWarning(`Succesvol uitgelezen, maar de server meldt het volgende: \n${data.warning}`)
                }else{
                    setAlert('Succesvol uitgelezen, Navigeren naar resultaten.....');
                }

                refetch().then(({data}) => {
                    updateTable(data.book.entries);
                }).then(() => props.setShow(false))
            } else {
                setError('Er is een fout opgetreden, de server meld het volgende ' + data.error)
            }

        }).catch(error => {
            console.log("Fetch Error: ", error);
        });
    };

    return (
        <div>
            <h4 className="mt-3 mb-3">Upload een CAMT053-bestand</h4>
            <Form encType='multipart/form-data'>
                <Form.Group as={Row}>
                    <Col>
                        <Form.File
                            label={file?.name ? file.name : 'Selecteer een CAMT053-bestand (*.xml)'}
                            type='file'
                            name='camtFile'
                            placeholder='Camt053 bestand (*.xml)'
                            id='camtFile'
                            accept='text/xml'
                            onChange={(e) => setFile(e.target.files[0])}
                            ref={fileInputForm}
                            custom
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col>
                        {/* @ts-ignore */}
                        <Button disabled={!fileInputForm.current?.value} onClick={(e) => submitFile(e)}>Uploaden</Button>
                    </Col>
                </Form.Group>
            </Form>
            { (alert) ? <Alert variant='success'>{alert}</Alert> : '' }
            { (warning) ? <Alert variant='warning'>{warning}</Alert> : '' }
            { (error) ? <Alert variant='danger'>{error}</Alert> : '' }
        </div>
    );
};
