import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';


type yearContextType = {
    year: number
    setYear: (number) => void
}

interface MatchParams {
    year: string;
    remainder: string;
    bookId: string;
}


const yearContext = React.createContext<Partial<yearContextType>>({})


interface KazboekParams {
    bookId: string | undefined
}


export const YearProvider = (props) => {
    const routeMatch = useRouteMatch<KazboekParams>('/books/:bookId')
    const bookId = !!routeMatch ? routeMatch.params.bookId : undefined;
    const [ year, setYear ] = useState(() => {
        if (!!bookId) {
            const storedBookYears = JSON.parse(localStorage.getItem('years') || '{}');
            if (!!storedBookYears) {
                if (!!storedBookYears[bookId]) {
                    return +storedBookYears[bookId];
                }
            }
        }
        return new Date().getFullYear();
    });

    useEffect(() => {
        const storedBookYears = JSON.parse(localStorage.getItem('years') || '{}');
        if (!!bookId) {
            storedBookYears[bookId] = year;
            localStorage.setItem('years', JSON.stringify(storedBookYears));
        }
    }, [ year, bookId ])

    return (
        <yearContext.Provider value={{year, setYear}}>
            {props.children}
        </yearContext.Provider>
    )
}


export const useYear = () => {
    const { year, setYear } = useContext(yearContext);

    return { year, setYear };
}