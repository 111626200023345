import React from "react";
import {Modal} from "react-bootstrap";
import {ReceiptUpload} from "./ReceiptUpload";
import {Entry} from "../../../../../../models/entry.model";
import {ReceiptInfoCard} from "../../../../receipts/ReceiptInfoCard";

export interface ReceiptModalProps {
    show: boolean;
    setShow: any;
    entry: Entry;
    props?: any;
    file?: File;
}

export const ReceiptModal = ({file, show, setShow, entry, props}: ReceiptModalProps) => {

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Toevoegen</Modal.Header>
            <Modal.Body>
                { !!entry.receipt
                    ? <ReceiptInfoCard receipt={entry.receipt}/>
                    : <ReceiptUpload file={file} show={show} setShow={setShow} entry={entry} {...props} />
                }
            </Modal.Body>
        </Modal>
    )
};
