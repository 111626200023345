import React, {FunctionComponent, useState} from "react";
import {Alert, Button, Container} from "react-bootstrap";
import {AdminTitle} from "../AdminTitle";
import {People, Plus} from "react-bootstrap-icons";
import {CreateUserModal} from "./CreateUserModal";
import {AdminUsersList} from "./AdminUsersList";
import {RouteComponentProps} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_USERS, GetAllUsersResult} from "./queries";
import {LoadingComponent} from "../../../helpers/LoadingComponent";

interface AdminUsersProps extends RouteComponentProps {

}

export const AdminUsers: FunctionComponent<AdminUsersProps> = (props) => {
    const {loading, error, data, refetch} = useQuery<GetAllUsersResult>(GET_ALL_USERS);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);


    return (
        <Container>
            <CreateUserModal  refetch={refetch} show={showCreateModal} setShow={setShowCreateModal}></CreateUserModal>
            <AdminTitle title={'Gebruikers'} icon={People} component={
                <Button onClick={() => setShowCreateModal(true)} type="button" variant='primary' size="sm"><Plus />&nbsp;Nieuw</Button>
            } description={"Alle gebruikers in het syteem"}/>
            {loading ? <LoadingComponent /> : null}
            {data ? <AdminUsersList users={data.users} {...props}/> : null}
            {error ? <Alert variant={"danger"}>Kon gebruikers niet laden: {JSON.stringify(error)}</Alert> : null }
        </Container>
    )
};
