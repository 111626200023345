import {ListGroup} from "react-bootstrap";
import {MemoizedEntriesTableGroupListEntry} from "./table/row/EntryRow";
import React from "react";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "./types";


interface EntriesTableGroupListProps {
    indices: number[];
}

export const EntriesTableGroupList: React.FunctionComponent<EntriesTableGroupListProps> = ({indices}) => {

    const {initialValues, values} = useFormikContext<EntriesTableValues>();

    return (
        <ListGroup variant='flush'>
            { indices.map((idx) => {
                const entry = values.entries[idx];
                return (
                    <MemoizedEntriesTableGroupListEntry
                        key={entry.id}
                        entry={entry}
                        index={idx}
                        initialEntry={initialValues.entries[idx]}
                    />
                )}

            )}
        </ListGroup>
    )
}
