import React, {useState} from "react";
import {Container, Navbar} from "react-bootstrap";
import logo from '../../assets/kazboek-logo.png'
import "./style.scss"
import {KazboekMenu} from "./menu";
import {NewFeaturesModal} from "../changelog/NewFeaturesModal";


export const KazboekContainer = ({...props}) => {
    //Show new features.
    const [show, setShow] = useState(false);

    return (
        <div className={"min-vh-100"}>
            <Navbar stick='top' className=' navbar-dark kasboek-header' expand='lg'>
                <Navbar.Brand>
                    <img src={logo} id="kazboek-logo"
                         className='ml-4'
                         alt='logo'

                    />
                </Navbar.Brand>

                <Navbar.Toggle className="color-white" aria-controls='responsive-navbar-nav'/>
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <KazboekMenu />
                </Navbar.Collapse>


            </Navbar>
            <Container fluid className={'min-full'}>
                        {props.children}
            </Container>
            <NewFeaturesModal show={show} setShow={setShow}/>
        </div>
    )
};
