import React, {FunctionComponent} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {User} from "../../../models/user.model";
import {ArrowUpRight} from "react-bootstrap-icons";
import {Link, RouteComponentProps} from "react-router-dom";

interface AdminUserRoleInfoProps extends RouteComponentProps{
    user: User;
}

export const AdminUserRoleInfo: FunctionComponent<AdminUserRoleInfoProps> = ({user, ...props}) => {

    return (
        <div className={"admin-info-card"}>
            <Row>
                <Col xs={12}>
                    <h5 className={"d-inline-block"}>Rechten</h5>
                    <Link to={`/admin/roles/${user.role?.id}`}>
                        <Button className={"float-right"} variant={"link"}>Bekijk rol&nbsp;<ArrowUpRight/></Button>
                    </Link>

                </Col>
                <Col xs={12}>
                    <Col xs={12}>
                        <span><strong>Rol: </strong>&nbsp;{user?.role?.name}</span>
                    </Col>
                    <Col xs={12}>
                        <span><strong>Omschrijving: </strong>&nbsp;{user?.role?.description}</span>
                    </Col>
                    <hr />
                    <p>Deze gebruiker heeft {user.role?.permissions?.length} permissies </p>
                </Col>
            </Row>
        </div>
    )
};
