import React from 'react';
// @ts-ignore
import ReactMarkdown from "react-markdown";
// @ts-ignore
import {Col, Container, Row} from "react-bootstrap";

export class Changelog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            markdownText: '',
        }
    }

    componentDidMount() {
        const readmePath = require("./changelog.md");

        fetch(readmePath)
            .then(response => {
                return response.text()
            })
            .then(text => {
                this.setState({
                    markdownText: text
                });
            })
    };


    render () {
        // @ts-ignore
        const {markdownText} = this.state;
        return(
            <Container>
                <Row className='py-2'>
                    <Col xs={12}>
                        <ReactMarkdown escapeHtml={false} source={markdownText} />
                    </Col>
                </Row>
            </Container>
        );
    }


};
