import {Link, RouteComponentProps} from "react-router-dom";
import {Icon} from "react-bootstrap-icons";
import React from "react";


interface AdminSidebarMenuItemProps extends RouteComponentProps, React.HtmlHTMLAttributes<HTMLDataListElement> {
    name: string;
    icon: Icon;
    path: string;
}

export const AdminSidebarMenuItem = (props: AdminSidebarMenuItemProps) => {

    const Icon = props.icon;
    return (

        <li className={(props.className || '') + ' admin-sidebar-menu-item'}>
            <Link to={`${props.match.url}${props.path}`}>
                <span>
                    <Icon className='admin-sidebar-menu-item-icon'/>
                    <span className="admin-sidebar-menu-item-text">{props.name}</span>
                </span>
            </Link>
        </li>
    )

};
