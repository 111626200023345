import {Form} from "react-bootstrap";
import React, {useEffect} from "react";
import {Entry} from "../../../../../../models/entry.model";
import {FastField, useFormikContext} from "formik";
import {EntriesTableValues} from "../../../types";

interface DescriptionInputProps extends React.HTMLProps<HTMLInputElement>{
    entry: Entry;
    index: number;
    id: string;}

export const DescriptionInput: React.FC<DescriptionInputProps> = ({id, entry, index, ...props}) => {

    const {setFieldValue, setFieldTouched, values} = useFormikContext<EntriesTableValues>();

    return (
        <Form.Control
            onChange={e => {
                setFieldValue(`entries[${index}].description`, e.target.value)
            }}
            onBlur={() => setFieldTouched(`entries[${index}].description`, true)}
            name={`entries[${index}].description`}
            value={values.entries[index].description}
            type='text'
            as={FastField}
            id={id}
        />
    )
}
