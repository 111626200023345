import React, { useState} from 'react'
import {Modal, Row, Col, Button} from 'react-bootstrap'
import { CamtUpload } from "./add/CamtUpload";
import { SingleEntry } from "./add/SingleEntry";
import {ReceiptUpload} from "./add/receipt/ReceiptUpload.tsx";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./AddModalStyle.scss";

const AddModalSwitch = ({option, show, setShow, refetch, ...props}) => {
    let result;
    switch(option) {
        case 'camt':
            result = <CamtUpload {...{...props, refetch, setShow}}/>;
            break;
        case 'single':
            result = <SingleEntry show={show} setShow={setShow} />;
            break;
        case 'receipt':
            result = <ReceiptUpload {...{show, setShow, ...props}}/>;
            break;
        default:
            result = <b>Kies iets</b>
    }

    return result
};


const AddModal = ({show, setShow, openoption, refetch,  ...props}) => {
    const [ option, setOption ] = useState(openoption ? openoption : 'camt');

    return (
        <Modal show={show} onHide={() => setShow(false)} className='add-modal'>
            <Modal.Header closeButton>Toevoegen</Modal.Header>
            <Modal.Body>

                <Tabs
                    id='add-modal-tabs'
                    className="even-tabs"
                    defaultActiveKey="camt"
                    activeKey={option}
                    varient="pills"
                    onSelect={(k) => setOption(k)}
                >
                    <Tab eventKey="camt" title="CAMT Bestand"/>
                    <Tab eventKey="single" title="Losse invoer"/>
                    <Tab eventKey="receipt" title="Bon"/>
                </Tabs>
                <Row>
                    <Col>
                        <AddModalSwitch {...{show, setShow, option,...props}} />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
};


export const AddModalButton = ({...props}) => {
    const [ show, setShow ] = useState(false);

    return (
        <React.Fragment>
            <Button type='button' variant='primary' onClick={() => setShow(true)} {...props}/>
            <AddModal {...{show, setShow, ...props}} />
        </React.Fragment>
    )
};
