import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {ENTRIESQUERY, ENTRYDATA, ADDTRANSACTION} from "../../../Queries.ts";
import {Button, ButtonGroup, Form, InputGroup, Spinner} from "react-bootstrap";
import {Field, Formik} from "formik";
import {DateField} from "../../../../forms/DateField";
import React from "react";
import {useYear} from "../../../../../providers/year-provider";


export const SingleEntry = ({ setShow, ...props }) => {
    let { year } = useYear();
    let { bookId } = useParams();

    const { data, loading } = useQuery(ENTRYDATA, { variables: {bookId: bookId } })

    const [ addEntry ] = useMutation(ADDTRANSACTION,
        {
            update(cache, {data: { createTransaction }}) {
                const { book } = cache.readQuery({ query: ENTRIESQUERY, variables: { bookId: bookId, year: year } });

                cache.writeQuery({
                    query: ENTRIESQUERY,
                    variables: { bookId: bookId, year: year },
                    data: { book: {...book, entries: book.entries.concat([...createTransaction.transaction.entries])} },
                });
            }
        });

    function handleSubmit(values, helpers) {
        addEntry({
            variables: {
                date: values.date,
                description: values.description,
                amount: values.amount,
                sourceId: values.sourceId,
                subcategoryId: values.subcategoryId,
            }
        }).then(({ data }) => {
            helpers.setSubmitting(false)
        }).then(() => setShow(false))

    }

    if (loading) return <Spinner animation='border' />

    const categories = data.book.categories.filter(c => (c.subcategories.length > 0))
    const sources = data.book.sources.filter(s => (!!!s.isCamtSource))

    if (categories.length === 0) return 'Er zijn geen categoriën en/of subcategoriën aangemaakt.'
    if (sources.length === 0) return 'Er zijn geen bronnen waar handmatige regels aan toegevoegd kunnen worden.'

    const submitAndAgain = (values, { setValues, submitForm }) => {
        const rememberedValues = {...values}
        submitForm().then(
            setValues(rememberedValues, false)
        )
    }

    return (
        <Formik
            initialValues={{
                date: new Date(),
                description: '',
                amount: 0.0,
                sourceId: '',
                categoryId: '',
                subcategoryId: '',
            }}
            onSubmit={handleSubmit}
        >
            {({ values, handleReset, handleSubmit, submitForm, setValues, setFieldValue }) => (
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <h4 className="mt-3 mb-3">Voer een losse bankmutatie</h4>
                    <Form.Group>
                        <Form.Label>Datum</Form.Label>
                        <DateField name='date' />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Beschrijving</Form.Label>
                        <Field as={Form.Control} name='description' type='text'/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bedrag</Form.Label>
                        <Field name='amount'>
                            {({field, meta, helpers}) => (
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>€</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type='number' step='0.01' {...field} />
                                    <InputGroup.Append>
                                        <Button onClick={() => setFieldValue('amount', -1.0 * values.amount)}>
                                            { (values.amount < 0.0) ? <span><b>Af</b> / Bij</span> : <span>Af / <b>Bij</b></span> }
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Bron</Form.Label>
                        <Field name='sourceId'>
                            {({field, meta, helpers}) => (
                                <Form.Control as='select' {...field}>
                                    <option value=''>Selecteer categorie</option>
                                    { sources.map(s => {
                                        return <option key={s.id} value={s.id}>{s.name}</option>
                                    })}
                                </Form.Control>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Categorie</Form.Label>
                        <Field name='categoryId'>
                            {({field, meta, helpers}) => (
                                <Form.Control as='select' {...field}>
                                    <option value=''>Selecteer categorie</option>
                                    { categories.map(c => {
                                        return <option key={c.id} value={c.id}>{c.name}</option>
                                    })}
                                </Form.Control>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Subcategorie</Form.Label>
                        <Field name='subcategoryId'>
                            {({field, meta, helpers}) => {
                                const subcategories = categories.reduce((subcategories, c) => {
                                    return (!!c.subcategories && (c.id === values.categoryId)) ? [...subcategories, ...c.subcategories] : subcategories;
                                }, [])
                                return (
                                    <Form.Control as='select' {...field}>
                                        <option value=''>Selecteer subcategorie</option>
                                        { subcategories.map(sc => {
                                            return <option key={sc.id} value={sc.id}>{sc.name}</option>
                                        })}
                                    </Form.Control>
                                )
                            }}
                        </Field>
                    </Form.Group>
                    <Form.Group>
                        <ButtonGroup className='w-100'>
                            <Button type='button' onClick={() => submitForm()}>Toevoegen</Button>
                            <Button type='button' onClick={() => submitAndAgain(values, { setValues, submitForm })}>Toevoegen en nog een</Button>
                        </ButtonGroup>
                    </Form.Group>
                </Form>
            )}
        </Formik>
    )
}
