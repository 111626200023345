import React, {FunctionComponent, useEffect, useState} from "react";
import {useCurrentUser} from "../../providers/CurrentUserProvider";
import {userHasPermission} from "./guard-helper";

interface GuardedComponentProps {
    permission: string[] | string;
    mode?: 'all' | 'any';
}

export const GuardedComponent: FunctionComponent<GuardedComponentProps> = (props) => {

    const {user} = useCurrentUser();
    const [isAllowed, setIsAllowed] = useState<boolean>(false);

    useEffect(() => {
        if(user?.role?.permissions) {
            setIsAllowed(userHasPermission({
                permission: Array.isArray(props.permission) ? props.permission : [props.permission],
                mode: props.mode ? props.mode : 'any',
                userPermissions: user?.role?.permissions
            }));
        } else {
            setIsAllowed(false);
        }

    }, [props.permission, user, props.mode]);

    return (
        <React.Fragment>
            {isAllowed ? props.children : null}
        </React.Fragment>
    )
};
