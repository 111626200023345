import React, {useState} from "react";
import { useParams } from 'react-router-dom';
import {useMutation} from "@apollo/react-hooks";
import {DELETEENTRY, ENTRIESQUERY} from "../Queries";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useFormikContext} from "formik";
import {Entry} from "../../../models/entry.model";
import {ButtonVariant} from "react-bootstrap/types";
import {EntriesTableValues} from "./types";


interface DeleteEntryModalFormProps {
    entry: Entry;
    size?: 'sm' | 'lg'
    variant?: ButtonVariant;
    disabled: boolean;
    tabIndex?: number;
}

export const DeleteEntryModalForm: React.FC<DeleteEntryModalFormProps> = ({entry, size = 'sm', variant = 'primary', ...props}) => {
    let { bookId } = useParams<{bookId?: any}>();
    const [ show, setShow ] = useState(false);
    const entriesFormik = useFormikContext<EntriesTableValues>();

    const [ deleteEntry ] = useMutation(DELETEENTRY,
        {
            update(cache, {data: { deleteEntry }}) {
                // @ts-ignore
                const { book } = cache.readQuery({query: ENTRIESQUERY, variables: {bookId: bookId}});
                if (deleteEntry.ok) {
                    const fIdx = entriesFormik.values.entries.findIndex(e => e.id === entry.id);
                    const names = ['entries'];
                    const { values, touched, errors } = entriesFormik;
                    entriesFormik.resetForm({
                        values: names.reduce((v, n) => ({...v, [n]: values[n].filter((_, i) => i !== fIdx)}), {...values}),
                        touched: names.reduce((v, n) => ({...v, [n]: !touched[n] ? [] : touched[n].filter((_, i) => i !== fIdx)}), {...touched}),
                        errors: names.reduce((v, n) => ({...v, [n]: !errors[n] ? [] : errors[n].filter((_, i) => i !== fIdx)}), {...errors}),
                    })

                    cache.writeQuery( {
                        query: ENTRIESQUERY,
                        variables: { bookId: bookId },
                        data: { book: {...book, entries: book.entries.filter(e => e.id !== deleteEntry.entry.id)} }
                    })
                }
            }
        });

    function handleSubmit(e) {
        e.preventDefault();
        deleteEntry({variables: {id: entry.id}}).then(({data}) => setShow(false));
    }

    function getDate(date){
        date = new Date(date);
        return date.getDay() + '-' + date.getMonth() + '-' + date.getFullYear();
    }

    return (
        <React.Fragment>
            <Button size={size} variant={variant} onClick={() => setShow(true)} {...props}>{props.children}</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Regel verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col><h3>Deze regel wordt verwijderd: Zeker weten?</h3></Col>
                        </Row>
                        <Row><Col>{ 'Datum: ' + getDate(entry.date) }</Col></Row>
                        <Row><Col>{ entry.description }</Col></Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Ja, verwijderen.
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
