import {Button, Modal, Row, Col} from "react-bootstrap";
import React from "react";

interface HelpModalProps {
    setShow: (show: boolean) => void;
    show: boolean;
}

export const HelpModal = ({show, setShow}:HelpModalProps) => {

    const cancel = () => {
        setShow(false);
    };


    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Help!</Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        Hulp nodig? Kijk op <a id='linkAnchor' target='_blank' rel='noopener noreferrer' href='https://help.kazboek.nl'>help.kazboek.nl</a>.
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        Heb je vragen, problemen, een fout ontdekt, suggesties voor verbeteringen of iets anders, mail dan naar:<br />
                        <a id={'mailAnchor'} href="mailto:kazboek@katwijksezeeverkenners.nl?subject=[kazboek] JE VRAAG HIER">kazboek@katwijksezeeverkenners.nl</a>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {(document.querySelector("#mailAnchor") as HTMLAnchorElement).click()}} variant='primary'>Stuur mail.</Button>
                <Button onClick={() => {cancel()}} variant='danger'>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )

};
