import React, {useContext} from 'react'
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CATEGORIES, GET_SPLIT_PAYMENTS, GetSplitPaymentResult, SOURCES} from "./Queries";
import { useParams } from 'react-router-dom';
import { Book } from '../../models/book.model';
import {Category} from "../../models/category.model";
import {Source} from "../../models/source.model";
import {MutationTuple} from "@apollo/react-hooks/lib/types";
import {OperationVariables} from "@apollo/react-common";

interface BookContextProps {
    categories: Category[];
    sources: Source[];
    book: Book | undefined;
    autoSplitQuery: MutationTuple<GetSplitPaymentResult, OperationVariables>
}


// @ts-ignore
export const BookContext = React.createContext<BookContextProps>({categories: [], sources: [], book: undefined})


export const BookContextProvider = (props) => {
    let { bookId } = useParams<{bookId?: any}>();

    const { data, loading } = useQuery(CATEGORIES, {
        variables: {bookId: bookId },
    });

    const { data: sourcesData, loading: sourcesLoading } = useQuery(SOURCES, {
        variables: {bookId: bookId },
    });

    const autoSplitQuery = useMutation<GetSplitPaymentResult>(GET_SPLIT_PAYMENTS);

    if (loading || sourcesLoading) return <b>Loading categories and sources....</b>;

    if (!data.book) return null;

    return (
        <BookContext.Provider value={{
            categories: data.book.categories,
            sources: sourcesData.book.sources,
            book: data.book,
            autoSplitQuery
        }}
        >
            {props.children}
        </BookContext.Provider>
    )
};


export const useBookContext = () => {
    return useContext(BookContext)
};
