import React, {FunctionComponent, useState} from "react";
import {Source} from "../../../../models/source.model";
import {Pencil} from "react-bootstrap-icons";
import {EditSourceModal} from "./EditSourceModal";

interface EditSourceButtonProps {
    source: Source
    refetch: () => void;
}

export const EditSourceButton: FunctionComponent<EditSourceButtonProps> = (props) => {

    const [show, setShow] = useState<boolean>(false);

    return (
        <span>
            <EditSourceModal source={props.source}
                             show={show}
                             setShow={setShow}
                             refetch={props.refetch}/>
            <Pencil onClick={() => setShow(true)}/>
        </span>

    )
};
