import React, {FunctionComponent, ReactElement} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


interface AdminTooltipOverlayProps {
    text: string;
    id: string;
}

export const AdminToolTipOverlay: FunctionComponent<AdminTooltipOverlayProps> = (props) => {

    return (
        <OverlayTrigger placement="auto"
                        delay={{show: 250, hide: 400}}
                        overlay={<Tooltip {...props}>{props.text} </Tooltip>}>
            {props.children as ReactElement}
        </OverlayTrigger>
    )

};
