import gql from "graphql-tag";

export const GET_ALL_RECEIPTS =  gql`
    query getBookReceipts($bookId: ID!){
        book(bookId: $bookId){
            id
            receipts { 
                id,
                bookId
                fileName,
                description,
                amount,
                entry {
                    id,
                    description,
                    amount
                }
            }
        }
    }
`;

export const DELETE_RECEIPT = gql`
    mutation deleteReceipt($receiptId: ID!){
        deleteReceipt(receiptId: $receiptId){
            ok
        }
    }
`;
