import React from 'react';
import {ListGroup} from 'react-bootstrap';

import {SubcategoryView} from "./SubcategoryView";

export const Subcategories = (props) => {
    const subcategories = props.subcategories.map(subcategory =>  {
        return <SubcategoryView key={subcategory.id} name={subcategory.name} id={subcategory.id} />
    });

    return (
        <React.Fragment>
            <ListGroup className='my-2'>
                {subcategories}
            </ListGroup>

        </React.Fragment>
    )
}
