import {useParams} from "react-router-dom";
import {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {CATEGORIES, DELETESUBCATEGORY} from "../Queries";
import {Button, Form, Modal} from "react-bootstrap";
import React from "react";

export const  DeleteSubcategoryModalForm = (props) => {
    let { bookId } = useParams<{bookId?: any}>();
    const [ show, setShow ] = useState(false);

    const [ deleteSubcategory ] = useMutation(DELETESUBCATEGORY,
        {
            refetchQueries: [{query: CATEGORIES, variables: {bookId: bookId}}],
        });

    function handleSubmit(e) {
        e.preventDefault();
        deleteSubcategory({variables: {id: props.id}});
        setShow(false);
    }

    return (
        <React.Fragment>
            <Button size='sm' variant="primary" onClick={() => setShow(true)} className='mr-2'>
                Verwijderen
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Subcategorie verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Subcategorie {props.name} wordt verwijderd. Zeker weten?</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Ja, verwijderen.
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
