import React, {FunctionComponent} from "react";
import {User} from "../../../models/user.model";
import {Col, Row} from "react-bootstrap";
import "../admin-style.scss"
import {AdminUserBooksInfo} from "./books/AdminUserBooksInfo";
import {AdminUserRoleInfo} from "./AdminUserRoleInfo";
import { RouteComponentProps } from "react-router-dom";
import {AdminUserBaseInfo} from "./AdminUserBaseInfo";

interface AdminUserViewerProps extends RouteComponentProps{
    user: User
    refetch: () => void;
}

export const AdminUserViewer: FunctionComponent<AdminUserViewerProps> = ({user, ...props}) => {

    return (
        <Row>
            <Col xs={12} md={6}>

                <AdminUserBaseInfo user={user} refetch={props.refetch}/>
                {user.books ? <AdminUserBooksInfo books={user.books}/> : <AdminUserBooksInfo books={[]}/>}

            </Col>
            <Col xs={12} md={6}>
                <AdminUserRoleInfo {...props} user={user}/>
            </Col>
        </Row>
    )
};
