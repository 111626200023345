import React, {FunctionComponent} from "react";
import {User} from "../../../models/user.model";
import {Col, Row} from "react-bootstrap";
import {EditUserButton} from "./EditUserButton";

interface AdminUserBaseInfoProps {
    user: User;
    refetch: () => void;
}

export const AdminUserBaseInfo: FunctionComponent<AdminUserBaseInfoProps> = (props) => {

    return (
        <Row>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5 className={'d-inline-block'}>Gegevens</h5>
                    <span className={'float-right'}>
                        <EditUserButton  user={props.user} refetch={props.refetch}/>
                    </span>

                </Col>
                <hr />
                <Col xs={12}>
                    <span><strong>ID:</strong>&nbsp;{props.user.id}</span>
                </Col>
                <Col xs={12}>
                    <span><strong>Email:</strong>&nbsp;{props.user.email}</span>
                </Col>
            </div>
        </Row>
    )
};
