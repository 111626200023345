import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../components/AdminTooltipOverlay";
import {Link, RouteComponentProps} from "react-router-dom";
import {Pencil} from "react-bootstrap-icons";
import {BookUser, bookUserNameSorter} from "../../../models/book-user.model";

interface AdminBooksListProps extends RouteComponentProps {
    books: BookUser[];
}

export const AdminBooksList: FunctionComponent<AdminBooksListProps> = ({books, ...props}) => {

    books.sort(bookUserNameSorter);

    return (
        <div className={"admin-list"}>
            {
                books.map((bookUser) => (
                    <Row key={bookUser.book?.id} className={"admin-list-item"}>
                        <Col xs={1}>
                            {bookUser.book?.id}
                        </Col>
                        <Col xs={4}>
                            <Link
                                  to={`${props.match.url}/${bookUser.book?.id}`}
                                  className={"kazboek-link"}>
                            {bookUser.book?.name}
                            </Link>
                        </Col>
                        <Col xs={2}>
                            {bookUser.role?.name}
                        </Col>
                        <Col xs={2}>
                            {bookUser.book?.users.length ? <span>
                                {bookUser.book.users.length}&nbsp;{(bookUser.book?.users.length > 1) ? "gebruikers" : "gebruiker"}
                            </span> : ""}
                        </Col>
                        <Col xs={2} className='text-right'>
                            <AdminToolTipOverlay text={"Bewerk kazboek"} id={"overlay_edit_"+bookUser.book?.id}>
                                <Link
                                    to={`${props.match.url}/${bookUser.book?.id}`}
                                    className={"kazboek-link"}>
                                    <Pencil className="admin-spaced-icon"/>
                                </Link>
                            </AdminToolTipOverlay>
                        </Col>
                    </Row>

                ))
            }
        </div>
    )
};
