import {useQuery} from "@apollo/react-hooks";
import {GET_ROLES_AND_PERMISSIONS, GetRolesAndPermissionsResult} from "./queries";
import {LoadingComponent} from "../../../helpers/LoadingComponent";
import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../components/AdminTooltipOverlay";
import {Link, RouteComponentProps} from "react-router-dom";
import {Pencil, Trash} from "react-bootstrap-icons";


interface AdminRolesListProps extends RouteComponentProps {

}

export const AdminRolesList: FunctionComponent<AdminRolesListProps> = (props) => {

    const {data, loading, error} = useQuery<GetRolesAndPermissionsResult>(GET_ROLES_AND_PERMISSIONS);

    if(loading){
        return <LoadingComponent />
    }

    if(error){
        return <h3>Er is een fout opgetreden: {error}</h3>
    }

    if(data){

        data.roles.sort((roleA, roleB) => {
            return roleA.name > roleB.name ? 1 : -1;
        });

        return (
            <div className={"admin-list"}>
                {
                    data?.roles.map((role) => (

                        <Row  className={"admin-list-item"}>
                            <Col xs={1}>
                                {role.id}
                            </Col>
                            <Col xs={5}>
                                <Link key={role.id}
                                      to={`${props.match.url}/${role.id}`}
                                      className={'kazboek-link'}>
                                {role.name}
                                </Link>
                            </Col>
                            <Col xs={4}>
                                {role.permissions?.length}&nbsp;permissies
                            </Col>
                            <Col xs={2} className='text-right'>
                                <AdminToolTipOverlay text={"Bewerk rol"} id={"overlay_edit_"+role.id}>
                                    <Link key={role.id}
                                          to={`${props.match.url}/${role.id}`}
                                          className={'kazboek-link'}>
                                        <Pencil className="admin-spaced-icon"/>
                                    </Link>
                                </AdminToolTipOverlay>
                                <AdminToolTipOverlay text={"Kazboek verwijderen"} id={"overlay_remove_"+role.id} >
                                    <Trash  className="admin-spaced-icon"/>
                                </AdminToolTipOverlay>
                            </Col>
                        </Row>

                    ))
                }
            </div>

        )
    }
    return <h1>Something went wrong</h1>



};
