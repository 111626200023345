import React, {useEffect, useState} from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import {useCurrentUser} from "../../providers/CurrentUserProvider";
import {userHasPermission} from "./guard-helper";
import {LoadingComponent} from "../../helpers/LoadingComponent";

interface GuardedRouteProps extends RouteComponentProps{
    permission: string | string[];
    mode: 'any' | 'all' | undefined;
}

export const GuardedRoute = (props: GuardedRouteProps) => {

    const {user} = useCurrentUser();
    const [isAllowed, setIsAllowed] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if(user?.role?.permissions) {
            let hasPermission = userHasPermission({
                permission: Array.isArray(props.permission) ? props.permission : [props.permission],
                mode: props.mode ? props.mode : 'any',
                userPermissions: user?.role?.permissions
            });
            setIsAllowed(hasPermission);
        } else {
            setIsAllowed(undefined);
        }

    }, [props.permission, user, props.mode]);

    if(!user || user.id === 0){
       return  <LoadingComponent />
    }

    if (isAllowed || isAllowed === undefined){
        return <Route {...props} />
    } else {

        return <Redirect to={'/'} />
    }

};
