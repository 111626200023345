import {Receipt} from "../../../../../../models/receipt.model";
import * as React from "react";
import {PdfViewer} from "./PdfViewer";
import {ProtectedImage} from "../../../../../../helpers/ProtectedImage";

export interface ReceiptViewProps  {
    receipt: Receipt
    bookId: number;
}

export enum FileType {
    None='',
    Image= 'jpg',
    PDF = 'pdf'
}

export const ReceiptView = ({receipt, bookId}: ReceiptViewProps) => {

    const baseUrl = `/api/receipts/${bookId}/${encodeURIComponent(receipt.fileName)}`;
    // const smallUrl = baseUrl.split('.jpg').join('_small.jpg');

    const extension = receipt.fileName.split('.').pop();

    let element = <div>Kan bon niet laden</div>;

    if (extension === FileType.PDF) {
        element = (
            <PdfViewer  fileLocation={baseUrl} />
        )
    } else if (extension === FileType.Image){
        element = <ProtectedImage className={"img-fluid"} fluid src={baseUrl} alt={receipt.fileName}/>
    }

    return (
        <div onClick={() => window.open(baseUrl, '_blank')}>
            {element}
        </div>
    )
};
