import React from 'react';
import { ListGroup, Container} from 'react-bootstrap';
import { useQuery} from "@apollo/react-hooks";
import { useParams } from 'react-router-dom';
import { CATEGORIES } from "../Queries";
import { CreateCategoryModalForm } from "./CreateCategoryModalForm";
import { CreateSubcategoryModalForm } from "./CreateSubcategoryModalForm";
import {Plus, Tag} from "react-bootstrap-icons";
import {AdminTitle} from "../../admin/AdminTitle";
import {CategoryView} from "./CategoryView";



export function Categories(props) {
    let { bookId } = useParams<{bookId?: any}>();

    // @ts-ignore
    const { data, loading, errors } = useQuery(CATEGORIES, {
        variables: {bookId: bookId},
    });

    if (loading) return <h2>Loading....</h2>;
    if (errors) return <p>JSON.stringify(errors)</p>;

    const categories = data['book']['categories'].map(category =>  {
        return <CategoryView key={category.id} allCategories={data.book.categories} category={category} bookId={ bookId } />
    });

    const categories_clean = data['book']['categories'].map(category => {
        return {id: category.id, name: category.name}
    });

    const CategoryButtons = () => {

        return (
            <React.Fragment>
                <CreateCategoryModalForm className={"btn-sm"}><Plus/>&nbsp;Voeg een categorie toe</CreateCategoryModalForm>
                &nbsp;
                <CreateSubcategoryModalForm className={"btn-sm"} categories={categories_clean} ><Plus />&nbsp;Voeg een subcategorie toe</CreateSubcategoryModalForm>

            </React.Fragment>
            )

    };

    return (
        <React.Fragment>
            <Container>
                <AdminTitle title={"Categorien beheren"}
                            icon={Tag}
                            description={"Beheer hier je categorieen"}/>
                <CategoryButtons/>
                <br />
                <br />
                <ListGroup>
                    {categories}
                </ListGroup>
                <br />
                <CategoryButtons></CategoryButtons>
            </Container>
        </React.Fragment>
    )
}
