import React from 'react'
import {Button, ButtonGroup, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {DateInput} from "./form/Date";
import {DescriptionInput} from "./form/Description";
import {CategorySelect} from "./form/CategorySelect";
import {SubcategorySelect} from "./form/SubcategorySelect";
import {DeleteEntryModalForm} from "../../DeleteEntryModalForm";
import {FileCheck, Trash, X, ArrowDownUp} from "react-bootstrap-icons";
import {CamtButton} from "./camt/CamtButton";
import {SelectButton} from "./SelectButton";
import {SubcategoryYearInput} from "./form/Year";
import {useLimitedFormikContext} from "../../EntriesTableContext";
import {TransactionSplitModalButton} from "./TransactionSplitModal";
import {AdminToolTipOverlay} from "../../../../admin/components/AdminTooltipOverlay";
import { EditableEntry} from "../../EntriesTable";
import { useFormikContext} from "formik";
import {EntriesTableValues} from "../../types";

interface EntryFormProps {
    initialEntry: EditableEntry;
    entry: EditableEntry;
    index: number;
}

export const EntryForm: React.FunctionComponent<EntryFormProps> = ({initialEntry, entry, index}) => {
    // @ts-ignore
    const { saveEntries } = useLimitedFormikContext();

    const {setFieldValue, setFieldTouched, values} = useFormikContext<EntriesTableValues>();
    const formikProps = useFormikContext<EntriesTableValues>();

    return (
        <FormGroup as={Row} className='mb-0 mt-0'>
            <Col sm={1}>
                <DateInput size='sm' name={`entries[${index}].date`} entry={entry}/>
            </Col>
            <Col sm={3}>
                <DescriptionInput index={index} id={`entry-${entry.id}-description-input`} entry={entry} />
            </Col>
            <Col sm={1}/>
            <Col sm={4}>
                <Row>
                    <Col sm={5}>
                        <CategorySelect index={index} entry={entry}/>
                    </Col>
                    <Col sm={5}>
                        <SubcategorySelect index={index} entry={entry}/>
                    </Col>
                    <Col sm={2}>
                        <SubcategoryYearInput index={index} entry={entry} size='sm' />
                    </Col>
                </Row>
            </Col>
            <Col sm={1}>
                <InputGroup size='sm'>
                    <InputGroup.Prepend>
                        <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        className='text-right input-sm'
                        onChange={e => setFieldValue(`entries[${index}].amount`, e.target.value)}
                        onBlur={() => setFieldTouched(`entries[${index}].amount`, true)}
                        name={`entries[${index}].amount`}
                        value={entry.amount}
                        disabled={entry.isCamtEntry}
                        type='text'/>
                </InputGroup>
            </Col>
            <Col sm={2} className='text-right'>
                <ButtonGroup size='sm'>
                    {entry.isCamtEntry ?

                        <CamtButton entry={entry} /> :
                        <AdminToolTipOverlay text={"Verwijder entry"} id={"delete-entry"}>
                            <DeleteEntryModalForm tabIndex={-1} disabled={entry.isCamtEntry ?? false} size='sm' entry={entry}>
                                <Trash size={18}/>
                            </DeleteEntryModalForm>
                        </AdminToolTipOverlay>
                    }
                    <AdminToolTipOverlay text={"Entry opslaan"} id={"entry-save"}>
                        <Button className='entry-save' tabIndex={-1} onClick={() => {
                            saveEntries({
                                    entries: [entry],
                                    options: values.options,
                                    filters: values.filters
                                }
                            , formikProps).then(
                                () => setFieldValue(`entries[${index}].editing`, false)
                            )
                        }}>

                        <FileCheck size={20}/>
                        </Button>
                    </AdminToolTipOverlay>
                    <AdminToolTipOverlay text={"Annuleren"} id={"cancel-edit"}>
                        <Button className='entry-cancel-edit' tabIndex={-1} onClick={() => {
                            setFieldValue(`entries[${index}]`, initialEntry)
                        }}>
                            <X size={20}/>
                        </Button>
                    </AdminToolTipOverlay>
                    <AdminToolTipOverlay text={"Meerdere selecteren"} id={"select-multiple"}>
                        <SelectButton entry={entry} index={index} />
                    </AdminToolTipOverlay>
                    {
                        entry.transaction
                            ?   <TransactionSplitModalButton transactionId={entry.transaction.id} className='entry-split'>
                                    <ArrowDownUp size={20} />
                                </TransactionSplitModalButton>
                            :   <></>
                    }

                </ButtonGroup>
            </Col>
        </FormGroup>
    )
};
