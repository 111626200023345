import {Nav, NavDropdown, Button } from "react-bootstrap";
import { useLocation, Redirect } from 'react-router-dom'
import {LinkContainer} from "react-router-bootstrap";
import {NavRouterLink} from "../BootstrapRouter";
import React, {useState} from "react";
import { useAuth } from "../../Auth";
import './style.scss';
import { List } from "react-bootstrap-icons";
import {HelpModal} from "../../helpers/HelpModal";
import {useYear} from "../../providers/year-provider";
import {GuardedComponent} from "../guards/GuardedComponent";


const ColouredLink = ({to, ...props}) => {
    let location = useLocation();

    return (
        <NavRouterLink active={location.pathname === to} eventKey={to} to={to} {...props}>{props.children}</NavRouterLink>
    )
};


// const DropdownLink = ({to, ...props}) => {
//     let location = useLocation();
//
//     return (
//         <LinkContainer to={location.pathname} {...props}>
//             <NavDropdown.Item eventKey={to}>{props.children}</NavDropdown.Item>
//         </LinkContainer>
//     )
// };

export const DropdownTitle = (props) => {
  if(window.innerWidth >= 992){
      return <List size={24} color='white' />;
  } else {
      return "Opties";
  }
};


export const YearSelect = () => {
    const { year, setYear } = useYear();

    const onClickNext = (e) => {
        e.preventDefault();
        setYear(year => year + 1)
    };

    const onClickPrevious = (e) => {
        e.preventDefault();
        setYear(year => year - 1)
    };

    return (
        <div className='text-white nav-link'>
            <span id='previous-year' className='text-white' onClick={onClickPrevious}>&lt;</span>
            <span id='current-year' className='mx-2'>{year}</span>
            <span id='next-year' className='text-white' onClick={onClickNext}>&gt;</span>
        </div>
    )
};


export const KazboekMenu = (props) => {
    const defaultYear = new Date().getFullYear();
    let location = useLocation();
    const [ showHelp, setShowHelp ] = useState(false);
    const { isAuthenticated, logout, login } = useAuth();

    const locationSplit = location.pathname.split('/');
    const booksIndex = locationSplit.indexOf('books');


    let bookId, year;
    if (booksIndex > -1) {
        bookId = locationSplit[booksIndex + 1];
        year = (!!locationSplit[booksIndex + 2]) ? locationSplit[booksIndex + 2] : defaultYear;

        if (year === 'undefined') {
            year = defaultYear;
        }

        if (!Number.parseInt(year)) {
            year = defaultYear;
        }
    }

    if (!isAuthenticated) {
        return <Button className='ml-auto' variant='primary' onClick={() => login()}>Login</Button>
    }

    if (bookId === 'undefined') {
        return <Redirect to='/books' />
    }

    const showHelpHelper = () => {
        setShowHelp(true);
    };



    return (
        <Nav className='ml-auto' activeKey={location} defaultActiveKey='aaaa'>
            { bookId !== undefined ?
                <React.Fragment>
                    <YearSelect/>
                    <ColouredLink className='vertical-line' to={`/books/${bookId}/entries`}>Administratie</ColouredLink>
                    <ColouredLink className='vertical-line' to={`/books/${bookId}/dashboard`}>Dashboard</ColouredLink>
                </React.Fragment>
                : <ColouredLink to={`/books`}>Kasboeken</ColouredLink>}


            {/*<NavDropdown alignRight id='menu-dropdown' title={<List size={24} color='white' />}>*/}
            <NavDropdown alignRight id='menu-dropdown' title={<DropdownTitle/>}>
                { bookId !== undefined ?
                    <React.Fragment>
                        <GuardedComponent permission={'viewCategory'}>
                            <LinkContainer to={`/books/${bookId}/categories`}>
                                <NavDropdown.Item>Categorieën</NavDropdown.Item>
                            </LinkContainer>
                        </GuardedComponent>
                        <GuardedComponent permission={'viewReceipt'} >
                            <LinkContainer to={`/books/${bookId}/receipts`}>
                                <NavDropdown.Item>Bonnen</NavDropdown.Item>
                            </LinkContainer>
                        </GuardedComponent>
                    </React.Fragment>
                    : '' }
                <div>
                    <NavDropdown.Item onClick={showHelpHelper} >Help</NavDropdown.Item>
                    <HelpModal setShow={setShowHelp} show={showHelp}/>
                </div>
                <LinkContainer to={`/books`} exact={true}>
                    <NavDropdown.Item>Kasboeken</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={`/changelog`} exact={true}>
                    <NavDropdown.Item>Changelog</NavDropdown.Item>
                </LinkContainer>
                <GuardedComponent permission={['viewAllBooks', 'viewAllUsers']} mode={'any'}>
                    <LinkContainer to={`/admin`} exact={true}>
                        <NavDropdown.Item>Admin paneel</NavDropdown.Item>
                    </LinkContainer>
                </GuardedComponent>
                <LinkContainer className='text-decoration-none' to='/' exact={true} onClick={logout}>
                    <NavDropdown.Item>Uitloggen</NavDropdown.Item>
                </LinkContainer>
            </NavDropdown>
        </Nav>
    )
};
