import React, {useContext, useEffect, useState} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import useInterval from "./helpers/useInterval";

export const authContext = React.createContext({isAuthenticated: false, token: ''})


export const AuthContextProvider = (props) => {
    const [ token, setToken ] = useState(null)

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const urlSearch = new URLSearchParams(location.search)
        if ((location.pathname === '/callback') && urlSearch.has('code') && urlSearch.has('state') && (!token)) {
            const code = urlSearch.get('code');
            const state = urlSearch.get('state');

            fetch(`/api/callback?code=${code}&state=${state}`, {method: 'GET'})
                .then(response => response.json())
                .then((data) => {
                    setToken(data['token'])
                    history.push('/')
                }).catch(() => history.push('/'))
        } else {
            //Disabled to prevent spamm!!!
            if (!token) {
                fetch('/api/refresh', {method: 'GET'})
                    .then(r => r.json())
                    .then(data => setToken(data['token'])).catch(() => history.push('/'))
            }
        }

    }, [token, history, location]);

    // Refresh the token every hour
    useInterval(() => {
        fetch('/api/refresh', {method: 'GET'})
            .then(r => r.json())
            .then(data => setToken(data['token'])).catch(() => history.push('/'))
    }, 3600000.0)

    const logout = () => {
        const returnTo = window.location.origin;
        fetch('/api/logout', {method: 'GET'})
            .then(() => {
                sessionStorage.clear();
                setToken(null);
                window.location.href = `https://er-kazboek.eu.auth0.com/v2/logout?returnTo=${returnTo}`;
            })
    }

    const login = () => {
        window.location.href = '/api/login'
    };

    return (
        <authContext.Provider value={{token, isAuthenticated: !!token, logout, login}}>
            {props.children}
        </authContext.Provider>
    )
};


export const useAuth = () => {
    return useContext(authContext);
};


