import {Form} from "react-bootstrap";
import React from "react";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "../../../types";


export const SubcategoryYearInput = ({entry, index, ...props}) => {

    const {setFieldValue, setFieldTouched} = useFormikContext<EntriesTableValues>();

    return (
        <Form.Control
            onChange={e => setFieldValue(`entries[${index}].subcategoryYear`, Number.parseInt(e.target.value))}
            onBlur={() => setFieldTouched(`entries[${index}].subcategoryYear`, true)}
            name={`entries[${index}].subcategoryYear`}
            value={entry.subcategoryYear ?? ''}
            type='number'
            step='1'
            min='2000'
            max='3000'
            className='year-input'
            {...props}
        />
    )
}
