import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, Modal, Form} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import {useLocalStorage} from "../../helpers/useLocalStorage";
import {Utils} from "../../helpers/Utils";


interface NewFeaturesModalProps {
    setShow: (show: boolean) => void;
    show: boolean;
}

interface LocalStorageVersion {
    lastHash: number
    shown: boolean
}

export const NewFeaturesModal: FunctionComponent<NewFeaturesModalProps> = (props) => {

    //Doesn't have to updated manually, it calculates a hash, and if it's different, it shows the popup
    const currentVersion: LocalStorageVersion = {
        lastHash: 0,
        shown: true
    };

    // Dont see again checkbox.
    const [dsaCheckbox, setDsaCheckbox] = useState<boolean>(false);
    const [markdownText, setMarkdownText] = useState<string>("");
    const [storedVersion, setStoredVersion] = useLocalStorage<LocalStorageVersion>(currentVersion, 'new_features_shown');
    const closeModal = () => {
        if (dsaCheckbox) {
            storedVersion.shown = false;
            setStoredVersion(storedVersion);
        }
        props.setShow(false);

    };

    useEffect(() => {
        const readmePath = require("./new_features.md");

        fetch(readmePath)
            .then(response => {
                return response.text()

            })
            .then(text => {
                setMarkdownText(text);
                let hash: number = Utils.hashString(text);
                currentVersion.lastHash = hash;
                if(storedVersion.lastHash !== currentVersion.lastHash){
                    setStoredVersion(currentVersion);
                    currentVersion.shown = true;
                    props.setShow(true);
                } else if(storedVersion.lastHash === currentVersion.lastHash && storedVersion.shown) {
                    props.setShow(true);
                }
                if((storedVersion.lastHash === currentVersion.lastHash) && storedVersion.shown === false){
                    currentVersion.shown = false;
                    props.setShow(false);
                }
            })
    }, []);

    return (
        <Modal size={'lg'} show={props.show} onHide={() => closeModal()}>
            <Modal.Header closeButton>Het Kazboek is verbeterd!</Modal.Header>
            <Modal.Body>
                <ReactMarkdown escapeHtml={true} source={markdownText} />
            </Modal.Body>
            <Modal.Footer>
                <Form.Check value={(dsaCheckbox ? '1' : '0')} onChange={() => {setDsaCheckbox(!dsaCheckbox)}} id={'do-not-show-checkbox'} type="checkbox" label="Niet meer weergeven"/>
                <Button onClick={() => {closeModal()}} variant='primary'>Ok</Button>
            </Modal.Footer>
        </Modal>
    )


};
