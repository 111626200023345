import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {ADDCATEGORY, CATEGORIES} from "../Queries";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import { useParams } from 'react-router-dom';


export const CreateCategoryModalForm = (props) => {
    let { bookId } = useParams<{bookId?: any}>();
    const [ show, setShow ] = useState(false);
    const [ name, setName ] = useState('');

    const [ addCategory, { loading } ] = useMutation(ADDCATEGORY,
        {
            update(cache, {data: { createCategory }}) {
                // @ts-ignore
                const { book } = cache.readQuery({ query: CATEGORIES, variables: {bookId: bookId }});
                cache.writeQuery({
                    query: CATEGORIES,
                    variables: {bookId: bookId},
                    data: { book: {...book, categories: book.categories.concat([createCategory.category])}},
                });
            }
        });

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        addCategory({variables: {name: name, bookId: bookId}}).then(() => {
            setName('')
            setShow(false)
        });
    };

    return (
        <React.Fragment>
            <Button variant="primary" onClick={() => setShow(true)} {...props} className={(props.className || '') + ' create-category-button'} />
            <Modal show={show} onHide={() => setShow(false)} className='create-category-modal'>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Voeg een categorie toe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Naam:</Form.Label>
                            <Form.Control type='text' placeholder='Naam' onChange={(e) => setName(e.target.value)} value={name}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Toevoegen { loading ? <Spinner animation='border' className='ml-1' /> : null }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}

