import gql from "graphql-tag";
import {User} from "../../../models/user.model";

export interface CreateUserResult {
    createUser: {
        ok: boolean;
        user: User;
    }
}

export const CREATE_USER = gql`
    mutation CreateUser($email: String!, $roleId: ID!){
        createUser(email: $email, roleId: $roleId){
            ok
            user {
                id
                email
                roleId
            }
        }
    }
`;

export interface GetAllUsersResult {
    users: User[]
}

export const GET_ALL_USERS = gql`
    query {
        users {
            id
            email
            role {
                id 
                name
            }
        }
    }
`;
