import {Entry} from "../../../../../models/entry.model";
import {Transaction} from "../../../../../models/transaction.model";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {BookContext} from "../../../BookContext";
import {useParams} from "react-router";
import {useLocalStorage} from "../../../../../helpers/useLocalStorage";
import {GetSplitPaymentResult} from "../../../Queries";
import {Button, ButtonGroup, Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import {Lightning, Plus, Trash} from "react-bootstrap-icons";
import {LoadingComponent} from "../../../../../helpers/LoadingComponent";
import {Field, FieldArray} from "formik";
import {Currency} from "../../../../../helpers/Currency";
import {AdminToolTipOverlay} from "../../../../admin/components/AdminTooltipOverlay";
import './style.scss'

interface TransactionSplitFormProps {
    values: {
        entries: Partial<Entry>[]
    }
    transaction: Transaction
    setValues: (any) => any;
}



export const TransactionSplitForm: React.FC<TransactionSplitFormProps> = ({setValues, values, transaction}) => {

    const {book, autoSplitQuery: [getAutoSplit, {loading, data}]} = useContext(BookContext);


    const [diff, setDiff] = useState(0);
    const {bookId} = useParams<{bookId?: any}>();

    const [splitPerUser, setSplitPerUser] = useLocalStorage<boolean>(false, "splitPerUser");

    useEffect(() => {
        let sumValue = values.entries.reduce((accumulator, current) => {
            return accumulator + (current.amount ?? 0);
        }, 0)
        setDiff(sumValue - transaction.amount);
    }, [values, transaction]);



    const getFromData = (localData: GetSplitPaymentResult, fSplitPerUser: boolean) => {


        let settlement = localData.getSplitPayment.settlements.find(s => s.settlementReference === transaction.description.slice(5));
        if(!settlement) {
            return;
        };
        values.entries = [];

        console.log('Transaction',  transaction);

        if(fSplitPerUser) {
            settlement.transactions.forEach((item) => {
                values.entries.push({
                    amount: item.price,
                    subcategoryYear: new Date(transaction.date).getFullYear(),
                    date: new Date(transaction.date),
                    description: `${item.productName} -- orderId: ${item.orderId} -- ${item.buyer}`
                })
            })
        } else {

            let result: {productId: number, productName: string, price: number}[] = []
            settlement.transactions.forEach(( value) => {

                let index = result.findIndex((item) => item.productId === value.productId)
                if(index === -1){
                    index = result.length
                    result.push({
                        productId: value.productId,
                        price: 0,
                        productName: value.productName
                    })
                }
                result[index].price += +value.price;
            })
            result.forEach(item => {
                values.entries.push({
                    amount: item.price,
                    subcategoryYear: new Date(transaction.date).getFullYear(),
                    description: `${item.productName}`,
                    date: new Date(transaction.date)
                })
            })
        }

        values.entries.push({
            date: new Date(transaction.date),
            amount: -settlement.bankCosts,
            subcategoryYear: new Date(transaction.date).getFullYear(),
            description: `Molliekosten ${transaction.description}`
        })
        if(settlement.refunds > 0){
            values.entries.push({
                date: new Date(transaction.date),
                amount: -settlement.refunds,
                subcategoryYear: new Date(transaction.date).getFullYear(),
                description: `Refunds ${transaction.description}`
            })
        }


        setValues({
            entries: values.entries
        })
    }

    const findSplitTransaction = (event: any, fSplitPerUser?: boolean) => {
        if(loading) return;
        if(data !== undefined) {
            getFromData(data, fSplitPerUser ?? splitPerUser)
            return;
        }


        getAutoSplit({
            variables: {
                bookId
            }
        }).then((data) => {
            if(data.data && data.data?.getSplitPayment.ok){
                getFromData(data.data, fSplitPerUser ?? splitPerUser);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const autoSplitAvailable = useMemo<boolean>(() => {
        return ((book?.hasAutoSplit ?? false) && transaction.description.match(/REF T[0-9]{8}.[0-9]{4}.[0-9]{2}/g) != null)
    }, [book, transaction])

    return (
        <Form>
            {
                autoSplitAvailable ?
                    <React.Fragment>
                        <Row>
                            <Col xs={6}>
                                <Button disabled={loading} size={'sm'} onClick={findSplitTransaction} className='add-split-entry mb-1'>
                                    <Lightning size={18}/>
                                    &nbsp;Splitten vanuit webshopgegevens
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Form.Check label={"Split per gebruiker"} checked={splitPerUser} onChange={(e) => { setSplitPerUser(!splitPerUser); findSplitTransaction(e, !splitPerUser); }}></Form.Check>
                            </Col>
                        </Row>



                        {
                            loading
                                ? <LoadingComponent></LoadingComponent>
                                : ""
                        }

                    </React.Fragment>
                    : ""
            }



            <FieldArray name='entries'>
                {({push, remove}) => (
                    <React.Fragment>
                        { values.entries.map((entry, entryIdx) => (
                            <FormGroup className={"transaction-grid"} key={entryIdx}>
                                <div className={"transaction-grid-year"}>
                                    <Field as={Form.Control} type='number' name={`entries.${entryIdx}.subcategoryYear`} className='split-entry-year' />
                                </div>
                                <div className={"transaction-grid-description"}>
                                    <Field as={Form.Control} type='text' name={`entries.${entryIdx}.description`} className='split-entry-description' />
                                </div>
                                <div className={"transaction-grid-amount"}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>€</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Field as={Form.Control} lang='nl' type='number' step='0.01' name={`entries.${entryIdx}.amount`} className='text-right split-entry-amount' />
                                    </InputGroup>
                                </div>
                                <div className={"transaction-grid-actions"}>
                                    <Button onClick={() => remove(entryIdx)} className='remove-split-entry'>
                                        <Trash size={18}/>
                                    </Button>
                                </div>
                            </FormGroup>
                        ))}
                        {
                            Math.abs(diff) > 0.00001 ?
                                <FormGroup className={'transaction-grid one-row'}>
                                    <div className={'text-danger transaction-grid-description'}>
                                        Verschil:
                                    </div>
                                    <div className='font-weight-bold text-danger transaction-grid-amount'>
                                        <Currency amount={diff} />
                                    </div>
                                    <div className={"transaction-grid-actions"}>
                                        <AdminToolTipOverlay text={"Voeg verschil toe als entry"} id={"add_diff_in_split_transaction"}>
                                            <Button onClick={() => push({amount: Math.round(-diff*100)/100, description: 'Verschil Fix', subcategoryYear: new Date(transaction.date).getFullYear()})} className='add-split-entry'>
                                                <Plus size={18}/>
                                            </Button>
                                        </AdminToolTipOverlay>
                                    </div>
                                </FormGroup>
                                : ''
                        }


                        <FormGroup className={"transaction-grid"}>
                            <div className={"transaction-grid-description"}>
                                Totaal:
                            </div>

                            <div className='transaction-grid-amount font-weight-bold'>
                                <Currency amount={transaction.amount} />
                            </div>
                        </FormGroup>



                        <FormGroup>
                            <ButtonGroup className='w-100'>
                                <Button onClick={() => push({amount: 0.0, description: 'Nieuw', subcategoryYear: new Date(transaction.date).getFullYear()})} className='add-split-entry'>Toevoegen</Button>
                            </ButtonGroup>
                        </FormGroup>
                    </React.Fragment>
                )}
            </FieldArray>
        </Form>
    )
}
