import React, {FunctionComponent} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {KazboekModalProps} from "../../../models/PropDefinitions";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';
import {useMutation} from "@apollo/react-hooks";
import {CREATE_USER, CreateUserResult} from "./queries";
import {useCurrentUser} from "../../../providers/CurrentUserProvider";
import {User} from "../../../models/user.model";
import {UPDATE_USER, UpdateUserResult} from "../user/queries";

interface CreateUserModalProps extends KazboekModalProps{
    refetch: () => void;
    user?: User
}

interface NewUser {
    email: string;
    roleId?: number;
}

export const CreateUserModal: FunctionComponent<CreateUserModalProps> = (props) => {

    const {user: currentUser} = useCurrentUser();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Ongeldige Email").required().min(1),
        roleId: Yup.number().required()
    });

    const closeModal = () => {
        props.setShow(false);
    };



    const initialValues: NewUser = props.user ? props.user : {
        email: '',
        roleId: 0
    };

    const [create, {error: createError, data: createdData}] = useMutation<CreateUserResult>(CREATE_USER);
    const [update, {error: updateError, data: updateData}] = useMutation<UpdateUserResult>(UPDATE_USER);

    const submitForm = (values, {setSubmitting, resetForm}) => {
        if (props.user) {
            update({
                variables: {
                    userId: props.user.id,
                    email: values.email,
                    roleId: values.roleId
                }
            }).then((data) => {
                window.setTimeout(() => {
                    props.refetch();
                    closeModal();
                }, 2000);
            }).catch(() => {
                resetForm();
                setSubmitting(false)
            })
        } else {
            create({
                variables: {
                    email: values.email,
                    roleId: values.roleId
                }
            }).then((data) => {
                window.setTimeout(() => {
                    props.refetch();
                    closeModal()

                }, 2000);
            }).catch(() => {
                resetForm();
                setSubmitting(false)
            })
        }
    };

    const titleText = props.user ? "Bewerk gebruiker" : "Voeg nieuwe gebruiker toe";

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                onSubmit={submitForm}
                validationSchema={validationSchema}>
            {(formikProps: FormikProps<NewUser>) => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <Modal.Header closeButton>{titleText}</Modal.Header>

                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Email adres</Form.Label>
                            <Form.Control value={formikProps.values.email}
                                          onChange={formikProps.handleChange}
                                          onBlur={formikProps.handleBlur}
                                          isInvalid={(formikProps.errors.email ? true : false) && formikProps.touched.email}
                                          type="email"
                                          id="email"
                                          placeholder="Enter Email" />
                              {formikProps.errors.email
                                ? <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
                                : null}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>De rol voor deze gebruiker</Form.Label>
                            <Form.Control value={formikProps.values.roleId}
                                          onChange={formikProps.handleChange}
                                          onBlur={formikProps.handleBlur}
                                          isInvalid={(formikProps.errors.roleId ? true : false) && formikProps.touched.roleId}
                                          as="select"
                                          id="roleId"
                                          placeholder="Voer rolnummer in">
                                <option value={0} disabled>{currentUser?.role?.children ? "Kies een rol" : "rollen laden..."}</option>
                                {
                                    currentUser?.role?.children?.map((role) => (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    ))
                                }

                            </Form.Control>
                        </Form.Group>
                        {createError ? <Alert variant={"danger"}>Kon gebruiker niet aanmaken</Alert> : null }
                        {createdData ? <Alert variant={"success"}>Gebruiker {createdData.createUser.user.email} succesvol aangemaakt</Alert> : null }
                        {updateError ? <Alert variant={"danger"}>Kon gebruiker niet bijwerken</Alert> : null }
                        {updateData ? <Alert variant={"success"}>Gebruiker  succesvol bijgewerkt</Alert> : null }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                        <Button variant='primary'
                                type="submit"
                                disabled={formikProps.isSubmitting || !formikProps.isValid}>Opslaan</Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
        </Modal>
    )

};
