import React, {FunctionComponent, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../../components/AdminTooltipOverlay";
import {Book} from "../../../../models/book.model";
import {ArrowUpRight, Plus} from "react-bootstrap-icons";
import {AddUserToBookModal} from "./AddUserToBookModal";
import {RemoveUserFromBookButton} from "./RemoveUserFromBookButton";
import {NavLink} from "react-router-dom";
import {User} from "../../../../models/user.model";

interface AdminBookUserInfoProps {
    book: Book
    refetch: () => void;
}

export const AdminBookUserInfo: FunctionComponent<AdminBookUserInfoProps> = ({book, ...props}) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <Row>
            <AddUserToBookModal refetch={props.refetch} book={book} show={show} setShow={setShow}/>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5 className={"d-inline-block"}>Gebruikers</h5>
                    <Button onClick={() => setShow(true)}
                            size={"sm"}
                            className={"float-right"}
                            type={"button"}
                            variant={"link"}>
                        <Plus/>&nbsp;toevoegen
                    </Button>
                    <p className={"small"}>Alle gebruikers die toegang hebben tot dit kazboek</p>
                </Col>
                <hr />
                <div className={"admin-list-header ml-1 mr-1"}>
                    <Row>
                        <Col xs={1}>
                            <AdminToolTipOverlay text={"Unique nummer van elke gebruiker"} id={"id_overlay"}>
                                <strong>ID</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={6}>
                            <AdminToolTipOverlay text={"Email addres van gebruiker"} id={"email_overlay"}>
                                <strong>Email</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={3}>
                            <AdminToolTipOverlay text={"Rol van deze gebruiker in dit kazboek"} id={"email_overlay"}>
                                <strong>Rol</strong>
                            </AdminToolTipOverlay>
                        </Col>
                    </Row>
                </div>
                <div className={"admin-list ml-1 mr-1"}>
                    {
                        book.users.map((bookUser) => (
                            <Row key={bookUser.user?.id} className={"admin-list-item"}>
                                <Col xs={1}>
                                    {bookUser.user?.id}
                                </Col>
                                <Col xs={6}>
                                    {bookUser.user?.email}&nbsp;
                                    <NavLink to={`/admin/users/${bookUser.user?.id}`} className={"kazboek-link"}>
                                        <ArrowUpRight/>
                                    </NavLink>&nbsp;
                                </Col>
                                <Col xs={3}>
                                    {bookUser.role?.name}
                                </Col>
                                <Col xs={2} className={"text-right"}>
                                    {/*Disabled because it's not yet implemented on the backend*/}
                                    {/*<EditBookUserButton bookUser={bookUser} refetch={props.refetch} />&nbsp;*/}
                                    <RemoveUserFromBookButton book={book}
                                                              user={(bookUser.user as User)}
                                                              refetch={props.refetch} />
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </div>
        </Row>
    )
};
