import React, {FunctionComponent} from "react";
import {useMutation} from "@apollo/react-hooks";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';
import {KazboekModalProps} from "../../../../models/PropDefinitions";
import {Book} from "../../../../models/book.model";
import {CREATE_SOURCE, CreateSourceResult} from "../queries";
import {isValidIBANNumber} from "./helper";

interface CreateSourceModalProps extends KazboekModalProps {
    book: Book;
    refetch: () => void;
}

interface NewSource {
    name: string;
    iban: string;
    startAmount: number;
}

export const CreateSourceModal: FunctionComponent<CreateSourceModalProps> = (props) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2).required(),
        iban: Yup.string().test("valid-iban", "IBAN niet geldig", (value) => {
            return isValidIBANNumber(value) === 1;
        }),
        startAmount: Yup.number().required()
    });

    const closeModal = () => {
        props.setShow(false);
    };

    const initialValues: NewSource = {
        name: '',
        iban: '',
        startAmount: 0
    };

    const [create, {error: createError, data: createdData},] = useMutation<CreateSourceResult>(CREATE_SOURCE);

    const submitForm = (values, {setSubmitting, resetForm}) => {
        create ( {
            variables: {
                bookId: props.book.id,
                iban: values.iban,
                name: values.name,
                startAmount: values.startAmount
            }
        }).then((data) => {
            props.refetch();
            window.setTimeout(() => {closeModal()}, 2000);
        }).catch(() => {
            resetForm();
            setSubmitting(false)
        })
    };

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<NewSource>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>Voeg een nieuwe bron toe aan&nbsp;<strong>{props.book.name}</strong></Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Naam van de bron</Form.Label>
                                <Form.Control value={formikProps.values.name}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.name ? true : false) && formikProps.touched.name}
                                              type="text"
                                              id="name"
                                              placeholder="Bronnaam" />
                                {formikProps.errors.name
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.name}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Iban van de bron</Form.Label>
                                <Form.Control value={formikProps.values.iban}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.iban ? true : false) && formikProps.touched.iban}
                                              type="text"
                                              id="iban"
                                              placeholder="IBAN" />
                                {formikProps.errors.iban
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.iban}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Startbedrag van bron</Form.Label>
                                <Form.Control value={formikProps.values.startAmount}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.startAmount ? true : false) && formikProps.touched.startAmount}
                                              type="number"
                                              id="startAmount"
                                              placeholder="Startbedrag" />
                                {formikProps.errors.startAmount
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.startAmount}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            {createError ? <Alert variant={"danger"}>Kon bron niet aanmaken</Alert> : null }
                            {createdData ? <Alert variant={"success"}>Bron {createdData?.createSource.source.name} succesvol aangemaakt</Alert> : null }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                            <Button variant='primary'
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid}>Opslaan</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
};
