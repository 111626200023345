import React from 'react'
import {Button, ButtonGroup, Col, ListGroup, Row} from "react-bootstrap";
import {FileCheck, Pencil, X, Sliders, HourglassSplit, PlusSquare} from "react-bootstrap-icons";
import {AddModalButton} from "../header/AddModal";
import {AdminToolTipOverlay} from "../../../admin/components/AdminTooltipOverlay";
import {Book} from "../../../../models/book.model";
import {EntriesTableValues} from "../types";
import {useFormikContext} from "formik";


interface EntriesTableHeaderProps {
    book?: Book;
    showOptions: boolean;
    setShowOptions: (show: boolean) => void;
}

export const EntriesTableHeader: React.FC<EntriesTableHeaderProps>
    = ({book,
           showOptions,
           setShowOptions}) => {

    const {resetForm, setFieldValue, values, dirty, isSubmitting} = useFormikContext<EntriesTableValues>();

    return (
        <ListGroup.Item className='mb-2 entries-table-header'>
            <Row>
                <Col xs={12}>
                    <h5>Kazboek - {book?.name ?? 'Naam niet geladen'}</h5>
                </Col>
            </Row>
            <Row>
                <Col lg={1} className='d-none d-lg-block'>Datum</Col>
                <Col lg={3} className='d-none d-lg-block'>Beschrijving</Col>
                <Col lg={1} className='d-none d-lg-block'>Bron</Col>
                <Col lg={4} className='d-none d-lg-block'>
                    <Row>
                        <Col sm={5}>Categorie</Col>
                        <Col sm={5}>Subcategorie</Col>
                        <Col sm={2}>Jaar</Col>
                    </Row>
                </Col>
                <Col lg={1} className='d-none d-lg-block'>Bedrag</Col>
                <Col lg={2}>
                    <ButtonGroup className='w-100' size='sm'>
                        <Button size='sm' onClick={() => setShowOptions(!showOptions)} className='align-text-top show-options'>
                            <Sliders color="white" size={18}/>
                        </Button>
                        <AdminToolTipOverlay text={"Alles bewerken"} id={"edit-all"}>
                            <Button size='sm' onClick={() => setFieldValue('entries', values.entries.map(e => ({...e, editing: true})))} className='edit'>
                                <Pencil size={18}/>
                            </Button>
                        </AdminToolTipOverlay>
                        {(values.entries.filter(e => e.editing).length > 0) ? (
                            <React.Fragment>
                                <AdminToolTipOverlay text={"Wijzigingen opslaan"} id={"save-changes"}>
                                    <Button variant={dirty ? 'warning' : 'primary'} size='sm' disabled={isSubmitting} type='submit' className='save'>
                                        {isSubmitting ? <HourglassSplit size={18} /> : <FileCheck size={18}/>}
                                    </Button>
                                </AdminToolTipOverlay>
                                <AdminToolTipOverlay text={"Annuleren"} id={"cancel-edit"}>
                                    <Button size='sm' onClick={() => resetForm()} className='cancel'>
                                        <X size={18}/>
                                    </Button>
                                </AdminToolTipOverlay>
                            </React.Fragment>
                        ) : null }
                        <AdminToolTipOverlay text={"Bonnen en camt bestanden uploaden"} id={"upload-things"}>
                            <AddModalButton className='add-button'>
                                <PlusSquare size={18} />
                            </AddModalButton>
                        </AdminToolTipOverlay>
                    </ButtonGroup>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}
