import React, {useEffect, useState} from "react";
import { useAuth} from "../Auth";
import {ImageProps} from "react-bootstrap";
import Image from "react-bootstrap/Image";

export const ProtectedImage:  React.FunctionComponent<ImageProps & React.HTMLProps<HTMLImageElement>> = (props) => {

    const [imageData, setImageData] = useState<string>('');

    const {token} = useAuth();

    useEffect( () =>  {
        fetch((props.src as string), {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            if(response.status >= 400){
                return new Blob();
            }
            return response.blob();
        })
            .then((blob) => {
                let reader = new FileReader();
                reader.onload = () => {
                    setImageData(reader.result as string);
                };
                                reader.readAsDataURL(blob);
            })
    }, [props.src, token]);


    return <Image fluid={props.fluid} src={imageData} />

};
