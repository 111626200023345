import {Icon} from "react-bootstrap-icons";
import React, {ReactNode} from "react";
import {Col, Row} from "react-bootstrap";
import './admin-style.scss'

interface AdminTitleProps {
    title: string;
    icon: Icon;
    component?: ReactNode
    description?: string
}

export const AdminTitle = (props: AdminTitleProps) => {
    const Icon = props.icon;

    return (
        <Row>
            <Col xs={12} className={'mt-4'}>
                <h4 className={'d-inline-block'}><Icon /><span className={'admin-title-text'}>{props.title}</span></h4>
                <span className={'float-right'}><span className={"align-self-center"}>{props.component}</span></span>
                {props.description? <p className={"small"}>{props.description}</p> : null}
                <hr />
            </Col>
        </Row>
    )

};
