import {useEffect, useRef} from 'react';
import {Entry} from "../../../models/entry.model";
import { EntriesTableValues } from "./types";
import { EditableEntry} from "./EntriesTable";
import {FormikProps} from "formik";


interface EntriesCleanerProps {
    entries: EditableEntry[]
    setFieldValue: (any) => any;
    resetForm: (any) => any;
    initialValues: EditableEntry[];
    values: any
    formikProps: FormikProps<EntriesTableValues>;
}

export const EntriesCleaner = ({entries, formikProps, resetForm, initialValues, values }) => {
    const lastReceiptIds = useRef<string[] | null>(null);
    const lastDatabaseIds = useRef<string[]>([]);
    const currentEntries = useRef<Entry[]>([]);
    const currentValues = useRef<EntriesTableValues>(values);

    currentValues.current = values;
    currentEntries.current = entries;

    useEffect(() => {
        const initialEntries = initialValues.entries;

        if (lastDatabaseIds.current.length === 0) {
            lastDatabaseIds.current = initialEntries.map(e => e.id);
        }

        if (!lastReceiptIds.current) {
            lastReceiptIds.current = initialEntries.filter(e => !!e.receipt).map(e => e.receipt.id);
        }

        const currentDatabaseIds = initialEntries.map(e => e.id);
        const idsToRemove = lastDatabaseIds.current.filter(id => !currentDatabaseIds.includes(id));
        const idsToAdd = currentDatabaseIds.filter(id => !lastDatabaseIds.current.includes(id));
        const entryIdsToAddReceipt = initialEntries.filter(e => {
            if(!!e.receipt && !!lastReceiptIds.current) {
                return !lastReceiptIds.current.includes(e.receipt.id);
            }
            return false
        }).reduce((es, e) => ({...es, [e.id]: e.receipt}), {});

        resetForm({values: initialValues})

        let newEntries = [...currentValues.current.entries]
        if (idsToRemove.length > 0 || idsToAdd.length > 0 || Object.keys(entryIdsToAddReceipt).length > 0) {
            newEntries = [...currentEntries.current.filter(e => !idsToRemove.includes(e.id)), ...initialEntries.filter(e => idsToAdd.includes(e.id))]

            if ( Object.keys(entryIdsToAddReceipt).length > 0 ) {
                newEntries = newEntries.map(nv => !!entryIdsToAddReceipt[nv.id] ? {...nv, receipt: entryIdsToAddReceipt[nv.id]} : nv)
            }
        }

        formikProps.setFieldValue('entries', newEntries)
        formikProps.setFieldValue('filters', currentValues.current.filters)
        formikProps.setFieldValue('options', currentValues.current.options)

        lastReceiptIds.current = initialEntries.filter(e => !!e.receipt).map(e => e.receipt.id);
        lastDatabaseIds.current = initialEntries.map(e => e.id);
    }, [ initialValues ])

    useEffect(() => {
        // When initial entries changes following an entry-split action, we need to update the form entries as well
        const initialEntries = initialValues.entries;
        if (initialEntries.length === currentEntries.current.length) {
            if (initialEntries.every((ie, idx) => ie.id === currentEntries.current[idx].id)) {
                initialEntries.forEach((ie, idx) => {
                    if (ie.amount !== currentEntries.current[idx].amount) {
                        formikProps.setFieldValue(`entries[${idx}].amount`, ie.amount)
                    }

                    if (ie.description !== currentEntries.current[idx].description) {
                        formikProps.setFieldValue(`entries[${idx}].description`, ie.description)
                    }
                })
            }
        }
    }, [ initialValues])

    return null;
}
