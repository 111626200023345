import {Role} from "./role.model";
import {User} from "./user.model";
import {Book} from "./book.model";


export interface BookUser {
    role?: Role;
    user?: User;
    book?: Book;
    roleId: number;
    bookId: number;
    userId: number;
}

export const bookUserNameSorter = (bookUserA, bookUserB) => {
    if(bookUserA?.book?.name && bookUserB?.book?.name){
        return bookUserA.book.name > bookUserB.book.name ? 1 : -1;
    }
    return 0;
}

