import {default as React, useState} from "react";
import {Button} from "react-bootstrap";
import {Pencil} from "react-bootstrap-icons";
import {ReceiptEditModal} from "./ReceiptEditModal";
import {Receipt} from "../../../models/receipt.model";

interface ReceiptEditButtonProps {
    receipt: Receipt;
    refetch?: () => {};
}


export const ReceiptEditButton = ({receipt, refetch}:ReceiptEditButtonProps) => {
    const [show, setShow] = useState(false);

    return (
        <React.Fragment>
            <Button type='button' variant='primary' onClick={() => setShow(true)}><Pencil></Pencil></Button>
            <ReceiptEditModal {...{show, setShow, receipt, refetch }} />
        </React.Fragment>
    )
};
