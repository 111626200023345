import React, {FunctionComponent, useState} from "react";
import {Book} from "../../../models/book.model";
import {Pencil} from "react-bootstrap-icons";
import {EditBookModal} from "./EditBookModal";

interface EditBookButtonProps {
    refetch: () => void;
    book: Book
}

export const EditBookButton: FunctionComponent<EditBookButtonProps> = (props) => {

    const [show, setShow] = useState<boolean>(false);

    return (
        <span>
            <EditBookModal book={props.book} refetch={props.refetch} show={show} setShow={setShow}/>
            <Pencil onClick={() => {setShow(true)}} />
        </span>
    )
};
