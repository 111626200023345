import {Receipt} from "../../../models/receipt.model";
import {Alert, Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {RECEIPT_UPDATE_VALUES} from "../Queries";


interface ReceiptEditModalProps {
    receipt: Receipt;
    show: boolean,
    setShow: any
    refetch?: () => {}
}

export const ReceiptEditModal = ({show, setShow, receipt, refetch}:ReceiptEditModalProps) => {

    const [description, setDescription] = useState(receipt.description);
    const [amount, setAmount] = useState(receipt.amount);
    const [alert, setAlert] = useState('');
    const [error, setError] = useState('');

    const [updateReceipt] = useMutation(RECEIPT_UPDATE_VALUES, {
            onError: () => setError('Kon niet bijwerken'),
            onCompleted: (() => {

                setAlert('Succesvol bijgewerkt');
                setTimeout(() => {
                    setAlert('');
                    if(refetch !== undefined)
                        refetch();
                    setShow(false);
                }, 2000);
            })
        });


    const submitForm = () => {
        updateReceipt({
            variables: {
                receiptId: receipt.id,
                description: description,
                amount: amount
            }
        })
    };

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Toevoegen</Modal.Header>
            <Modal.Body>
                <div>
                    <h2>Bewerk Bonnetje</h2>
                    <Form encType='multipart/form-data'>
                        <Form.Group>
                            <Form.Label>Bon Omschrijving</Form.Label>
                            <Form.Control
                                type='description'
                                value={description}
                                onChange={(e:ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bedrag op bon</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type='number'
                                    disabled={receipt.entry !== null}
                                    value={amount}
                                    step='0.01'
                                    onChange={(e:ChangeEvent<HTMLInputElement>) => setAmount(+(e.target.value))}
                                    min='0'
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col>
                                <Button onClick={() => submitForm()}>Bijwerken</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    { (alert) ? <Alert variant='success'>{alert}</Alert> : '' }
                    { (error) ? <Alert variant='danger'>{error}</Alert> : '' }
                </div>
            </Modal.Body>
        </Modal>
    )
};
