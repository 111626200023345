import React, {FunctionComponent} from "react";
import {AdminTitle} from "../AdminTitle";
import {Person} from "react-bootstrap-icons";
import {Alert, Container} from "react-bootstrap";
import { useParams, RouteComponentProps } from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {GET_USER_INFO, GetUserInfoResult} from "./queries";
import {LoadingComponent} from "../../../helpers/LoadingComponent";
import {AdminUserViewer} from "./AdminUserViewer";

interface AdminUserProps extends RouteComponentProps {

}

export const AdminUser: FunctionComponent<AdminUserProps> = (props) => {

    const {userId} = useParams<{userId?: any}>();
    const {loading, error, data, refetch} = useQuery<GetUserInfoResult>(GET_USER_INFO, {
        variables: {
            userId: userId
        }
    });

    return (
        <Container>
            <AdminTitle title={
                data?.user ? 'Gebruiker - ' + data?.user.email : 'Gebruiker - laden...'} icon={Person}/>

            {error ? <Alert variant={"danger"}>A Error occured while fetching the data: {JSON.stringify(error)}</Alert> : null}
            {loading ? <LoadingComponent/> : null}
            {data?.user ?  <AdminUserViewer refetch={refetch} {...props} user={data.user} /> : null}
        </Container>
    )
};
