import React, {useEffect, useMemo} from 'react'
import {useAuth} from "./Auth";
import {HttpLink} from "apollo-link-http";
import {setContext} from "apollo-link-context";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloProvider} from '@apollo/react-hooks';


const cache = new InMemoryCache();


export const KazboekApolloProvider = (props) => {
    const { token } = useAuth();

    const client = useMemo(() => {
        const apolloLink = new HttpLink({uri: '/api/graphql'})
        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${token}`
                }
            }
        })

        return new ApolloClient({
            link: authLink.concat(apolloLink),
            cache: cache,
        })
    }, [token])

    useEffect(() => {
        if (!token) {
            client.clearStore()
        }
    }, [client, token])

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    )
}