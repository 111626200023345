import { useEffect } from "react";
import {ListGroupItem} from "react-bootstrap";
import {EntryForm} from "./EntryForm";
import {EntryDisplay} from "./EntryDisplay";
import React from "react";
import  {EditableEntry} from "../../EntriesTable";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "../../types";

interface EntriesTableGroupListEntryProps {
    entry: EditableEntry;
    index: number;
    initialEntry: EditableEntry;
}

export const EntriesTableGroupListEntry: React.FC<EntriesTableGroupListEntryProps> = ({entry, index, initialEntry}) => {

    const {setFieldValue} = useFormikContext<EntriesTableValues>();

    //Not this
    useEffect(() => {
        if (!entry.editing && !!entry.categoryId && !entry.subcategoryId) {
            setFieldValue(`entries[${index}].categoryId`, '');
        }
    }, [entry]);

    return (
        <ListGroupItem className='py-1 entry'>
            {
                entry.editing ?
                    <EntryForm entry={entry} index={index} initialEntry={initialEntry} />
                    : <EntryDisplay entry={entry} index={index} />
            }
        </ListGroupItem>
    )
}

export const MemoizedEntriesTableGroupListEntry = React.memo(EntriesTableGroupListEntry, (pp, np) => {
    //return ((pp.entry === np.entry) && (pp.editing === np.editing) && (pp.selected === np.selected))
    return ((pp.entry === np.entry) && (pp.entry.receipt === np.entry.receipt) && (pp.index === np.index))

})
