import React, {FunctionComponent} from "react";
import {Role} from "../../../models/role.model";
import {Col, Row} from "react-bootstrap";
import {AdminRoleBaseInfo} from "./AdminRoleBaseInfo";
import {AdminRolePermissionsInfo} from "./AdminRolePermissionsInfo";

interface AdminRoleViewerProps {
    role: Role;
    refetch: () => void;
}

export const AdminRoleViewer: FunctionComponent<AdminRoleViewerProps> = (props) => {

    return (
        <Row>
            <Col xs={12} lg={6}>
                <AdminRoleBaseInfo role={props.role}/>
            </Col>
            <Col xs={12} lg={6}>
                <AdminRolePermissionsInfo role={props.role}/>
            </Col>
        </Row>

    )
};
