import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {UPDATECATEGORY} from "../Queries";
import {Button, Form, Modal} from "react-bootstrap";

export const EditCategoryModalForm = (props) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState(props.name);

    const [ updateCategory, { loading } ] = useMutation(UPDATECATEGORY);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateCategory({variables: {id: props.id, name: name}});
        setShow(false);
    };

    if (loading) return <Button size='sm' disabled>Working.....</Button>;

    return (
        <>
            <Button variant="primary" size='sm' onClick={() => setShow(true)}>
                Bewerken
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Categorie bewerken</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Naam:</Form.Label>
                            <Form.Control type='text' placeholder='Naam' onChange={(e) => setName(e.target.value)} value={name}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
