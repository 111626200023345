import gql from "graphql-tag";
import {Book} from "../../../models/book.model";
import {Role} from "../../../models/role.model";
import {BookUser} from "../../../models/book-user.model";


export const CREATE_BOOK = gql`
    mutation CreateBook($name: String!, $roleId: ID!){
        createBook(name: $name, roleId: $roleId){
            ok
            book {
                id
                name
            }
        }
    }
`;

export interface UpdateBookResult {
    updateBook: {
        ok: boolean;
        book: Book;
    }
}

export const UPDATE_BOOK = gql`
    mutation UpdateBook($name: String!, $bookId: ID!){
      updateBook(name: $name, bookId: $bookId){
        ok, 
        book {
          id
          name
        }
      }
    }
`;


export interface GetAllBooksResult {
    books: BookUser[];
}

export const GET_ALL_BOOKS = gql`
    query {
        books {
            book {
                id
                name
                users {
                    role {
                        id
                        name
                    }
                    user {
                        id
                        email
                    }
                }
            }
            role {
                id
                name
            }
        }
    }
`;


export interface GetRoleNamesResult {
    roles: Role[];
}

export const GET_ROLE_NAMES = gql`
    query {
        roles {
            id
            name
        }
    }
`;
