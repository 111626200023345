import React, {FunctionComponent} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_ALL_USERS, GetAllUsersResult} from "../../users/queries";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import {KazboekModalProps} from "../../../../models/PropDefinitions";
import * as Yup from 'yup';
import {Book} from "../../../../models/book.model";
import {BookUser} from "../../../../models/book-user.model";
import {CREATE_BOOK_USER, CreateBookUserResult, UPDATE_BOOK_USER, UpdateBookUserResult} from "../queries";
import {useCurrentUser} from "../../../../providers/CurrentUserProvider";

interface AddUserToBookModalProps extends KazboekModalProps {
    refetch: () => void;
    book?: Book;
    bookUser?: BookUser;
}

export const AddUserToBookModal: FunctionComponent<AddUserToBookModalProps> = (props) => {

    const {user} = useCurrentUser();
    const {loading: userLoading, data: userData, error: userError} = useQuery<GetAllUsersResult>(GET_ALL_USERS);

    const validationSchema = Yup.object().shape({
        userId: Yup.number().required(),
        roleId: Yup.number().required(),
    });

    const closeModal = () => {
        props.setShow(false);
    };

    const [create, {error: createError, data: createdData},] = useMutation<CreateBookUserResult>(CREATE_BOOK_USER);
    const [update, {error: updateError, data: updateData}] = useMutation<UpdateBookUserResult>(UPDATE_BOOK_USER);


    if(!props.book && !props.bookUser){
        return <span></span>
    }

    const initialValues: BookUser = props.bookUser ? props.bookUser : props.book ? {
        bookId: props.book?.id,
        roleId: 0,
        userId: 0
    } : {
        bookId: 0,
        roleId: 0,
        userId: 0
    };

    const submitForm = (values, {setSubmitting, resetForm}) => {
        if(props.bookUser){
            update ( {
                variables: {
                    roleId: values.roleId,
                    userId: props.bookUser.userId,
                    bookId: props.bookUser.bookId
                }
            }).then((data) => {
                props.refetch();
                resetForm();
                window.setTimeout(() => {closeModal()}, 2000);
            }).catch(() => {
                resetForm();
                setSubmitting(false)
            })
        }else {
            create ( {
                variables: {
                    roleId: values.roleId,
                    userId: values.userId,
                    bookId: values.bookId
                }
            }).then((data) => {
                props.refetch();
                resetForm();
                window.setTimeout(() => {closeModal()}, 2000);
            }).catch(() => {
                resetForm();
                setSubmitting(false)
            })
        }
    };

    const titleText = props.book
        ? <span>Voeg een gebruiker toe aan een kazboek</span>
        : <span>Bewerk gebruiker in kazboek</span>

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<BookUser>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>{titleText}</Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>De Gebruiker</Form.Label>
                                <Form.Control value={formikProps.values.userId}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.userId ? true : false) && formikProps.touched.userId}
                                              disabled={!!props.bookUser}
                                              as="select"
                                              id="userId">
                                    <option value={0} disabled>{userData ? "Kies een gebruiker" : (userLoading ? "laden.." : "FOUT")}</option>
                                    {
                                        userData?.users.map((user) => (
                                            <option key={user.id} value={user.id}>{user.email}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>De rol voor deze gebruiker</Form.Label>
                                <Form.Control value={formikProps.values.roleId}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.roleId ? true : false) && formikProps.touched.roleId}
                                              as="select"
                                              id="roleId"
                                              placeholder="Voer rolnummer in">
                                    <option value={0} disabled>{user?.role?.children ? "Kies een rol" : "Rollen laden..."}</option>
                                    <option value={user?.role?.id}>{user?.role?.name}</option>
                                    {
                                        user?.role?.children?.map((role) => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                            {userError ? <Alert variant={"danger"}>Kon gebruikers niet laden</Alert> : null }
                            {createError ? <Alert variant={"danger"}>Kon gebruiker niet aan kazboek toevoegen</Alert> : null }
                            {createdData ? <Alert variant={"success"}>Gebruiker succesvol toegevoegd</Alert> : null }
                            {updateError ? <Alert variant={"danger"}>Kon gebruiker niet bijwerken</Alert> : null }
                            {updateData ? <Alert variant={"success"}>Gebruiker succesvol bijgewerkt</Alert> : null }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                            <Button variant='primary'
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid}>Opslaan</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
};
