import gql from "graphql-tag";
import {Book} from "../../../models/book.model";
import {BookUser} from "../../../models/book-user.model";
import {Source} from "../../../models/source.model";


export interface GetBookInfoResult {
    book: Book;
}

export const GET_BOOK_INFO = gql`
    query GetBook($bookId: ID!) {
        book(bookId: $bookId){
            id
            name
            users {
                role {
                    id
                    name
                }
                roleId
                bookId
                userId
                user {
                    id
                    email
                }
            }
            sources {
                id
                name
                iban
                startAmount
                balance
            }
        }
    }
`;

export interface CreateBookUserResult {
    ok: boolean,
    bookUser: BookUser;
}

export const CREATE_BOOK_USER = gql`
    mutation CreateBookUser($userId: ID!, $roleId: ID!, $bookId: ID!){
        createBookUser(userId: $userId, roleId: $roleId, bookId: $bookId){
            ok,
            bookUser {
                roleId,
                bookId,
                userId
            }
        }
    }
`;

export interface UpdateBookUserResult {
    updateBookUser: {
        ok: boolean;
        bookUser: BookUser;
    }
}

export const UPDATE_BOOK_USER = gql`
    mutation UpdateBookUser($userId: ID!, $roleId: ID!, $bookId: ID!){
        updateBookUser(userId: $userId, roleId: $roleId, bookId: $bookId){
            ok,
            bookUser {
                roleId,
                bookId,
                userId
            }
        }
    }
`;

export interface CreateSourceResult {
    createSource: {
        ok: boolean,
        source: Source
    }
}

export const CREATE_SOURCE = gql`
    mutation createSource($bookId: ID!, $name: String!, $iban: String, $startAmount: Float!) {
        createSource(bookId: $bookId, name: $name, iban: $iban, startAmount: $startAmount) {
            ok
            source {
                id
                startAmount
                name
                iban
            }
        }
    }
`;

export interface DeleteSourceResult {
    deleteSource: {
        ok: boolean,
        source: Source
    }
}

export const DELETE_SOURCE = gql`
    mutation DeleteSource($sourceId: ID!){
        deleteSource(sourceId: $sourceId){
            ok,
            source {
                id
                name
            }
        }
    }
`;

export interface UpdateSourceResult {
    updateSource: {
        ok: boolean,
        source: Source
    }
}

export const UPDATE_SOURCE = gql`
    mutation UpdateSource($sourceId: ID!, $name: String, $iban: String, $startAmount: Float){
        updateSource(sourceId: $sourceId, name: $name, startAmount: $startAmount, iban: $iban){
            ok
            source {
                id
                name
                startAmount
                iban
            }

        }
    }
`;

export interface DeleteBookUserResult {
    ok: boolean;
}


export const DELETE_BOOK_USER = gql`
    mutation DeleteBookUser($userId: ID!, $bookId: ID!){
        deleteBookUser(userId: $userId, bookId: $bookId){
            ok
        }
    }
`;
