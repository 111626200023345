import {useBookContext} from "../BookContext";
import React, {useMemo} from "react";
import {filterEntries, sortEntries} from "./helpers";
import {EntriesTableByCategory} from "./EntriesTableByCategory";
import { EditableEntry} from "./EntriesTable";
import {useFormikContext} from "formik";
import {EntriesTableValues} from "./types";

interface EntriesTableProps{
    initialEntries: EditableEntry[];
}


export const EntriesTableEntries: React.FC<EntriesTableProps> = ({initialEntries}: EntriesTableProps) => {

    const {values, initialValues} = useFormikContext<EntriesTableValues>();

    const { categories }  = useBookContext();

    const sortedIndices = useMemo(() => {
        const [ filteredIndices] = filterEntries(initialValues.entries, values.filters)
        const [ sortedIndices, ] = sortEntries(initialValues.entries, filteredIndices, values.filters);
        return sortedIndices
    }, [initialValues, values])

    const groupedIndices = useMemo<{id: string, name: string, indices: number[]}[]>(() => {
        // groups the row indices by their category id. Two groups are reserved: if no grouping is applied, -1 is used
        // for all categorised entries. In any case, 0 is used for uncategorised entries.

        return sortedIndices.reduce<{id: string, name: string, indices: number[]}[]>((grouped, entryIdx) => {
            const entry: EditableEntry = initialValues.entries[entryIdx];

            // Set to 0 by default: not categorized. If categorized anyway, change the groupId
            let groupId = '0'
            // If a subcategory and year is known.
            if (!!entry.subcategoryId && !!entry.subcategoryYear) {
                groupId = values.options.groupByCategoryId ? entry.categoryId || '-1' : '-1';
            }

            const groupIdx = grouped.findIndex(group => groupId === group.id);

            if (groupIdx < 0) {
                const cat = categories.find(c => c.id === entry.categoryId);
                return [...grouped, {
                    id: groupId,
                    name: !!cat ? cat.name : 'Something',
                    indices: [entryIdx]}]
            } else {
                const newGrouped = grouped.slice();
                newGrouped[groupIdx].indices = [...newGrouped[groupIdx].indices, entryIdx];
                return newGrouped
            }
        }, [{id: '0', name: 'Ongecategoriseerd', indices: []}, {id: '-1', name: 'Gecategoriseerd', indices: []}])
    }, [sortedIndices, values.options.groupByCategoryId, categories]);



    // const sortedGroupedIndices = useMemo(() => {
        groupedIndices.sort((groupIndexA, groupIndexB) => {
            if(groupIndexA.name === "Ongecategoriseerd") {
                return -1;
            }
            if(groupIndexB.name === "Ongecategoriseerd") {
                return 1;
            }
            return groupIndexA.name > groupIndexB.name ? 1 : -1;
        });
        // return groupedIndices
    // }, [groupedIndices])


    return (
        <React.Fragment>
            <EntriesTableByCategory
                groupedIndices={groupedIndices }
                options={values.options}
            />
        </React.Fragment>

    )
};
