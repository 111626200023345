import {Permission} from "../../models/permission.model";


interface isAllowedProps {
    mode: 'any' | 'all';
    permission: string[];
    userPermissions: Permission[];
}


export const userHasPermission = ({mode, permission, userPermissions}: isAllowedProps) => {

    let matchCount = 0;
    userPermissions.forEach((userPermission) => {
        (permission as string[]).forEach((givenPermission) => {
            if(givenPermission === userPermission.internalName) {
                matchCount ++;
            }
        })
    });

    if(mode === 'all'){
        return matchCount === (permission as string[]).length;
    } else {
        return matchCount > 0;
    }
};
