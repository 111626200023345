import {useYear} from "../../../providers/year-provider";
import {useParams} from "react-router-dom";
import {useEffect, useRef} from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import {ENTRIESQUERY} from "../Queries";
import {processEntries} from "./EntriesTable";

interface YearChangeHandlerProps {
    resetForm: (any) => any;
    entries: any[];
    values: any
}

export const YearChangeHandler: React.FunctionComponent<YearChangeHandlerProps> = ({resetForm, values, entries, ...props}) => {
    const { year } = useYear();
    const { bookId } = useParams<{bookId?: any}>();
    const lastYearRef = useRef(year)

    const [ refetchEntries ] = useLazyQuery(ENTRIESQUERY, {
        variables: {year, bookId},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            resetForm({
                values: {
                    ...values,
                    entries: processEntries(data.book.entries),
                }
            })
        }
    });

    useEffect(() => {
        //const entryFilter = e => !e.subcategoryYear || !e.subcategoryId || e.subcategoryYear === year;

        if (year !== lastYearRef.current) {
            // Two things to do: filter out entries for other years, and reset form with data from this year
            refetchEntries();
            lastYearRef.current = year;
        }
    }, [entries, values, year, refetchEntries]);

    return null
};
