import React, {FunctionComponent, useState} from "react";
import {isValidIBANNumber} from "./helper";
import {useMutation} from "@apollo/react-hooks";
import {UPDATE_SOURCE, UpdateSourceResult} from "../queries";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import {Source} from "../../../../models/source.model";
import * as Yup from 'yup';
import {KazboekModalProps} from "../../../../models/PropDefinitions";

interface EditSourceModalProps extends KazboekModalProps{
    source: Source;
    refetch: () => void;
}

interface UpdatedSource {
    name: string
    iban: string
    startAmount: number;
}

export const EditSourceModal: FunctionComponent<EditSourceModalProps> = (props) => {

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(2).required(),
        iban: Yup.string().test("valid-iban", "IBAN niet geldig", (value) => {
            return isValidIBANNumber(value) === 1;
        }),
        startAmount: Yup.number().required()
    });

    const closeModal = () => {
        props.setShow(false);
    };

    const initialValues: UpdatedSource = {
        name: props.source.name,
        iban: props.source.iban,
        startAmount: props.source.startAmount
    };

    const [update, {data: updateData}] = useMutation<UpdateSourceResult>(UPDATE_SOURCE);

    const submitForm = (values, {setSubmitting, resetForm}) => {
        update ( {
            variables: {
                sourceId: props.source.id,
                iban: values.iban,
                name: values.name,
                startAmount: values.startAmount
            }
        }).then((data) => {
            resetForm();
            setShowSuccess(true);
            props.refetch();
            window.setTimeout(() => {
                closeModal();
                setShowSuccess(false);
            }, 2000);
        }).catch(() => {
            resetForm();
            setShowError(true);
            setSubmitting(false)
        })
    };

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<UpdatedSource>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>Bewerk bron&nbsp;<strong>{props.source.name}</strong></Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Naam van de bron</Form.Label>
                                <Form.Control value={formikProps.values.name}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.name ? true : false) && formikProps.touched.name}
                                              type="text"
                                              id="name"
                                              placeholder="Bronnaam" />
                                {formikProps.errors.name
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.name}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Iban van de bron</Form.Label>
                                <Form.Control value={formikProps.values.iban}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.iban ? true : false) && formikProps.touched.iban}
                                              type="text"
                                              id="iban"
                                              placeholder="IBAN" />
                                {formikProps.errors.iban
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.iban}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Startbedrag van bron</Form.Label>
                                <Form.Control value={formikProps.values.startAmount}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.startAmount ? true : false) && formikProps.touched.startAmount}
                                              type="number"
                                              id="startAmount"
                                              placeholder="Startbedrag" />
                                {formikProps.errors.startAmount
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.startAmount}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            {showError ? <Alert variant={"danger"}>Kon bron niet bijwerken</Alert> : null }
                            {showSuccess ?
                                <Alert variant={"success"}>
                                    Bron&nbsp;<strong>{updateData?.updateSource.source.name}</strong>&nbsp;succesvol bijgewerkt
                                </Alert> : null }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                            <Button variant='primary'
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid}>Bijwerken</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
