import React, {FunctionComponent} from "react";
import {Book} from "../../../models/book.model";
import {Col, Row} from "react-bootstrap";
import {AdminBookUserInfo} from "./users/AdminBookUserInfo";
import {AdminBookSourceInfo} from "./sources/AdminBookSourceInfo";
import {AdminBookBaseInfo} from "./AdminBookBaseInfo";

interface AdminBookViewerProps {
    book: Book;
    refetch: () => void;
}

export const AdminBookViewer: FunctionComponent<AdminBookViewerProps> = ({book, ...props}) => {

    return (
        <Row>
            <Col xs={12} lg={6}>
                <AdminBookBaseInfo book={book} refetch={props.refetch}/>
                <AdminBookSourceInfo book={book} refetch={props.refetch}/>
            </Col>
            <Col xs={12} lg={6}>
                <AdminBookUserInfo refetch={props.refetch} book={book}/>
            </Col>
        </Row>
    )
};
