import React, {FunctionComponent} from "react";
// import {Container} from "react-bootstrap";
// import {AdminTitle} from "../AdminTitle";
// import {Shield} from "react-bootstrap-icons";
// import {GuardedComponent} from "../../guards/GuardedComponent";
import { Redirect } from "react-router-dom";

interface AdminMainProps {
    token: string;
}

export const AdminMain: FunctionComponent<AdminMainProps> = (props) => {

    return (
        //TODO make this page usefull.
        <Redirect to={'admin/books'} />
        // <Container>
        //
        //     <AdminTitle title={'Hoofdpagina'} icon={Shield}/>
        //     <GuardedComponent permission="updateRole">
        //         <h1>Should be viewable</h1>
        //     </GuardedComponent>
        //     {/*<GuardedComponent component={<h1>Should not be viewable</h1>} permission="canNotView"/>*/}
        //
        // </Container>
    )
};
