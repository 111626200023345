import {EditableEntry} from "./EntriesTable";
import {EntriesFilter} from "./types";


export function filterEntries(entries: EditableEntry[], filters: EntriesFilter): [number[], EditableEntry[]] {
    //Get Id's
    const indices = [...Array.from(Array(entries.length).keys())];


    const filteredIndices = indices.filter(idx => {
        const entry = entries[idx];
        const entryDate = new Date(entry.date);
        return (
            ((!filters.likeDescription) || (entry.description.toLowerCase().indexOf(filters.likeDescription.toLowerCase()) > -1))
            &&
            ((filters.subcategoryId === '') || (entry.subcategoryId === filters.subcategoryId))
            &&
            ((filters.categoryId === '') || (entry.categoryId === filters.categoryId))
            &&
            ((filters.sourceId === '') || (entry.sourceId === filters.sourceId))
            &&
            ((!filters.beforeDate) || (entryDate.getTime() <= filters.beforeDate.getTime()))
            &&
            ((!filters.afterDate) || (entryDate.getTime() >= filters.afterDate.getTime()))
            &&
            ((!filters.amountFrom) || (Math.abs(entry.amount) >= filters.amountFrom))
            &&
            ((!filters.amountTo) || (Math.abs(entry.amount) <= filters.amountTo))
            &&
            ((!filters.amountSign) || (Math.sign(entry.amount) === filters.amountSign)))
            &&
            ((filters.receipt === '') || (entry.receipt && filters.receipt === "WITH") || !entry.receipt && filters.receipt === 'WITHOUT')


    });

    return [ filteredIndices, filteredIndices.map(idx => entries[idx]) ]
}

export function sortEntries(entries: EditableEntry[],
                            filteredIndices: number[],
                            filters: EntriesFilter): [number[], EditableEntry[]] {
    const sortedIndices = filteredIndices.slice();

    if(filters.orderBy === 'DATE'){
        sortedIndices.sort((a: number, b: number) => {
            if (entries[a].date > entries[b].date)
                return filters.orderType === 'ASC' ? 1 : -1;
            if (entries[a].date < entries[b].date)
                return filters.orderType === 'ASC' ? -1 : 1;
            else
                return 0;
        })

    } else if (filters.orderBy === 'LAST_EDITED'){
        sortedIndices.sort((a: number, b:number) => {
            if (entries[a].lastEdited > entries[b].lastEdited)
                return filters.orderType === 'ASC' ? 1 : -1;
            if (entries[a].lastEdited < entries[b].lastEdited)
                return filters.orderType === 'ASC' ? -1 : 1;
            else
                return 0;
        })
    }

    return [ sortedIndices, sortedIndices.map(idx => entries[idx]) ]
}
