import gql from "graphql-tag";
import {SubCategory} from "../../models/subcategory.model";
import {Book} from "../../models/book.model";
import {Transaction} from "../../models/transaction.model";
import {Category} from "../../models/category.model";


export const RECEIPT_UPDATE = gql`
    mutation UpdateReceipt($receiptId: ID!, $entryId: ID!){
        updateReceipt(receiptId: $receiptId, entryId: $entryId){
            ok
            receipt {
                id
            }
        }
    }
`

export const RECEIPT_UPDATE_VALUES = gql`
    mutation UpdateReceipt($receiptId: ID!, $amount: Float, $description: String){
        updateReceipt(receiptId: $receiptId, amount: $amount, description: $description){
            ok
            receipt {
                id
            }
        }
    }
`

export const RECEIPTS_FOR_BOOK = gql`
    query getBookReceipts($bookId: ID!, $amount: Float!){
        book(bookId: $bookId){
            id
            receipts(amount: $amount) { 
                id
                fileName
                description
                amount
                entryId
            }
        }
    } 
`;

const FRAGMENT_ENTRY_CONTENT = gql`
    fragment entryContent on EntryObject {
        date
        description
        userDescription
        camtDescription
        contraAccount
        amount
        lastEdited
        sourceId
        transaction {
            id
        }
        source {
            id
            name
        }
        subcategoryId
        subcategoryYear
        categoryId
        receipt {
            id
            fileName
            description
            amount
            bookId
            entryId
        }
        isCamtEntry
        descriptionEdited
        subcategory {
            id
            name
            category {
                id
                name
            }
        }
    }
`


export interface MutationUpdateTransactionResult {
    updateTransaction: {
        ok: boolean
        transaction: Transaction;
    }
}

export const MUTATION_UPDATE_TRANSACTION = gql`
    mutation updateTransaction($transactionId: ID!, $entries: [TransactionEntryInputType]) {
        updateTransaction(transactionId: $transactionId, entries: $entries) {
            ok
            transaction {
                id
                entries {
                    id
                    ...entryContent
                }
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`


export const MUTATION_UPDATE_ENTRIES = gql`
    mutation updateEntries($entries: [EntryInputType]) {
        updateEntries(entries: $entries) {
            ok
            entries {
                id
                ...entryContent
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`

export interface EntriesQueryResult {
    book: Book
}

export interface GetSplitPaymentResult {
    getSplitPayment: {
        ok: boolean,
        settlements: {
            settlementId: string,
            settlementReference: string,
            bankCosts: number,
            revenue: number,
            refunds: number,
            transactions: {
                orderId: number,
                productId: number,
                productName: string,
                price: number,
                buyer: string,
                email: string
            }[]
        }[]
    }
}

export const GET_SPLIT_PAYMENTS = gql`
    mutation getSplitPayment($bookId: ID!) {
        getSplitPayment(bookId: $bookId) {
        ok
        settlements {
            settlementId
            settlementReference
            bankCosts
            revenue
            refunds
            transactions {
                orderId
                productId
                productName
                price
                buyer
                email
            }
        }
    }
    }
`

export const ENTRIESQUERY = gql`
    query getBookEntries($bookId: ID!, $year: Int) {
        book(bookId:$bookId) {
            id
            entries(year: $year) {
                id
                ...entryContent
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`;


export const ENTRY_UPDATE_MUTATION = gql`
    mutation updateEntry(
        $entryId: ID!, 
        $date: DateTime, 
        $description: String, 
        $amount: Float, 
        $subcategoryId: ID,
        $subcategoryYear: Int,
    ) {
        updateEntry(
            entryId: $entryId, 
            date: $date, 
            description: $description, 
            amount: $amount, 
            subcategoryId: $subcategoryId,
            subcategoryYear: $subcategoryYear,
        ) {
            ok
            entry {
                id
                ...entryContent
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`


export const ADDENTRY = gql`
    mutation createEntry($date: DateTime!, $description: String!, $amount: Float!, $sourceId: ID!, $subcategoryId: ID!) {
        createEntry(date: $date, description: $description, amount: $amount, sourceId: $sourceId, subcategoryId: $subcategoryId) {
            ok
            entry {
                id
                ...entryContent
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`;


export const ADDTRANSACTION = gql`
    mutation createTransaction($date: DateTime!, $description: String!, $amount: Float!, $sourceId: ID!, $subcategoryId: ID!, $subcategoryYear: Int) {
        createTransaction(date: $date, description: $description, amount: $amount, sourceId: $sourceId, entries: [{
            description: $description,
            date: $date,
            amount: $amount,
            subcategoryId: $subcategoryId,
            subcategoryYear: $subcategoryYear,
        }]) {
            ok
            transaction {
                id
                entries {
                    id
                    ...entryContent
                }
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`


export const DELETEENTRY = gql`
    mutation deleteEntry($id: ID!) {
        deleteEntry(entryId: $id) {
            ok
            entry {
                id
            }
        }
    }
`;


export interface AddSubcategoryResult {
    createSubcategory: {
        ok: boolean;
        subcategory: SubCategory
    };
}

export const ADDSUBCATEGORY = gql`
    mutation createSubcategory($name: String!, $categoryId: ID!) {
        createSubcategory(name: $name, categoryId: $categoryId) {
            ok
            subcategory {
                id
                name
                balance
                category {
                    id
                    name
                }
            }
        }
    }
`;


export const DELETESUBCATEGORY = gql`
    mutation deleteSubcategory($id: ID!) {
        deleteSubcategory(subcategoryId: $id) {
            ok
            subcategory {
                entries {
                    id
                    ...entryContent
                }                
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`;


export const UPDATESUBCATEGORY = gql`
    mutation updateSubcategory($id: ID!, $name: String!) {
        updateSubcategory(subcategoryId: $id, name: $name) {
            ok
            subcategory {
                id
                name
                category {
                    id
                    name
                }
            }
        }
    }
`;


export const ENTRYDATA = gql`
    query getEntryFormOptions($bookId: ID!) {
        book(bookId: $bookId) {
            id
            categories {
                id
                name
                subcategories {
                    id
                    name
                }
            }
            sources {
                id
                name
                isCamtSource
            }
        }
    }
`;

export interface CategoriesResult {
    book: Book
}

export const CATEGORIES = gql`
    query getCategories($bookId: ID!) {
        book(bookId: $bookId) {
            id
            name
            hasAutoSplit
            categories {
                id
                name
                balance
                hidden
                subcategories {
                    id
                    name
                    balance
                }
            }
        }
    }
`;


export const ADDCATEGORY = gql`
    mutation createCategory($name: String! $bookId: ID!) {
        createCategory(name: $name, bookId: $bookId) {
            ok
            category {
                id
                name
                balance
                subcategories {
                    id
                    name
                }
            }
        }
    }
`;


export const DELETECATEGORY = gql`
    mutation deleteCategory($id: ID!) {
        deleteCategory(categoryId: $id) {
            ok
        }
    }
`;

export interface ChangeCategoryHiddenResult {
    updateCategory: {
        ok: boolean
        category: Category
    }
}

export const CHANGE_CATEGORY_HIDDEN = gql`
    mutation updateCategory($id: ID!, $hidden: Boolean!) {
        updateCategory(categoryId: $id, hidden: $hidden) {
            ok
            category {
                id
                hidden
            }
        }
    }
`

export const UPDATECATEGORY = gql`
    mutation updateCategory($id: ID!, $name: String!) {
        updateCategory(categoryId: $id, name: $name) {
            ok
            category {
                id
                name
                subcategories {
                    id
                    name
                }
            }
        }
    }
`;

export const DASHBOARD_INFO = gql`
    query getDashboardInfo($bookId: ID!, $year: Int) {
        book(bookId: $bookId) {
            id
            sources {
                id
                name
                balance
                iban
                startAmount
            }
            categories(year: $year) {
                id
                name
                balance
                subcategories(year: $year) {
                    id
                    name
                    balance
                }
            }
        }
    }  
`;

export interface TransactionResult {
    book: Book;
}


export const TRANSACTION = gql`
    query getTransaction($bookId: ID!, $transactionId: ID!) {
        book(bookId: $bookId) {
            id
            transaction(transactionId: $transactionId) {
                id
                description
                amount
                date
                entries {
                    id
                    ...entryContent
                }
                source {
                    id
                    name
                }
            }
        }
    }
    ${FRAGMENT_ENTRY_CONTENT}
`


export const SOURCES = gql`
    query getSources($bookId: ID!) {
        book(bookId: $bookId) {
            id
            sources {
                id
                name
                balance
                iban
            }
        }
    }  
`;


export const CREATESOURCE = gql`
    mutation createSource($bookId: ID!, $name: String!, $iban: String) {
        createSource(bookId: $bookId, name: $name, iban: $iban) {
            ok
            source {
                id
                name
                iban
            }
        }
    }
`;


export const UPDATESOURCE = gql`
    mutation updateSource($id: ID!, $name: String!, $iban: String) {
        updateSource(sourceId: $id, name: $name, iban: $iban) {
            ok
            source {
                id
                name
                iban
            }
        }
    } 
`;
