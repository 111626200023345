import React, {useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import './style.scss'
import {AdminSidebarMenuItem} from "./AdminSidebarMenuItem";
import {CardList, List, Lock, People} from "react-bootstrap-icons";
import {Collapse} from "react-bootstrap";
import {GuardedComponent} from "../../guards/GuardedComponent";


interface AdminSidebarProps extends RouteComponentProps {

}

export const AdminSidebar = (props: AdminSidebarProps) => {
    const [sidebarCollapsed, setSidebarCollaspsed] = useState(true);

    return (
        <div className={"align-items-center"}>
            <h4 className='text-center mt-3'>
                Admin
                <span  className={"float-right d-md-none"} onClick={() => setSidebarCollaspsed(!sidebarCollapsed)}>
                    <List />
                </span>

            </h4>
            <Collapse in={sidebarCollapsed}>
                <ul className="admin-sidebar-menu list-unstyled align-content-center">
                    {/*<AdminSidebarMenuItem*/}
                    {/*    name={'Hoofdpagina'}*/}
                    {/*    icon={Shield}*/}
                    {/*    path={''}*/}
                    {/*    {...props} />*/}
                    <div className={"items-wrapper"}>
                        <AdminSidebarMenuItem
                            name={'Kasboeken'}
                            icon={CardList}
                            path={'/books'}
                            {...props} />
                        <GuardedComponent permission={"viewAllUsers"}>
                            <AdminSidebarMenuItem
                                name={'Gebruikers'}
                                icon={People}
                                path={'/users'}
                                {...props} />
                        </GuardedComponent>
                        <GuardedComponent permission={"viewAllRoles"}>
                            <AdminSidebarMenuItem
                                name={'Rollen'}
                                icon={Lock}
                                path={'/roles'}
                                {...props} />
                        </GuardedComponent>
                    </div>

                </ul>
            </Collapse>
        </div>
    )

};
