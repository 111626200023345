import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_RECEIPTS} from "./ReceiptQueries";
import {Receipt} from "../../../models/receipt.model";
import React, { useState } from "react";
import {Container, Form, InputGroup, Accordion, Button, Card, Col, Row} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import {ReceiptInfoCard} from "./ReceiptInfoCard";
import {LoadingComponent} from "../../../helpers/LoadingComponent";

interface ReceiptsProps {
    bookId: number;
}

interface ReceiptRequest {
    loading: any,
    data: undefined | {
        book: {
            id: number,
            receipts: Receipt[];
        }
    };
    refetch: () => {};
}

export const Receipts = ({bookId}: ReceiptsProps) => {
    const [ filter, setFilter ] = useState('');

    const {data, loading, refetch}:ReceiptRequest = useQuery(GET_ALL_RECEIPTS, {
        variables: {
            bookId: bookId
        }
    });

    if(loading){
        return <LoadingComponent item={"Bonnen"}></LoadingComponent>
    } else {

        // @ts-ignore
        let receipts = data.book.receipts;

        const filteredReceipts = receipts.filter((r) => (r.description.toLowerCase() === filter.toLowerCase()) || (filter === ''))
        const unassignedReceipts = filteredReceipts.filter(r => r.entry === null);
        const assignedReceipts = filteredReceipts.filter(r => r.entry !== null);

        return (
            <Container fluid>
                <Row className='py-2'>
                    <Col xs={12}>
                    <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                        Username
                    </Form.Label>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text><Search></Search></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control id="inlineFormInputGroup" placeholder="Zoeken" value={filter} onChange={(e) => setFilter(e.target.value)} />
                    </InputGroup>


                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Niet toegewezen bonnetjes.
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row>
                                        { unassignedReceipts.map(receipt => (
                                            <Col lg={4} key={receipt.id}>
                                                <ReceiptInfoCard refetch={refetch}  receipt={receipt}/>
                                            </Col>
                                        ))}
                                    </Row>
                                    <p hidden={unassignedReceipts.length > 0}>Er zijn geen bonnen die niet zijn toegewezen aan een bankmutatie</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    Toegewezen bonnetjes
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <Row>
                                        { assignedReceipts.map(receipt => (
                                            <Col lg={4} key={receipt.id}>
                                                <ReceiptInfoCard refetch={refetch}  receipt={receipt}/>
                                            </Col>
                                        ))}
                                    </Row>
                                    <p hidden={assignedReceipts.length > 0}>Er zijn geen bonnen die niet zijn toegewezen aan een bankmutatie</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    </Col>
                </Row>
            </Container>
        )
    }

};
