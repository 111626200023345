import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {CATEGORIES, DELETECATEGORY} from "../Queries";
import {Button, Form, Modal} from "react-bootstrap";

export function DeleteCategoryModalForm(props) {
    const [ show, setShow ] = useState(false);

    const [ deleteCategory ] = useMutation(DELETECATEGORY,
        {
            update(cache, {data: { deleteCategory }}) {
                // @ts-ignore
                const { book } = cache.readQuery({query: CATEGORIES, variables: {bookId: props.bookId}});
                if (deleteCategory.ok) {
                    cache.writeQuery( {
                        query: CATEGORIES,
                        variables: { bookId: props.bookId },
                        data: { book: {...book, categories: book.categories.filter(c => c.id !== props.id)} }
                    })
                }
            }
        });

    function handleSubmit(e) {
        e.preventDefault();
        deleteCategory({variables: {id: props.id}});
        setShow(false);
    }

    return (
        <React.Fragment>
            <Button size='sm' variant="primary" onClick={() => setShow(true)} className='mr-2'>
                Verwijderen
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Categorie verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Categorie {props.name} wordt verwijderd. Zeker weten?</h3>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Ja, verwijderen.
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    )
}
