import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {DASHBOARD_INFO} from "../Queries";
import {CategoryCards} from "./CategoryCards";
import {SourcesInfo} from "./SourcesInfo";
import {Container} from "react-bootstrap";
import {useYear} from "../../../providers/year-provider";


export const Dashboard = () => {
    const { year } = useYear();
    let { bookId } = useParams<{bookId?: any}>();

    const { data, loading, error, refetch } = useQuery(DASHBOARD_INFO, {
        variables: {bookId: bookId, year: year},
    });

    useEffect(() => {
        refetch();
    }, [year, refetch]);

    if (loading) return <h2>Loading......</h2>;
    if (error) return <p>JSON.stringify(errors)</p>;

    // @ts-ignore
    let categories = data.book.categories;
    // @ts-ignore
    let sources = data.book.sources;
    return (
        <Container>
            <h1>Dashboard</h1>
            <SourcesInfo sources={sources}></SourcesInfo>
            <CategoryCards categories={categories}></CategoryCards>
        </Container>
    )
};
