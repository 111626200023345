import {FunctionComponent} from "react";
import * as React from 'react';
import {Button} from "react-bootstrap";
import {ReceiptModal} from "./ReceiptModal";
import {Entry} from "../../../../../../models/entry.model";
import {KazboekModalProps} from "../../../../../../models/PropDefinitions";

export interface ReceiptButtonProps extends KazboekModalProps {
    entry: Entry;
    file?: File;
    props?: any;
}

export const ReceiptButton:FunctionComponent<ReceiptButtonProps> = ({file, entry, show, setShow,...props}) => {

    return (
        <React.Fragment>
            <Button type='button' variant='primary' onClick={() => setShow(true)} {...props}/>
            <ReceiptModal {...{file, show, setShow, entry, ...props}} />
        </React.Fragment>
    )
};


