import React from 'react'
import {Button} from "react-bootstrap";
import {Square, CheckSquare} from "react-bootstrap-icons";
import { EditableEntry} from "../../EntriesTable";
import {FormikProps, useFormikContext} from "formik";
import {EntriesTableValues} from "../../types";

interface SelectButtonProps {
    entry: EditableEntry;
    index: number
}

export const SelectButton: React.FunctionComponent<SelectButtonProps> = ({entry, index, ...props}) => {

    const {setFieldValue} = useFormikContext<EntriesTableValues>();

    return (
        <Button onClick={(event) => {
            setFieldValue(`entries[${index}].selected`, !entry.selected)
            if (!entry.selected) {
                setFieldValue(`entries[${index}].editing`, true)
            }
        }} className={entry.selected ? 'entry-select selected' : 'entry-select'}  >
            {(entry.selected) ? <CheckSquare size={18} /> : <Square size={18} />}
        </Button>
    )
}
