import {Button} from "react-bootstrap";
import {Link} from "react-bootstrap-icons";
import {useState} from "react";
import {Receipt} from "../../../models/receipt.model";
import {useMutation} from "@apollo/react-hooks";
import {RECEIPT_UPDATE} from "../Queries";
import {ConfirmModal} from "../../../helpers/ConfirmModal";
import React from 'react'

interface ReceiptUnlinkButtonProps {
    receipt: Receipt;
    refetch?: () => {} | undefined;
}

export const ReceiptUnlinkButton = ({receipt, refetch}:ReceiptUnlinkButtonProps) => {

    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [unlinkReceipt] = useMutation(RECEIPT_UPDATE, {
        variables: {
            receiptId: receipt.id,
            entryId: 0
        },
        onCompleted: (() => {
            setSuccess('Succesvol bon van bankrdegel ontkoppeld')

            setTimeout(() => {
                setShow(false);
                if (refetch !== undefined){
                    refetch();
                }
            }, 1000)
        }),
        onError: (() => {setError('Kon link niet verwijderen')})
    });


    return (
        <React.Fragment>
            <Button variant='primary' onClick={() => {setShow(true)}}><Link></Link></Button>
            <ConfirmModal title={'Bon van bankregel ontdkoppelen'}
                          show={show}
                          text={'Weet je zeker dat je deze bon van de bankregel wilt onkoppelen'}
                          setShow={setShow}
                          onConfirm={unlinkReceipt}
                          successMessage={success}
                          errorMessage={error}/>
        </React.Fragment>
    )

};
