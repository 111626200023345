import {useMutation} from "@apollo/react-hooks";
import {DELETE_RECEIPT} from "./ReceiptQueries";
import {Button} from "react-bootstrap"
import React, {useState} from "react";
import {Trash} from "react-bootstrap-icons";
import {ConfirmModal} from "../../../helpers/ConfirmModal";


interface DeleteReceiptButtonProps {
    id: number;
    refetch?: () => {} | undefined
}


export const DeleteReceiptButton = ({id, refetch}:DeleteReceiptButtonProps) => {

    const [show, setShow] = useState(false);
    const [alert, setAlert] = useState('');
    const [error, setError] = useState('');

    const [deleteReceipt] = useMutation(DELETE_RECEIPT, {
        onCompleted: (() => {
            setAlert('Succesvol verwijderd')

            setTimeout(() => {
                setShow(false);
                if(refetch !== undefined)
                    refetch();
            }, 1000)
        }),
        onError: (() => {setError('Kon niet verwijderen')})
    });

    const onClickDelete = () => {
        deleteReceipt({
            variables: {
                receiptId: id
            }
        })

    }

    return (
        <React.Fragment>
            <Button onClick={() => setShow(true)} variant="primary"><Trash></Trash></Button>
            <ConfirmModal title={'Bon verwijderen'}
                          show={show}
                          setShow={setShow}
                          text={'Weet je zeker dat je deze bon wilt verwijderen'}
                          onConfirm={onClickDelete}
                          onCancel={() => {}}
                          successMessage={alert}
                          errorMessage={error}/>
        </React.Fragment>

    )
};
