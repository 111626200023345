import gql from "graphql-tag";
import {User} from "../models/user.model";


export interface GetCurrentUserResult {
    me: User;
}


export const GET_CURRENT_USER = gql`
    query {
        me {
            id
            email
            role {
                id
                name
                children {
                    id
                    name
                }
                permissions {
                    id
                    internalName
                }
            }
        }
    }
`;
