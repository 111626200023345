import gql from "graphql-tag";
import {User} from "../../../models/user.model";


export interface GetUserInfoResult {
    user: User
}

export const GET_USER_INFO = gql`
    query GetUserInfo($userId: ID!){
        user (userId:$userId) {
            id
            email
            books {
                role {
                    id
                    name
                }
                book {
                    id
                    name
                }
            }
            role {
                id
                name
                description
                permissions {
                    id
                    internalName
                }
            }
        }
    }
`;

export interface UpdateUserResult {
    updateUser: {
        ok: boolean
        user: User
    }
}

export const UPDATE_USER = gql`
    mutation UpdateUser($userId: ID!, $roleId: ID, $email: String){
        updateUser(userId: $userId, roleId: $roleId, email: $email){
            ok
            user {
                id
                email
                roleId
            }
        }
    }
`;
