import {Overlay, Popover, Button, Table} from "react-bootstrap";
import React, { useRef, useState } from "react";
import { Shield, ShieldExclamation, X} from "react-bootstrap-icons";
import {AdminToolTipOverlay} from "../../../../../admin/components/AdminTooltipOverlay";
import { EditableEntry} from "../../../EntriesTable";

interface CamtButtonProps {
    entry: EditableEntry;
}

export const CamtButton: React.FunctionComponent<CamtButtonProps> = ({entry, ...props}) => {
    const [ showCamtInfo, setShowCamtInfo ] = useState(false)
    const camtButtonRef = useRef(null)

    return (
        <React.Fragment>
            <AdminToolTipOverlay text={"Camt data"} id={"camt-data"}>
                <Button id={`entry-${entry.id}-camt-info-button`} ref={camtButtonRef} type='button' onClick={() => setShowCamtInfo(!showCamtInfo)} {...props}>
                    { (entry.camtDescription !== entry.description) ? <ShieldExclamation size={16}/> : <Shield size={16} /> }
                </Button>
            </AdminToolTipOverlay>

            <Overlay
                target={camtButtonRef.current}
                placement='bottom'
                show={showCamtInfo}
            >
                <Popover id={`entry-${entry.id}-camt-info`} className='entry-popover'>
                    <Popover.Title>
                        <div className='d-flex'>
                            <div className='mr-auto'>CAMT Info</div>
                            <div style={{cursor: 'pointer'}} onClick={() => setShowCamtInfo(false)}>
                                <X size={18} color='black'/>
                            </div>
                        </div>
                    </Popover.Title>
                    <Popover.Content>
                        <Table borderless size='sm'>
                            <tbody>
                                <tr><td>Beschrijving:</td><td>{entry.camtDescription}</td></tr>
                                <tr><td>Tegenrekening:</td><td>{entry.contraAccount}</td></tr>
                            </tbody>
                        </Table>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </React.Fragment>
    )
}
