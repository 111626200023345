import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import "../admin-style.scss"
import {Pencil, Trash} from "react-bootstrap-icons";
import {AdminToolTipOverlay} from "../components/AdminTooltipOverlay";
import {Link, RouteComponentProps} from "react-router-dom";
import {User} from "../../../models/user.model";

interface AdminUsersListProps extends RouteComponentProps {
    users: User[];
}

export const AdminUsersList: FunctionComponent<AdminUsersListProps> = (props) => {

    props.users.sort((userA, userB) => {
        return userA.email > userB.email ? 1 : -1;
    });

    return (
        <div className={"admin-list"}>
            {
                props.users.map((user) => (

                        <Row key={user.id} className={"admin-list-item"}>

                            <Col xs={1}>
                                {user.id}
                            </Col>
                            <Col xs={6}>
                                <Link
                                    to={`${props.match.url}/${user.id}`}
                                    className={'kazboek-link'}>
                                    {user.email}
                                </Link>
                            </Col>
                            <Col xs={3}>
                                {user.role?.name}
                            </Col>

                            <Col xs={2} className='text-right'>
                                <AdminToolTipOverlay text={"Bewerk gebruiker"} id={"overlay_edit_"+user.id}>
                                    <Link
                                        to={`${props.match.url}/${user.id}`}
                                        className={'kazboek-link'}>
                                        <Pencil className="admin-spaced-icon"/>
                                    </Link>
                                </AdminToolTipOverlay>
                                <AdminToolTipOverlay text={"Verwijder gebruiker"} id={"overlay_remove_"+user.id} >
                                    <Trash  className="admin-spaced-icon"/>
                                </AdminToolTipOverlay>
                            </Col>
                        </Row>

                ))
            }
        </div>
    )
};
