import React, {useState} from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap';
import {CategorySelectBase} from "./row/form/CategorySelect";
import {SubcategorySelectBase} from "./row/form/SubcategorySelect";
import {X} from 'react-bootstrap-icons'
import {EntriesTableValues} from "../types";
import {FormikProps} from "formik";
import { EditableEntry} from "../EntriesTable";

interface EntriesTableMultiSelectProps {
    formikProps: FormikProps<EntriesTableValues>
}

export const EntriesTableMultiSelect: React.FC<EntriesTableMultiSelectProps> = ({formikProps}) => {
    const [ categoryId, setCategoryId ] = useState('');

    function onCategoryChange(e) {
        const newEntries: EditableEntry[] = formikProps.values.entries.map((entry, idx) => entry.selected ? {...entry, categoryId: e.target.value} : entry )
        formikProps.setValues({...formikProps.values, entries: newEntries});
        setCategoryId(e.target.value)
    }

    function onSubcategoryChange(e) {
        const newEntries = formikProps.values.entries.map((entry, idx) => entry.selected ? {...entry, subcategoryId: e.target.value} : entry )
        formikProps.setValues({...formikProps.values, entries: newEntries});
    }

    function onYearChange(e) {
        const newEntries = formikProps.values.entries.map((entry, idx) => {
            const year = Number.parseInt(e.target.value)
            if (entry.selected && !!year) {
                return {...entry, subcategoryYear: year}
            } else {
                return entry
            }
        })
        formikProps.setValues({...formikProps.values, entries: newEntries})
    }

    return (
        <Row>
            <Col sm={5}>
                Massa-acties:
            </Col>
            <Col sm={4}>
                <Row>
                    <Col sm={5}>
                        <CategorySelectBase onBlur={() => {}} onChange={onCategoryChange} value={categoryId} />
                    </Col>
                    <Col sm={5}>
                        <SubcategorySelectBase onBlur={() => {}} onChange={onSubcategoryChange} categoryId={categoryId} />
                    </Col>
                    <Col sm={2}>
                        <Form.Control size='sm' type='number' step='1' min='2000' max='3000' onChange={onYearChange} className='year-input' />
                    </Col>
                </Row>
            </Col>
            <Col sm={2} />
            <Col sm={1}>
                <Button size='sm' className='deselect-all' onClick={() => formikProps.setValues({...formikProps.values, entries: formikProps.values.entries.map(e => ({...e, selected: false}))})}>
                    <X size={18}/>
                </Button>
            </Col>
        </Row>
    )
}
