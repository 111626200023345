import {useField} from "formik";
import {Form} from "react-bootstrap";
import React from "react";

export const SwitchField = (props) => {
    const [ field ] = useField(props);

    return (
        <Form.Check id={props.id} type='switch' {...field} {...props} />
    )
}