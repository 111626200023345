import {FieldArray, useFormikContext} from "formik";
import {EntriesTableGroup} from "./EntriesTableGroup";
import React from "react";
import {EntriesTableValues} from "./types";

interface EntriesTableByCategoryProps {
    groupedIndices: {id: string, name: string, indices: number[]}[]
    options: any
}

export const EntriesTableByCategory: React.FunctionComponent<EntriesTableByCategoryProps> = ({ groupedIndices, options}) => {

    const {values} = useFormikContext<EntriesTableValues>();

    return (
        <FieldArray name='entries'>
            {(field) => (
                <div className='entries-table'>
                    { (values.entries.length === 0) ? null : (
                        groupedIndices.map(({name, indices, id: groupId}, idx) => ((indices.length) > 0 ? (
                            <EntriesTableGroup
                                key={groupId}
                                groupId={groupId}
                                name={name}
                                indices={indices}
                                options={options}
                            />
                        ) : null ))
                    )}
                </div>
            )}
        </FieldArray>
    )
}
