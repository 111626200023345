import {useState} from "react";

export function useLocalStorage<T>(defaultValue: T, key: string): [T, (v: T) => void] {
    const [storedValue, setStoredValue] = useState<T>(() => {
        const storageValue: string | null = window.localStorage.getItem(key);
        const returnValue: T = (storageValue !== null
            ? JSON.parse(storageValue)
            : defaultValue);
        return returnValue;
    });

    const setValue =  (value: T) => {
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    return [storedValue, setValue];
}
