import React, {FunctionComponent} from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {Alert, Container} from "react-bootstrap";
import {AdminTitle} from "../AdminTitle";
import {CardList} from "react-bootstrap-icons";
import {LoadingComponent} from "../../../helpers/LoadingComponent";
import {GET_BOOK_INFO, GetBookInfoResult} from "./queries";
import {AdminBookViewer} from "./AdminBookViewer";

interface AdminBookProps {

}

export const AdminBook: FunctionComponent<AdminBookProps> = (props) => {

    const {bookId} = useParams<{bookId?: any }>();
    const {loading, error, data, refetch} = useQuery<GetBookInfoResult>(GET_BOOK_INFO, {
        variables: {
            bookId: bookId
        }
    });

    return (
        <Container>
            <AdminTitle title={
                data?.book ? 'Kazboek - ' + data?.book.name : 'Kazboek - laden...'} icon={CardList}/>

            {error ? <Alert variant={"danger"}>A Error occured while fetching the data: {JSON.stringify(error)}</Alert> : null}
            {loading ? <LoadingComponent/> : null}
            {data?.book ?  <AdminBookViewer refetch={refetch} book={data.book} /> : null}
        </Container>
    )
};
