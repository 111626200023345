import {Receipt} from "../../../models/receipt.model";
import {ReceiptView} from "../entries/header/add/receipt/ReceiptView";
import {Button, Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import {ReceiptEditButton} from "./ReceiptEditButton";
import {DeleteReceiptButton} from "./ReceiptDeleteButton";
import * as React from "react";
import {ReceiptUnlinkButton} from "./ReceiptUnlinkButton";
import {Utils} from "../../../helpers/Utils";


interface ReceiptInfoCardProps {
    receipt: Receipt
    refetch?: () => {};
}

export const ReceiptInfoCard = ({receipt, refetch}:ReceiptInfoCardProps) => {
    const baseUrl = `/api/receipts/${receipt.bookId}/${encodeURIComponent(receipt.fileName)}`

    return (
        <Card className={'h-100'}>
            <ReceiptView receipt={receipt} bookId={receipt.bookId}/>
            <Card.Body>
                <Card.Title>{receipt.description}</Card.Title>
                <Card.Text>
                    &euro;&nbsp;{Utils.makeDutchCurrency(receipt.amount)}
                </Card.Text>
                <ListGroup hidden={receipt.entry === null || receipt.entry?.description == null}>
                    <ListGroupItem>
                        {receipt.entry?.description}
                    </ListGroupItem>
                </ListGroup>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col lg={6}>
                        <Button block onClick={() => window.open(baseUrl, '_blank')} variant="success">
                            <Search/>
                        </Button>
                    </Col>
                    <Col lg={6} className='text-nowrap text-right'>
                        <ReceiptEditButton refetch={refetch} receipt={receipt}/>
                        <DeleteReceiptButton refetch={refetch} id={receipt.id}/>
                        <span hidden={receipt.entry === null}>
                            <ReceiptUnlinkButton receipt={receipt} refetch={refetch}/>
                        </span>
                    </Col>
                </Row>





            </Card.Footer>
        </Card>


    )

};
