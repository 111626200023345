import React, {useEffect} from 'react'
import {Col, Form, FormGroup, ListGroup, InputGroup, Row, ButtonGroup, Button} from "react-bootstrap";
import {FastField, Field, FormikProps} from "formik";
import { DateTimeField } from '../../../forms/DateField'
import { SelectField } from "../../../forms/SelectField";
import { SwitchField } from "../../../forms/SwitchField";
import { EntriesTableValues} from "../types";
import {Category} from "../../../../models/category.model";
import {Source} from "../../../../models/source.model";
import {SubCategory} from "../../../../models/subcategory.model";


interface EntriesTableOptionsProps {
    categories: Category[];
    sources: Source[];
    values: EntriesTableValues;
    formikProps: FormikProps<EntriesTableValues>;
}

export const EntriesTableOptions: React.FC<EntriesTableOptionsProps> = ({categories, sources, values, formikProps: { setFieldValue, setFieldTouched}, ...props}) => {

    useEffect(() => {
        if(values.filters.categoryId === ''){
            setFieldValue('filters.subcategoryId', '');
        }
    }, [values.filters.categoryId])


    return (
        <ListGroup.Item className='mb-2 entries-table-options entries-table-filters'>
            <FormGroup as={Row}>
                <Col lg={1}>
                    <DateTimeField
                        name='filters.afterDate'
                        placeholder='vanaf'
                        dateFormat='DD-MM-YYYY'
                        timeFormat=''
                        viewMode='days'/>
                </Col>
                <Col lg={3}>
                    <Field type='text' as={Form.Control} name='filters.likeDescription'/>
                </Col>
                <Col lg={1}>
                    <SelectField name='filters.sourceId'>
                        <option value={''}>Bron</option>
                        { sources.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                    </SelectField>
                </Col>
                <Col lg={2}>
                    <Field name='filters.categoryId'>
                        {({field}) => (
                            <Form.Control as='select' {...field}>
                                <option value=''>Categorie</option>
                                {categories.map(category => <option key={category.id}
                                                                    value={category.id}>{category.name}</option>)}
                            </Form.Control>
                        )}
                    </Field>
                </Col>
                <Col lg={2}>
                    <Field name='filters.subcategoryId'>
                        {({field}) => (
                            <Form.Control as='select' {...field}>
                                <option value=''>Subcategorie</option>
                                {
                                    categories.reduce<SubCategory[]>((subcategories, category) => {
                                        if (category.id === values.filters.categoryId) {
                                            // @ts-ignore
                                            return [...subcategories, ...category.subcategories]
                                        } else {
                                            return [...subcategories]
                                        }
                                    }, []).map(sc => <option key={sc.id} value={sc.id}>{sc.name}</option>)
                                }
                            </Form.Control>
                        )}
                    </Field>
                </Col>
                <Col lg={1}>
                    <Field name='filters.amountFrom'>
                        {({field}) => (
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control placeholder='van' type='number' step='0.01' {...field} />
                            </InputGroup>
                        )}
                    </Field>
                </Col>
                <Col lg={2}>
                    <ButtonGroup className='btn-group-amount-sign w-100' onBlur={() => setFieldTouched('filters.amountSign', true)}>
                        <Button active={values.filters.amountSign===-1} onClick={() => setFieldValue('filters.amountSign', -1)}>Af</Button>
                        <Button active={!values.filters.amountSign} onClick={() => setFieldValue('filters.amountSign', '')}>Af of bij</Button>
                        <Button active={values.filters.amountSign === 1} onClick={() => setFieldValue('filters.amountSign', 1)}>Bij</Button>
                    </ButtonGroup>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Col lg={1}>
                    <DateTimeField
                        name='filters.beforeDate'
                        placeholder='tot'
                        dateFormat='DD-MM-YYYY'
                        timeFormat=''
                        viewMode='days'/>
                </Col>
                <Col lg={2}>
                    <SwitchField
                        id='table-options-groupByCategory'
                        label='Groeperen op categorie'
                        name='options.groupByCategoryId'
                        checked={values.options.groupByCategoryId}/>
                </Col>
                <Col lg={1} className={'text-right'}>Sorteren op</Col>
                <Col lg={2} >

                    <FastField name='filters.orderBy'>
                        {({field}) => (
                            <Form.Control as='select' {...field}>
                                <option value={'DATE'}>Datum</option>
                                <option value={'LAST_EDITED'}>Laatst bewerkt</option>
                            </Form.Control>
                        )}
                    </FastField>
                </Col>
                <Col lg={1} >
                    <FastField name='filters.orderType'>
                        {({field}) => (
                            <Form.Control as='select' {...field}>
                                <option value={'ASC'}>Oplopend</option>
                                <option value={'DESC'}>Aflopend</option>
                            </Form.Control>
                        )}
                    </FastField>
                </Col>
                <Col lg={1}>
                    <FastField name='filters.receipt'>
                        {({field}) => (
                            <Form.Control as='select' {...field}>
                                <option value={''}>Bonnen</option>
                                <option value={'WITH'}>Met bonnen</option>
                                <option value={'WITHOUT'}>Zonder bonnen</option>
                            </Form.Control>
                        )}
                    </FastField>
                </Col>
                <Col lg={1}>

                </Col>
                <Col lg={1}>
                    <Field name='filters.amountTo'>
                        {({field}) => (
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control placeholder='tot' type='number' step='0.01' {...field} />
                            </InputGroup>
                        )}
                    </Field>
                </Col>
                <Col lg={2} />
            </FormGroup>
        </ListGroup.Item>
    )
}
