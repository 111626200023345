import React, {useContext} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {CreateCategoryModalForm} from "../../../../categories/CreateCategoryModalForm";
import {Plus} from "react-bootstrap-icons";
import {BookContext} from "../../../../BookContext";
import {EditableEntry} from "../../../EntriesTable";
import {FormikProps, useFormikContext} from "formik";
import {EntriesTableValues} from "../../../types";

interface CategorySelectBaseProps {
    onChange: (any) => any;
    onBlur: (any) => any;
    value: string;
}

export const CategorySelectBase: React.FC<CategorySelectBaseProps> = ({onBlur, onChange, value, ...props}) => {
    const {categories} = useContext(BookContext)

    return (
        <InputGroup size='sm'>
            <Form.Control size='sm' as='select' onBlur={onBlur} onChange={onChange}  value={value} className='category-select'>
                <option key={0} value=''>Geen categorie</option>
                {categories.filter(c => c.hidden === false).map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Form.Control>
            {/*@ts-ignore */}
            <InputGroup.Append size={'sm'}>
                <CreateCategoryModalForm size='sm' tabIndex='-1'>
                    <Plus size={16} className='align-text-bottom'/>
                </CreateCategoryModalForm>
            </InputGroup.Append>
        </InputGroup>
    )
}

interface CategorySelectProps {
    entry: EditableEntry
    index: number;
}

export const CategorySelect: React.FC<CategorySelectProps> = ({entry, index}) => {

    const {setFieldValue, setFieldTouched} = useFormikContext<EntriesTableValues>();

    return (
        <CategorySelectBase
            onChange={e => setFieldValue(`entries[${index}].categoryId`, e.target.value)}
            onBlur={() => setFieldTouched(`entries[${index}].categoryId`, true)}
            value={entry.categoryId ? entry.categoryId : ''}
        />
    )
}
