import {Alert, Button, Form, Modal} from "react-bootstrap";
import React, {ReactNode} from "react";
import {KazboekModalProps} from "../models/PropDefinitions";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';

interface ConfirmModalProps extends KazboekModalProps {
    title: string;
    text: string | ReactNode;
    onConfirm: () => void;
    onCancel?: () => void | undefined
    successMessage?: string | ReactNode;
    errorMessage?: string | ReactNode;
    confirmText?: string;
}

interface ConfirmProps {
    text: string;
}

export const ConfirmModal = ({confirmText, show, setShow, title, text, onConfirm, onCancel, successMessage, errorMessage}:ConfirmModalProps) => {

    const cancel = () => {
        setShow(false);
        if(onCancel !== undefined) {
            onCancel();
        }
    };

    const validationSchema = Yup.object().shape({
        text: Yup.string().test("invalid-text", "tekst komt niet overeen", (value) => {
            return (value ? value : '') === (confirmText ? confirmText : '');
        })
    });

    const submitForm = () => {
        onConfirm();
    };

    const initialValues: ConfirmProps = {
        text: ''
    };

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<ConfirmProps>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>{title}</Modal.Header>
                        <Modal.Body>
                            {text}
                            {
                                confirmText ?
                                    <Form.Group>
                                        <hr />
                                        <Form.Label>Typ&nbsp;<strong>{confirmText}</strong>&nbsp;om te bevestigen</Form.Label>
                                        <Form.Control value={formikProps.values.text}
                                                      onChange={formikProps.handleChange}
                                                      onBlur={formikProps.handleBlur}
                                                      isInvalid={(formikProps.errors.text ? true : false) && formikProps.touched.text}
                                                      type="text"
                                                      id="text"
                                                      placeholder="type na" />
                                        {formikProps.errors.text
                                            ? <Form.Control.Feedback type="invalid">{formikProps.errors.text}</Form.Control.Feedback>
                                            : null}
                                    </Form.Group> : null
                            }
                            { (successMessage) ? <Alert variant='success'>{successMessage}</Alert> : '' }
                            { (errorMessage) ? <Alert variant='danger'>{errorMessage}</Alert> : '' }
                        </Modal.Body>
                        <Modal.Footer>

                            <Button onClick={() => {cancel()}} variant='link'>Annuleren</Button>
                            <Button type={"submit"} disabled={!(formikProps.isValid)} variant='primary'>Bevestigen</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>

        </Modal>
    )

};
