import React, {FunctionComponent, ReactNode, useState} from "react";
import {Container} from "react-bootstrap";
import useInterval from "./useInterval";

interface LoadingComponentProps {
    item?: string | ReactNode
    dotCount?: number;
}

export const LoadingComponent: FunctionComponent<LoadingComponentProps> = (props) => {

    const [dotCount, setDotCount] = useState<number>(0);

    const updateDotCount = () => {
        if (dotCount > (props.dotCount ? props.dotCount :  5)) {
            setDotCount(0);
            return;
        }
        setDotCount(dotCount + 1);
    };

    useInterval(updateDotCount, 130);

    const dotString = new Array(dotCount + 1).join(".");

    return (
        <Container>
            <h1>
                {props.item ? (<span>{props.item}&nbsp;l</span>) : <span>L</span>}
                aden{dotString}
            </h1>
        </Container>
    )
};
