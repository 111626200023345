import React, {useContext, useMemo, useState, useCallback} from 'react'
import {
    Formik,
    FormikHelpers,
} from 'formik'
import {
    Form,
    ListGroup,
    Collapse,
} from 'react-bootstrap';
import {useParams, Prompt} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {ENTRIESQUERY, MUTATION_UPDATE_ENTRIES} from "../Queries";
import 'react-datetime/css/react-datetime.css'
import '../../../kazboek-style.scss'
import {EntriesTableHeader} from "./table/EntriesTableHeader";
import {BookContext} from "../BookContext";
import {EntriesTableOptions} from "./table/Options";
import {EntriesTableMultiSelect} from "./table/MultiSelect";
import {Entry} from "../../../models/entry.model";
import {useYear} from "../../../providers/year-provider";
import {LimitedFormikContextProvider} from "./EntriesTableContext";
import {EntriesCleaner} from "./EntriesCleaner";
import {EntriesFilter, EntriesTableValues} from "./types";
import {YearChangeHandler} from "./YearChangeHandler";
import {EntriesTableEntries} from "./EntriesTableEntries";

const initialFilters: EntriesFilter = {
    afterDate: '',
    beforeDate: '',
    amountFrom: '',
    amountTo: '',
    sourceId: '',
    categoryId: '',
    subcategoryId: '',
    likeDescription: '',
    amountSign : '',
    orderBy: 'DATE',
    orderType: 'DESC',
    receipt: ''
}


export interface EditableEntry extends Entry{
    selected: boolean;
    editing: boolean;
};

export const processEntries = (entries: Entry[]): EditableEntry[]  => {
    //ts-ignore
    return entries.map(entry => {
        return {
            ...entry,
            selected: false,
            editing: false,
        } as EditableEntry
    })
}

interface EntriesTableProps {

}


export const EntriesTable: React.FunctionComponent<EntriesTableProps> = (props) => {
    const [ showOptions, setShowOptions ] = useState(false);
    let { bookId } = useParams<{bookId?: any}>();
    let { year } = useYear();



    const { data: { book: { entries }} = { book: { entries: [] }}, loading: entriesLoading, refetch, networkStatus, fetchMore} = useQuery(ENTRIESQUERY, {
        variables: {
            bookId: bookId,
            year: year,
        },
        onError: (error) => console.log(JSON.stringify(error)),
    });

    const { categories, sources, book } = useContext(BookContext);

    const [ updateEntries ] = useMutation(MUTATION_UPDATE_ENTRIES, {
        onError: (error) => { console.log(error) },
        update: (cache, { data }) => {
            const entryFilter = e => !e.subcategoryYear || !e.subcategoryId || e.subcategoryYear === year;
            const cachedEntries : null | {book: {entries: [Entry]}} = cache.readQuery({query: ENTRIESQUERY, variables: {year, bookId}});
            if (!!cachedEntries) {
                cache.writeQuery({
                    query: ENTRIESQUERY, variables: {year, bookId}, data: {
                        ...cachedEntries,
                        book: {
                            ...cachedEntries.book,
                            entries: cachedEntries.book.entries.filter(entryFilter)
                        }
                    }
                })
            }
        }
    });

    const processedEntries: EditableEntry[] = useMemo(() => {
        return processEntries(entries)
    }, [ entries ]);

    const saveEntries = useCallback((updatingEntries: EntriesTableValues, formikHelpers: FormikHelpers<EntriesTableValues>) => {
        let entriesToSave: EditableEntry[] = processedEntries.slice();
        if (updatingEntries.entries.length === 1) {
            entriesToSave[processedEntries.findIndex(pe => pe.id === updatingEntries.entries[0].id)] = updatingEntries.entries[0];
        } else if (updatingEntries.entries.length === processedEntries.length) {
            entriesToSave = updatingEntries.entries.slice();
        } else {
            throw new Error('The entries are no longer well aligned!')
        }

        const changedEntries = entriesToSave.reduce<Partial<Entry>[]>((changedEntries, entry, idx) => {
            const pe = processedEntries[idx];
            if (pe.id !== entry.id) {
                throw new Error('The entries are no longer well aligned!');
            }
            const ce = ['description', 'date', 'subcategoryId', 'subcategoryYear', 'amount'].reduce<Partial<Entry>>((ce, key) => {
                const isDifferent = entry[key] !== pe[key];
                return isDifferent ? {...ce, [key]: entry[key]} : ce;
            }, {id: entry.id});

            if (Object.keys(ce).length > 1) {
                return [...changedEntries, ce]
            } else {
                return changedEntries
            }
        }, []);



        return updateEntries({variables: {entries: changedEntries}}).then(
            (a) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.resetForm()


                return a;
            }
        )
    }, [processedEntries])

    const formikInitialValues:EntriesTableValues = useMemo<EntriesTableValues>(() => {
        return {
            filters: initialFilters,
            options: {
                groupByCategoryId: true,
            },
            entries: processedEntries,
        }
    }, [processedEntries]);

    if (entriesLoading) return (
        <ListGroup>
            <ListGroup.Item>
                Loading....
            </ListGroup.Item>
        </ListGroup>
    );



    return (
        <React.Fragment>
            <Formik

                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={false}
                initialValues={formikInitialValues}
                onSubmit={(values, helpers) => {
                    saveEntries(values, helpers)
                }}
            >
                {(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset} className='entries'>
                        <LimitedFormikContextProvider formikProps={formikProps} saveEntries={saveEntries} refetch={refetch}>
                            <YearChangeHandler resetForm={formikProps.resetForm} entries={processedEntries} values={formikProps.values}/>
                            <EntriesCleaner formikProps={formikProps} values={formikProps.values} resetForm={formikProps.resetForm} initialValues={formikInitialValues} entries={formikProps.values.entries} />
                            <Prompt when={formikProps.dirty} message='Zijn alle wijzigingen opgeslagen?'    />
                            <ListGroup>
                                <EntriesTableHeader {...{book, setShowOptions, showOptions}} />
                            </ListGroup>
                            <Collapse in={showOptions}>
                                <ListGroup>
                                    <EntriesTableOptions
                                        values={formikProps.values}
                                        categories={categories}
                                        sources={sources}
                                        formikProps={formikProps}
                                    />
                                </ListGroup>
                            </Collapse>
                            <Collapse in={!!formikProps.values.entries ? formikProps.values.entries.filter(e => e.selected).length > 0 : false}>
                                <ListGroup>
                                    <ListGroup.Item className='mass-select'>
                                        <EntriesTableMultiSelect formikProps={formikProps} />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>

                            <EntriesTableEntries
                                initialEntries={formikProps.initialValues.entries}
                            />
                        </LimitedFormikContextProvider>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    )
};
