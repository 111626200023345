import React, {FunctionComponent, useState} from "react";
import {KazboekModalProps} from "../../../models/PropDefinitions";
import {Book} from "../../../models/book.model";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_ROLE_NAMES, GetRoleNamesResult, UPDATE_BOOK, UpdateBookResult} from "../books/queries";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';

interface EditBookModalProps extends KazboekModalProps {
    refetch: () => void;
    book: Book;
}

interface EditBook {
    name: string;
}


export const EditBookModal: FunctionComponent<EditBookModalProps> = (props) => {

    const {error} = useQuery<GetRoleNamesResult>(GET_ROLE_NAMES);

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-z0A-Z0-9 _-]+$/, "Ongeldig formaat, alleen Hoofdletter, kleine letters, spaties en - en _ zijn toegestaan")
            .required()
            .min(4)
    });

    const closeModal = () => {
        props.setShow(false);
    };

    const initialValues: EditBook = {
        name: props.book.name
    };

    const [update] = useMutation<UpdateBookResult>(UPDATE_BOOK);

    const submitForm = (values, {setSubmitting, resetForm}) => {
        update ( {
            variables: {
                name: values.name,
                bookId: props.book.id
            }
        }).then((data) => {
            setShowSuccess(true);
            props.refetch();
            window.setTimeout(() => {
                closeModal();
                setShowSuccess(false);
            }, 2000);
        }).catch(() => {
            resetForm();
            setShowError(true);
            setSubmitting(false)
        })
    };

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<EditBook>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>Bewerk kazboek</Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Naam van kazboek</Form.Label>
                                <Form.Control value={formikProps.values.name}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.name ? true : false) && formikProps.touched.name}
                                              type="text"
                                              id="name"
                                              placeholder="Kazboek naam" />
                                {formikProps.errors.name
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.name}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            {showError ? <Alert variant={"danger"}>Kon kazboek niet bijwerken</Alert> : null }
                            {showSuccess ? <Alert variant={"success"}>Successvol Bijgewerkt</Alert> : null }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                            <Button variant='primary'
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid || !!error}>Opslaan</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
};
