import {LinkContainer} from "react-router-bootstrap";
import {Nav} from "react-bootstrap";
import React from "react";
import '../kazboek-style.scss'


export function RouterLink({to, ...props}) {
    return (
        <LinkContainer to={to}>
            <Nav.Link href={to} {...props}>{props.children}</Nav.Link>
        </LinkContainer>
    )
}

export function NavRouterLink({to, className, ...props}) {
    return (
        <RouterLink className={ !!className ? className + ' text-white px-3' : 'text-white px-3' } to={to} {...props}>{props.children}</RouterLink>
    )
}
