import React, {FunctionComponent} from 'react'
import {Col, Row} from "react-bootstrap";
import {AdminSidebar} from "./sidebar/AdminSidebar";
import {Route, RouteComponentProps} from "react-router-dom";
import {AdminBooks} from "./books/AdminBooks";
import {AdminUsers} from "./users/AdminUsers";
import {AdminMain} from "./main/AdminMain";
import './sidebar/style.scss'
import {AdminRoles} from "./roles/AdminRoles";
import {AdminUser} from "./user/AdminUser";
import {AdminBook} from "./book/AdminBook";
import {AdminRole} from "./role/AdminRole";


interface AdminProps extends RouteComponentProps{

};

export const Admin: FunctionComponent<AdminProps> = (props) => {
    return (
        <Row>
            <Col md={3} xl={2} className={'admin-sidebar'}>
                <AdminSidebar {...props}/>
            </Col>
            <Col md={9} xl={10}>
                <Route path={`${props.match.path}`} component={AdminMain} exact={true} />
                <Route path={`${props.match.path}/books`} component={AdminBooks} exact={true}/>
                <Route path={`${props.match.path}/books/:bookId`} component={AdminBook} />
                <Route path={`${props.match.path}/users`} component={AdminUsers} exact={true} />
                <Route path={`${props.match.path}/users/:userId`} component={AdminUser} />
                <Route path={`${props.match.path}/roles`} component={AdminRoles} exact={true}/>
                <Route path={`${props.match.path}/roles/:roleId`} component={AdminRole} />
            </Col>
        </Row>

    )
};
