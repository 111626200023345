import React, {FunctionComponent, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {AdminToolTipOverlay} from "../../components/AdminTooltipOverlay";
import {Book} from "../../../../models/book.model";
import {Plus} from "react-bootstrap-icons";
import {CreateSourceModal} from "./CreateSourceModal";
import {RemoveSourceFromBookButton} from "./RemoveSourceFromBookButton";
import {EditSourceButton} from "./EditSourceButton";

interface AdminBookSourceInfoProps {
    book: Book;
    refetch: () => void;
}

export const AdminBookSourceInfo: FunctionComponent<AdminBookSourceInfoProps> = ({book, ...props}) => {

    const [show, setShow] = useState<boolean>(false);
    return (
        <Row>
            <CreateSourceModal book={book} refetch={props.refetch} show={show} setShow={setShow}/>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5 className={"d-inline-block"}>Bronnen</h5>
                    <Button onClick={() => setShow(true)}
                            size={"sm"}
                            className={"float-right"}
                            type={"button"}
                            variant={"link"}>
                        <Plus/>&nbsp;toevoegen
                    </Button>
                    <p className={"small"}>Alle plekken waar geld staat van dit kazboek</p>
                </Col>
                <hr />
                <div className={"admin-list-header ml-1 mr-1"}>
                    <Row>
                        <Col xs={1}>
                            <AdminToolTipOverlay text={"Unique nummer van elke bron"} id={"id_overlay"}>
                                <strong>ID</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={2}>
                            <AdminToolTipOverlay text={"Naam van elke bron"} id={"name_overlay"}>
                                <strong>Naam</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={2}>
                            <AdminToolTipOverlay text={"Huidige eindbalans van bron"} id={"balance_overlay"}>
                                <strong>Balans</strong>
                            </AdminToolTipOverlay>
                        </Col>
                        <Col xs={5}>
                            <AdminToolTipOverlay text={"IBAN nummer van deze bron, verplicht voor CAMT inlezen"} id={"iban_overlay"}>
                                <strong>IBAN</strong>
                            </AdminToolTipOverlay>
                        </Col>
                    </Row>
                </div>
                <div className={"admin-list ml-1 mr-1"}>
                    {
                        book?.sources.map((source) => (
                            <Row key={source.id} className={"admin-list-item"}>
                                <Col xs={1}>
                                    {source.id}
                                </Col>
                                <Col xs={2}>
                                    {source.name}
                                </Col>
                                <Col xs={2}>
                                    <AdminToolTipOverlay text={"Startbalans: " + source.startAmount} id={"source_overlay_"+source.id}>
                                        <span>{source.balance}</span>
                                    </AdminToolTipOverlay>

                                </Col>
                                <Col xs={5}>
                                    {source.iban}
                                </Col>
                                <Col xs={2} className={"text-right"}>
                                    <EditSourceButton source={source} refetch={props.refetch}/>&nbsp;
                                    <RemoveSourceFromBookButton refetch={props.refetch} book={book} source={source}/>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </div>
        </Row>
    )
};
