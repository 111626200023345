import {useParams} from "react-router-dom";
import React, {FunctionComponent, useState, useEffect} from "react";
import {useMutation} from "@apollo/react-hooks";
import {ADDSUBCATEGORY, AddSubcategoryResult, CATEGORIES, CategoriesResult} from "../Queries";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {Category} from "../../../models/category.model";

export interface CreateSubcategoryModalFormProps {
    initialCategoryId?: string;
    categories: Category[];
}

export const CreateSubcategoryModalForm: FunctionComponent<CreateSubcategoryModalFormProps & React.HTMLAttributes<React.ReactFragment>> = ({initialCategoryId, ...props}) => {
    let { bookId } = useParams<{bookId?: any}>();
    const [ show, setShow ] = useState(false);
    const [ name, setName ] = useState('');
    const [ categoryId, setCategoryId ] = useState(!!initialCategoryId ? initialCategoryId : '-1');
    const [ valid, setValid ] = useState(false);

    const [ addSubcategory, { loading } ] = useMutation<AddSubcategoryResult>(ADDSUBCATEGORY, {
            onError: (error) => {
                setValid(false);
            },
            update: (cache, data) => {
                const newSubcategory = data.data?.createSubcategory?.subcategory;
                // @ts-ignore
                const {book} = cache.readQuery<CategoriesResult>({ query: CATEGORIES, variables: { bookId }})
                if(newSubcategory === undefined){
                    return;
                } else {
                    cache.writeQuery({
                        query: CATEGORIES,
                        variables: { bookId },
                        data: {
                            book: {
                                ...book,
                                categories: book.categories.map(c => c.id !== categoryId ? c : {
                                    ...c,
                                    subcategories: [...c.subcategories, {
                                        // @ts-ignore
                                        __typename: newSubcategory.__typename,
                                        id: newSubcategory.id,
                                        name: newSubcategory.name,
                                        balance: newSubcategory.balance,
                                    }]
                                })
                            }
                        }})
                    }

            }
        });

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        addSubcategory({variables: {name: name, categoryId: categoryId}})
            .then(() => setShow(false));
    };

    useEffect(() => {
        if (!!initialCategoryId) {
            setCategoryId(initialCategoryId);
        }
    }, [ initialCategoryId])

    if (props.categories.length === 0) {
        return (
            <React.Fragment>
                <Button disabled {...props} className={(props.className || '') + ' create-subcategory-button'} />
            </React.Fragment>
        )
    }


    const categories = props.categories.map(category => {
        return <option key={category.id} value={category.id}>{category.name}</option>
    });

    return (
        <React.Fragment>
            <Button variant="primary" onClick={() => setShow(true)} {...props} className={(props.className || '') + ' create-subcategory-button'} />
            <Modal show={show} onHide={() => setShow(false)} className='create-subcategory-modal'>
                <Form noValidate onSubmit={handleSubmit} validated={valid}>
                    <Modal.Header closeButton>
                        <Modal.Title>Voeg een subcategorie toe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Naam:</Form.Label>
                            <Form.Control type='text' placeholder='Naam' onChange={(e) => setName(e.target.value)} value={name}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Categorie:</Form.Label>
                            <Form.Control as='select' value={categoryId} onChange={(e) => setCategoryId(e.target.value)} >
                                <option disabled value='-1' key='-1'>Kies een categorie</option>
                                {categories}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>Selecteer een categorie</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Toevoegen { (loading) ? <Spinner animation='border' size='sm' /> : null }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}
