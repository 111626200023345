import React from "react";
import './helpers.style.scss'
import {Utils} from "./Utils";

interface CurrencyProps {
    amount: number;
}

export const Currency = ({amount}:CurrencyProps) => {

    let newStringAmount = Utils.makeDutchCurrency(amount);

    return (
        <div className='d-inline-flex w-100'>
            <div className='mr-auto'>
                €
            </div>
            <div className='currency text-right'>
                {newStringAmount}
            </div>
        </div>
    )
};
