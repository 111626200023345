import {useMutation, useQuery} from "@apollo/react-hooks";
import {Alert, Button, ButtonGroup} from "react-bootstrap";
import {ChevronDown} from "react-bootstrap-icons";
import * as React from "react";
import { RECEIPTS_FOR_BOOK, RECEIPT_UPDATE } from '../../../../Queries'
import {ReceiptView} from "./ReceiptView";
import {Receipt} from "../../../../../../models/receipt.model";

export interface ReceiptSelectProps {
    setShow: any;
    entryId: string;
    amount: number;
    bookId: number;
    props: any;
}


export const ReceiptSelect = ({setShow, bookId, entryId, amount}:ReceiptSelectProps) => {

    const { data: { book: { receipts }} = { book: { receipts: [] }}, loading: entriesLoading } = useQuery(RECEIPTS_FOR_BOOK, {
        variables: {
            bookId: bookId,
            amount: amount
        },
        onError: (error) => console.log(JSON.stringify(error)),
    });

    const [update, {error: error2, data: data2}] = useMutation(RECEIPT_UPDATE);

    const updateSelected = (receiptId: number, entryId: string) => {
        update(
            {
                variables: {
                    receiptId,
                    entryId
                }
            }
        ).catch((error) => console.log("Could not update the receipt for entry"));
    };

    if(error2){
        return (<Alert variant='danger'>Kon bon niet toevoegen aan entry</Alert>)
    }

    if(data2){
        setTimeout(() => {
            setShow(false);
        }, 2000);
        return (<Alert variant='success'>De bon is toegevoegd aan de entry</Alert>)

    }

    if(!entriesLoading){
        const listItems = receipts.map((receipt: Receipt) =>{
                return (
                    <div key={receipt.id}>
                        <hr/>
                        <ButtonGroup>
                            <Button onClick={() => ( updateSelected(receipt.id, entryId))} >
                                Gebruik deze&nbsp;
                                <ChevronDown/>
                            </Button>
                        </ButtonGroup>
                        <div>Omschrijving: {receipt.description}</div>
                        <ReceiptView receipt={receipt} bookId={bookId} />
                    </div>
                )
            }

        );
        if(listItems.length > 0){
            return (

                <div>
                    <h4>Of selecteer een bon</h4>

                    <div>{listItems.length} {listItems.length === 1 ? 'bon' : 'bonnen'} gevonden met een bedrag van &euro; {amount.toFixed(2)}</div>

                    {listItems}
                </div>
            );
        } else {
            return (
                <div>
                    <h4>Of selecteer een bon</h4>
                    <div>Er zijn geen bonnen met een bedrag &euro; {amount.toFixed(2)}</div>
                </div>
            )
        }

    };
    return (<div>Bonnen laden...</div>)
};
