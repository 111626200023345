import React, {FunctionComponent} from "react";
import { useParams } from "react-router-dom";
import {AdminTitle} from "../AdminTitle";
import {Lock} from "react-bootstrap-icons";
import {Alert, Container} from "react-bootstrap";
import {GET_ROLE_INFO, GetRoleInfoResult} from "./queries";
import {useQuery} from "@apollo/react-hooks";
import {AdminRoleViewer} from "./AdminRoleViewer";
import {LoadingComponent} from "../../../helpers/LoadingComponent";

interface AdminRoleProps {

}

export const AdminRole: FunctionComponent<AdminRoleProps> = (props) => {

    const {roleId} = useParams<{roleId?: any}>();
    const {data, loading, error, refetch} = useQuery<GetRoleInfoResult>(GET_ROLE_INFO, {
        variables: {
            roleId: roleId
        }
    });

    return (
        <Container>
            <AdminTitle title={"Rol - " + (data ? data?.role.name : "laden..")} icon={Lock} description={"Permissierol, aanpassen kan leiden tot ongewenste gevolgen"} />
            {loading ? <LoadingComponent /> : null}
            {data ? <AdminRoleViewer role={data.role} refetch={refetch} /> : null }
            {error ? <Alert variant={"danger"}>Kon rollen niet ophalen: {JSON.stringify(error)}</Alert> : null}
        </Container>
    )
};
