import React, {PropsWithChildren, useContext, useRef} from 'react'
import {FormikHelpers, FormikProps} from "formik";
import {EntriesTableValues} from "./types";

interface LimitedFormikContextProps {
    saveEntries: (entries: EntriesTableValues,
                  formikHelpers: FormikHelpers<EntriesTableValues>) => any;
    refetch?: (p: { year: number; bookId: number }) => Promise<any>;
    formikProps: FormikProps<EntriesTableValues>
}

// @ts-ignore
const limitedFormikContext = React.createContext<LimitedFormikContextProps>({saveEntries: (x, any) => {}})


export const LimitedFormikContextProvider = (props: PropsWithChildren<LimitedFormikContextProps>) => {
    const lastProps = useRef({})
    let allSame = true;

    if (Object.keys(lastProps.current).length !== Object.keys(props).length) {
        lastProps.current = props;
    } else {
        Object.keys(lastProps.current).forEach(key => {
            allSame = allSame && (lastProps.current[key] === props[key])
        });

        if (!allSame) {
            lastProps.current = props;
        }
    }

    return (
        <limitedFormikContext.Provider value={{
            saveEntries: props.saveEntries,
            refetch: props.refetch,
            formikProps: props.formikProps
        }}>
            {props.children}
        </limitedFormikContext.Provider>
    )
}


export const useLimitedFormikContext = () => {
    return useContext(limitedFormikContext);
}
