import React, {FunctionComponent} from 'react'
import { Link, Redirect } from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {Col, ListGroup, Row} from 'react-bootstrap';
import {LoadingComponent} from "../../helpers/LoadingComponent";
import {NoBooksMessage} from "./NoBooksMessage";
import Container from "react-bootstrap/Container";
import {CardList} from "react-bootstrap-icons";
import {BookUser, bookUserNameSorter} from "../../models/book-user.model";


interface BookListResult {
    books: BookUser[];
}

const BOOKS_LIST = gql`
    query {
        books {
            book {
                id
                name
            }
            role {
                id
                name
            }
        }
    }
`;

interface BooksProps {

}

export const Books: FunctionComponent<BooksProps> = ({...props}) => {
    const { data = { books: [] }, loading } = useQuery<BookListResult>(BOOKS_LIST, {
        onError: error => { console.log(JSON.stringify(error)) }
    });

    if (loading) {
        return (
            <Container>
                <LoadingComponent item={"Kasboeken"}/>
            </Container>
        )
    }

    const bookList = data.books ? data.books.sort(
        bookUserNameSorter
    ).map((bookUser) => {
        return (
            <ListGroup.Item key={ bookUser.book?.id }>
                <Row>
                    <Col xs={8}>
                        <Link to={`books/${bookUser.book?.id}`} key={bookUser.book?.id }>{bookUser.book?.name}</Link>
                    </Col>
                    <Col xs={4}>
                        {bookUser.role?.name}
                    </Col>
                </Row>

            </ListGroup.Item>
        )
    }) : [];

    if (bookList.length === 1) {
        return <Redirect to={`/books/${data.books[0].book?.id}`}/>
    }

    if(bookList.length === 0) {
        return (
            <Container>
                <NoBooksMessage/>
            </Container>
        )
    }

    return (
        <Container>
            <h4 className={"mt-4"}><CardList />&nbsp;Jouw Kasboeken</h4>
            <hr />
            <Row className='py-2'>
                <Col xs={12}>
                    <ListGroup id='books-list'>
                        {bookList}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    )
};
