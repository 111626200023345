import {ChangeEvent, FunctionComponent, default as React, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";


interface CurrencyInputProps {
    value: number | string | null;
    setValue: (amount: number) => void;
    disabled?: boolean;
}

export const CurrencyInput: FunctionComponent<CurrencyInputProps> = (props) => {

    const [amount, setAmount] = useState<string | number | null>((!isNaN(Number(props.value))) ? props.value : 0);

    const updateAmount = (value: number | string) => {
        setAmount(value);
        props.setValue((!isNaN(Number(value))) ? +value : 0)
    };

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                value={amount === null ? undefined : amount}
                disabled={props.disabled}
                type='number'
                onChange={(e:ChangeEvent<HTMLInputElement>) => {
                    updateAmount(e.target.value);
                }}
            />
        </InputGroup>
    )
};
