import React, {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {Role} from "../../../models/role.model";

interface AdminRoleBaseInfoProps {
    role: Role;
}

export const AdminRoleBaseInfo: FunctionComponent<AdminRoleBaseInfoProps> = ({role, ...props}) => {

    return (
        <Row>
            <div className={"admin-info-card"}>
                <Col xs={12}>
                    <h5>Gegevens</h5>
                    <p className={"small"}>Stamgegevens van deze rol</p>
                </Col>
                <hr />
                <Col xs={12}>
                    <span><strong>ID:</strong>&nbsp;{role.id}</span>
                </Col>
                <Col xs={12}>
                    <span><strong>Naam:</strong>&nbsp;{role.name}</span>
                </Col>
                {role.description ?
                    <Col xs={12}>
                        <span><strong>Omschrijving:</strong>&nbsp;{role.description}</span>
                    </Col> : null
                }

            </div>
        </Row>
    );
};
