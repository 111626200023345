import {ListGroup} from "react-bootstrap";
import {DeleteSubcategoryModalForm} from "./DeleteSubcategoryModalForm";
import {EditSubcategoryModalForm} from "./EditSubcategoryModalForm";
import React from "react";


export function SubcategoryView(props) {
    return (
        <ListGroup.Item>
            <div className='justify-content-between d-flex'>
                <span>{props.name}</span>
                <div>
                    <DeleteSubcategoryModalForm id={props.id} name={props.name} />
                    <EditSubcategoryModalForm id={props.id} name={props.name} />
                </div>
            </div>
        </ListGroup.Item>
    )
}
