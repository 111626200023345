import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Card, Collapse} from "react-bootstrap";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";
import {EntriesTableGroupList} from "./EntriesTableGroupList";

interface EntriesTableGroupProps {
    groupId: string;
    options: any;
    indices: number[];
    name: string;
}

export const EntriesTableGroup: React.FunctionComponent<EntriesTableGroupProps> = ({ groupId, options, name, indices}) => {

    const { bookId } = useParams<{bookId?: any}>();
    const [ show, setShow ] = useState(() => {
        if (!!bookId) {
            const storedExpandedCategories = JSON.parse(localStorage.getItem('expandedCategories') || '{}');
            const storedBookExpandedCategories = storedExpandedCategories[bookId] || {'0': true};
            return storedBookExpandedCategories[groupId] || false;
        } else {
            return false;
        }
    });

    useEffect(() => {
        const storedExpandedCategories = JSON.parse(localStorage.getItem('expandedCategories') || '{}');
        const storedBookExpandedCategories = storedExpandedCategories[bookId] || {};

        storedBookExpandedCategories[groupId] = show;

        localStorage.setItem('expandedCategories', JSON.stringify({...storedExpandedCategories, [bookId]: storedBookExpandedCategories}))
    }, [ bookId, groupId, show ])

    return (
        <Card className='my-1 category-group'>
            <Card.Header className='category-group-header'>
                <Button className='toggle' size='sm' onClick={() => setShow(!show)}>
                    {(show) ? <ChevronUp size={20}/> : <ChevronDown size={20}/>}
                </Button>
                <b className='ml-3'>{name}</b>
            </Card.Header>
            <Collapse in={show}>
                <Card.Body className='p-0 category-group-content'>
                    <EntriesTableGroupList indices={indices}/>
                </Card.Body>
            </Collapse>
        </Card>
    )
};
