import gql from "graphql-tag";
import {Role} from "../../../models/role.model";


export interface GetRolesAndPermissionsResult {
    roles: Role[];
}
export const GET_ROLES_AND_PERMISSIONS = gql`
    query {
        roles {
            id,
            name,
            permissions {
                id
                internalName
            }
        }
    }
`;
