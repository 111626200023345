import {KazboekModalProps} from "../../../models/PropDefinitions";
import React, {FunctionComponent} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import * as Yup from 'yup';
import {CREATE_BOOK, GET_ROLE_NAMES, GetRoleNamesResult} from "./queries";


interface CreateBookModalProps extends KazboekModalProps{
    refetch: () => void;
}

interface NewBook {
    name: string;
    roleId: number;
}

export const CreateBookModal: FunctionComponent<CreateBookModalProps> = (props) => {

    const {loading, error, data} = useQuery<GetRoleNamesResult>(GET_ROLE_NAMES);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-z0A-Z0-9 _-]+$/, "Ongeldig formaat, alleen Hoofdletter, kleine letters, spaties en - en _ zijn toegestaan")
            .required()
            .min(4),
        roleId: Yup.number().min(1).required()
    });

    const closeModal = () => {
        props.setShow(false);
    };

    const initialValues: NewBook = {
        name: '',
        roleId: 0
    };

    const [create, {error: createError, data: createdData},] = useMutation(CREATE_BOOK);

    const submitForm = (values, {setSubmitting, resetForm}) => {
        create ( {
            variables: {
                name: values.name,
                roleId: values.roleId
            }
        }).then((data) => {
            props.refetch();
            window.setTimeout(() => {closeModal()}, 2000);
        }).catch(() => {
            resetForm();
            setSubmitting(false)
        })
    };

    return (
        <Modal show={props.show} onHide={() => closeModal()}>
            <Formik initialValues={initialValues}
                    onSubmit={submitForm}
                    validationSchema={validationSchema}>
                {(formikProps: FormikProps<NewBook>) => (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Modal.Header closeButton>Voeg een nieuw Kazboek toe</Modal.Header>
                        {/*<p>{JSON.stringify(formikProps.values)}</p>*/}
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Naam van kazboek</Form.Label>
                                <Form.Control value={formikProps.values.name}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.name ? true : false) && formikProps.touched.name}
                                              type="text"
                                              id="name"
                                              placeholder="Kazboek naam" />
                                {formikProps.errors.name
                                    ? <Form.Control.Feedback type="invalid">{formikProps.errors.name}</Form.Control.Feedback>
                                    : null}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Jouw rol in dit kazboek</Form.Label>
                                <Form.Control
                                                value={formikProps.values.roleId}
                                              onChange={formikProps.handleChange}
                                              onBlur={formikProps.handleBlur}
                                              isInvalid={(formikProps.errors.roleId ? true : false) && formikProps.touched.roleId}
                                              as="select"
                                              id="roleId"
                                              placeholder="Voer rolnummer in">
                                    <option value={0} disabled>{data ? "Kies een rol" : (loading ? "laden.." : "FOUT")}</option>
                                    {
                                        data?.roles.map((role) => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))
                                    }

                                </Form.Control>
                            </Form.Group>
                            {createError ? <Alert variant={"danger"}>Kon Kazboek niet aanmaken: {createError.message}</Alert> : null }
                            {error ? <Alert variant={"danger"}>Kon rollen niet laden aanmaken</Alert> : null }
                            {createdData ? <Alert variant={"success"}>Kazboek {createdData.name} succesvol aangemaakt</Alert> : null }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" onClick={() => {closeModal()}} variant="link">Annuleren</Button>
                            <Button variant='primary'
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid || !!error}>Opslaan</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )

};
