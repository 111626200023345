import {Container} from "react-bootstrap";
import {AdminTitle} from "../AdminTitle";
import {Lock} from "react-bootstrap-icons";
import React from "react";
import {AdminRolesList} from "./AdminRolesList";


export const AdminRoles = (props) => {
    return (
        <Container>
            <AdminTitle title={'Rollen'} icon={Lock} description={"Alle rollen binnen het kazboek"}/>
            <AdminRolesList {...props}/>
        </Container>
    )
};
