import React, {FunctionComponent} from "react";
import {Col, Container, Row} from "react-bootstrap";

interface LandingPageProps {

}

export const LandingPage: FunctionComponent<LandingPageProps> = (props) => {

    return (
        <Container>
            <Row className={"mt-4"}>
                <Col xs={12}>
                    <h4>Welkom op het Kazboek!</h4>
                    <hr />
                    <p>Klik rechtsboven op inloggen om te beginnen.</p>
                </Col>
            </Row>
        </Container>
    )
};
