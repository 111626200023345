import * as React from 'react';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {ChangeEvent, useState} from "react";
import {useRef} from "react";
import {Entry} from "../../../../../../models/entry.model";
import {ReceiptSelect} from "./ReceiptSelect";
import { useParams } from 'react-router'
import {useAuth} from "../../../../../../Auth";
import {useQuery} from "@apollo/react-hooks";
import {ENTRIESQUERY} from "../../../../Queries";
import {useYear} from "../../../../../../providers/year-provider";
import {CurrencyInput} from "../../../../../../helpers/CurrencyInput";

export interface ReceiptUploadProps {
    entry: Entry | null;
    show: boolean;
    setShow: any;
    props: any;
    file?: File;
}

interface AuthProps {
    isAuthenticated: boolean;
    token: string;
}

export const ReceiptUpload = ({file: droppedFile, show, setShow, entry, ...props}: ReceiptUploadProps) => {
    let { year } = useYear();
    let { bookId } = useParams<{bookId?: any}>();
    const { token }: AuthProps = useAuth();
    const [ file, setFile ] = useState<File | undefined>(droppedFile);
    const [ amount, setAmount] = useState<number | null>(entry?.amount ? (entry.amount*-1) : 0);
    const [ entryId ] = useState<string>(!!entry ? entry.id : "0");
    const [ description, setDescription] = useState<string>(entry?.description ? entry.description : '');
    const [ alert, setAlert ] = useState('');
    const [ error, setError ] = useState('');
    const fileInputForm = useRef<HTMLInputElement>(null);

    const { refetch: refetchEntries } = useQuery(ENTRIESQUERY, {
        variables: {
            bookId: bookId,
            year: year,
        },
        onError: (error) => console.log(JSON.stringify(error)),
    });

    const submitFile = () => {
        const formData = new FormData();
        const restUrl = '/api/receipts/upload';

        formData.append('file', file as Blob);
        formData.append('bookId', bookId);
        formData.append('amount', (amount ? amount : 0).toString());
        formData.append('entryId', entryId.toString());
        formData.append('description', description);

        fetch(restUrl, {
            method: 'POST',
            body: formData,
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            }
        }).then(response => response.json()
        ).then(data => {
            if(data.success){
                setAlert('De bon is succesvol toegevoegd aan de entry.');

                refetchEntries({year, bookId}).then(data => {
                    setShow(false)
                })

            } else {
                if(data.error){
                    setError(`Er is een fout opgetreden, de server meldt: "${data.error}"`);
                } else {
                    setError(`Er is een fout opgetreden`);
                }


            }

        }).catch(error => {
            console.log("Fetch Error: ", error);
        });
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = e.target.files[0];
        setFile(file)
    };

    return (
        <div>
            <h4 className="mt-3 mb-3">Upload Bon</h4>
            <Form encType='multipart/form-data'>
                <Form.Group as={Row}>
                    <Col>
                        <Form.File
                            label={file?.name ? file.name : 'Selecteer afbeelding'}
                            type='file'
                            name='imageFile'
                            placeholder='Afbeelding'
                            id='imageFile'
                            accept='image/jpeg,image/png,application/pdf'
                            onChange={onFileChange}
                            ref={fileInputForm}
                            custom
                        />
                    </Col>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Bon Omschrijving</Form.Label>
                    <Form.Control
                        type='description'
                        value={description}
                        onChange={(e:ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                         />
                </Form.Group>
                <Form.Control
                    type='hidden'
                    value={!!entry ? entry.id : ''}
                    name='entryId'
                    >

                </Form.Control>
                <Form.Group>
                    <Form.Label>Bedrag op bon</Form.Label>
                    <CurrencyInput disabled={entry?.amount != null} value={amount} setValue={setAmount} />
                    {/*<InputGroup>*/}
                    {/*    <InputGroup.Prepend>*/}
                    {/*        <InputGroup.Text>€</InputGroup.Text>*/}
                    {/*    </InputGroup.Prepend>*/}
                    {/*    <Form.Control*/}
                    {/*        value={amount}*/}
                    {/*        disabled={entry?.amount != null}*/}
                    {/*        type='number'*/}
                    {/*        onChange={(e:ChangeEvent<HTMLInputElement>) => {*/}
                    {/*            setAmount(e.target.value);*/}
                    {/*        }}*/}
                    {/*        />*/}
                    {/*</InputGroup>*/}
                </Form.Group>
                <Form.Group as={Row}>
                    <Col>
                        <Button disabled={!file} onClick={() => submitFile()}>Bon uploaden</Button>
                    </Col>
                </Form.Group>
            </Form>
            { (alert) ? <Alert variant='success'>{alert}</Alert> : '' }
            { (error) ? <Alert variant='danger'>{error}</Alert> : '' }
            { (!!entry && bookId !== undefined) ?  <ReceiptSelect {...{refetchEntries, show, setShow, entryId, bookId, amount: amount ? amount : 0, ...props}} /> : '' }
        </div>
    );
};








