import gql from "graphql-tag";
import {Role} from "../../../models/role.model";


export interface GetRoleInfoResult {
    role: Role
}

export const GET_ROLE_INFO = gql`
    query GetRole($roleId: ID!){
        role(roleId: $roleId) {
            id,
            name,
            description,
            permissions {
                id
                internalName
                displayName
            }
        }
    }  
`;
